import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllOrders, deleteOrder, updateOrder, orderConfirmation } from '../../actions/OrdersActions';
import { Link } from "react-router-dom";
import {
    Box,
    Typography,
    IconButton,
    TextField,
    Snackbar,
    Alert,
    Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import AccessDenied from "../Errors/AccessDenied";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery } from "@mui/material";
import { encodeId } from '../../Crypte';
import {
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';

import { Delete, BorderColor } from '@mui/icons-material';
import { Add, CheckCircle } from '@mui/icons-material';

const OrdersList = ({ currentUser }) => {
    const dispatch = useDispatch();
    const orders = useSelector((state) => state.orders.orders);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [confDialogOpen, setConfDialogOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchAllOrders());
    }, [dispatch]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const filteredOrders = orders.filter((order) => {
            const product = order?.product?.name || '';
            const fournisseur = order?.fournisseur?.name || '';

            return (
                product.toLowerCase().includes(searchTerm.toLowerCase()) ||
                fournisseur.toLowerCase().includes(searchTerm.toLowerCase())

            );
        })
            .sort((a, b) => {
                if (a.date === '0000-00-00' && b.date !== '0000-00-00') {
                    return 1;
                } else if (b.date === '0000-00-00' && a.date !== '0000-00-00') {
                    return -1;
                } else {
                    return new Date(b.date) - new Date(a.date);
                }
            });

        setFilteredOrders(filteredOrders);
    }, [orders, searchTerm]);


    const Pagination = ({ page, onPageChange, className }) => {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <MuiPagination
                color="secondary"
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                    onPageChange(event, newPage - 1);
                }}
            />
        );
    }

    const CustomPagination = (props) => {
        return <GridPagination ActionsComponent={Pagination} {...props} />;
    }

    const columns = [
        {
            field: 'date', headerName: 'Date',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'prix', headerName: 'Prix',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'quantity', headerName: 'Quantité',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'product?.name', headerName: 'Produit',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row?.product?.name || '',
        },
        {
            field: 'pricePeru', headerName: "Prix par l'unité",
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'statut',
            headerName: 'Statut',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.statut || '-',
            renderCell: (params) => {
                let statusColor;

                switch (params.row.statut) {
                    case 'En attente':
                        statusColor = 'orange';
                        break;
                    case 'Confirmé':
                        statusColor = 'green';
                        break;
                    case 'Annulé':
                        statusColor = 'red';
                        break;
                    default:
                        statusColor = 'black';
                }

                return (
                    <span style={{ backgroundColor: statusColor, color: "#fff" }}>{params.row.statut}</span>
                );
            },
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    <IconButton
                        className='me-1 ms-1'
                        size='small'
                        color='error'
                        onClick={() => openDielog(params.row)}>
                        <Delete />
                    </IconButton>
                    <IconButton
                        component={Link}
                        className='mS-1'
                        size='small'
                        color='info'
                        to={`/purchases/update/${encodeId(params.id)}`}
                    >
                        <BorderColor />
                    </IconButton>
                    {params.row?.statut === 'En attente' &&
                        <Tooltip title='Confirmation' >
                            <IconButton
                                size='small'
                                color='success'
                                onClick={() => openConfDielog(params.row)}
                            >
                                <CheckCircle />
                            </IconButton>
                        </Tooltip>
                    }
                </>
            ),
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
    ];

    const handleDeleteConfirm = () => {
        dispatch(deleteOrder(selectedItem?.id))
            .then((response) => {
                console.log('success', response);
                setSnackbarSeverity('success');
                setSnackbarMessage(response.data.message);
                setSnackbarOpen(true);
                dispatch(fetchAllOrders());
            })
            .catch((error) => {
                console.error('Error deleting user:', error);
                setSnackbarSeverity('error');
                setSnackbarMessage(error.response.data.message);
                setSnackbarOpen(true);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };

    const openDielog = (item) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    }

    const closeDilaog = () => {
        setSelectedItem(null);
        setDeleteDialogOpen(false);
    }

    const openConfDielog = (item) => {
        setSelectedItem(item);
        setConfDialogOpen(true);
    }

    const closeConfDilaog = () => {
        setSelectedItem(null);
        setConfDialogOpen(false);
    }

    const handleConfirm = () => {
        dispatch(orderConfirmation(selectedItem?.id, { statut: 'Confirmé' }))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('confirmé avec succès.');
                setSnackbarOpen(true);
                dispatch(fetchAllOrders());
                closeConfDilaog();
            })
            .catch((error) => {
                console.error('Error:', error);
                setSnackbarSeverity('error');
                setSnackbarMessage('Error. Please try again.');
                setSnackbarOpen(true);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };

    const handleReject = () => {
        dispatch(orderConfirmation(selectedItem?.id, { statut: 'Annulé' }))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Annulé avec succès.');
                setSnackbarOpen(true);
                dispatch(fetchAllOrders());
                closeConfDilaog();
            })
            .catch((error) => {
                console.error('Error:', error);
                setSnackbarSeverity('error');
                setSnackbarMessage('Error. Please try again.');
                setSnackbarOpen(true);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const AddButton = (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.25rem"
            height="3.25rem"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{
                cursor: "pointer",
                background: "#0288d1",
                color: "#fff !important"
            }}
            component={Link}
            to={'/purchases/add'}
        >
            <Add fontSize="small" color="inherit" />
        </Box>
    );
    return (
        <>
            {currentUser && (currentUser.role === "Administrateurs" || currentUser.permissions.includes("Consulter les projets")) ? (
                <>
                    <Typography
                        sx={{
                            fontSize: '36px',
                            textAlign: 'center',
                            marginTop: '12px',
                            fontWeight: 'bold',
                            color: '#3e4396',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                        }}
                        variant="h2"
                        gutterBottom
                    >
                        Les achats
                    </Typography>
                    <Box>
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Rechercher un projet par référence, client, ou numéro"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                        <Box
                            m="0 0 0 0"
                            height="75vh"
                        >
                            <DataGrid
                                rows={filteredOrders}
                                columns={columns}
                                pagination
                                slots={{
                                    toolbar: GridToolbar,
                                    pagination: CustomPagination,
                                }}
                                slotProps={{
                                    columnMenu: { background: 'red' },
                                }}
                                initialState={{
                                    ...filteredOrders.initialState,
                                    pagination: { paginationModel: { pageSize: 25 } },
                                }}
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            />
                        </Box>
                    </Box>
                    {AddButton}
                    <Dialog
                        open={deleteDialogOpen}
                        onClose={closeDilaog}
                    >
                        <DialogTitle
                            sx={{
                                color: '#fff',
                                background: 'red',
                                textAlign: 'center',
                                marginBottom: '1rem',
                            }}
                        >Confirmer la suppression</DialogTitle>
                        <DialogContent>
                            <Typography>
                                Êtes-vous sûr de vouloir supprimer ce produit?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDilaog}>Annuler</Button>
                            <Button onClick={handleDeleteConfirm}
                                sx={{
                                    color: 'white !important',
                                    backgroundColor: 'red !important',
                                }}
                            >
                                Supprimer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={confDialogOpen}
                        onClose={closeConfDilaog}
                        fullWidth
                    >
                        <DialogTitle
                            textAlign={'center'}
                            fontSize={'25px'}
                            fontWeight={'bold'}
                        >
                            Confirmation
                        </DialogTitle>
                        <DialogContent>
                            <Typography textAlign={'center'} variant='h4'>
                                Êtes-vous sûr de vouloir Confirmé cet achat?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeConfDilaog}>Fermer</Button>
                            <Button onClick={handleConfirm}
                                sx={{
                                    backgroundColor: 'green',
                                    color: "#fff !important",
                                    "&:hover": {
                                        backgroundColor: '#fff',
                                        color: "green !important",
                                        fontWeight: 'bold',
                                    },
                                }}
                            >Oui Confirmer</Button>
                            <Button onClick={handleReject}
                                sx={{
                                    backgroundColor: 'red',
                                    color: "#fff !important",
                                    "&:hover": {
                                        backgroundColor: '#fff',
                                        color: "red !important",
                                        fontWeight: 'bold',
                                    },
                                }}>Non Annuler</Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert variant='filled' onClose={handleSnackbarClose} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};

export default OrdersList;

