import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUsers, deleteUser } from "../../actions/UsersActions";
import { DataGrid, frFR } from "@mui/x-data-grid";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { ControlPoint, Delete, BorderColor } from "@mui/icons-material";
import { encodeId } from "../../Crypte";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Snackbar,
    Alert,
} from "@mui/material";
import {
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';

const UsersList = () => {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users.users);
    const [selectedItem, setSelectedItem] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(fetchAllUsers());
    }, [dispatch]);

    const Pagination = ({ page, onPageChange, className }) => {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <MuiPagination
                color="secondary"
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                    onPageChange(event, newPage - 1);
                }}
            />
        );
    }

    const CustomPagination = (props) => {
        return <GridPagination ActionsComponent={Pagination} {...props} />;
    }

    const CustomButton = () => {

        return (
            <>
                {currentUser && (currentUser.role === "Administrateurs" || currentUser?.permissions?.includes("Consulter les factures")) &&
                    <IconButton
                        color="primary"
                        component={Link}
                        to={`/users/add`}
                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-1ihd25c-MuiButtonBase-root-MuiButton-root"
                    >
                        <ControlPoint sx={{ mr: '7px' }} />
                        Ajouter
                    </IconButton>
                }
            </>
        );
    };
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <CustomButton style={{ marginRight: '2rem' }} />
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const columns = [
        {
            field: "id", headerName: "ID",
            width: 70,
        },
        {
            field: "username", headerName: "Username",
            width: 150,
        },
        {
            field: "cin", headerName: "CIN",
            width: 120,
        },
        {
            field: "nom", headerName: "Nom",
            flex: 1,
        },
        {
            field: "prenom", headerName: "Prenom",
            flex: 1,
        },
        {
            field: "email", headerName: "Email",
            flex: 1,
        },
        { field: "adresse", headerName: "Adresse", flex: 1 },
        {
            field: "date_recrutement", headerName: "Date Recrutement",
            flex: 1,
        },
        { field: "telephone", headerName: "Telephone", width: 150 },
        {
            field: 'role', headerName: 'Role',
            flex: 1,
        },
        {
            field: 'modifier',
            headerName: 'Modifier',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title='Modifier' arrow>
                    <IconButton
                        component={Link}
                        to={`/users/edit/${encodeId(params.row.id)}`}
                        title="Show All Assinged Projects"
                        color="info"
                    >
                        <BorderColor />
                    </IconButton>
                </Tooltip>
            ),
        },
        {
            field: 'supprimer',
            headerName: 'Supprimer',
            flex: 1,
            renderCell: (params) => (
                <>
                    {currentUser && (currentUser.role === "Administrateurs" || currentUser.permissions.includes("Supprimer les personnel")) && (
                        <IconButton
                            title="supprimer cet utilisateur"
                            color="error"
                            onClick={() => openDielog(params.row.id)}
                        >
                            <Delete />
                        </IconButton>
                    )}
                </>
            ),
        },
    ];

    const handleDeleteConfirm = () => {
        dispatch(deleteUser(selectedItem))
            .then(() => {
                setSnackbarMessage('User deleted successfully.');
                setSnackbarOpen(true);
                dispatch(fetchAllUsers());
            })
            .catch((error) => {
                console.error('Error deleting user:', error);
                setSnackbarMessage('Error deleting user. Please try again.');
                setSnackbarOpen(true);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };

    const openDielog = (item) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    }

    const closeDilaog = () => {
        setSelectedItem(null);
        setDeleteDialogOpen(false);
    }
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    return (
        <>
            <Typography variant="h1"
                sx={{
                    // color: '#fff',
                    margin: '1rem',
                }}
                color={'primary'}
                className='fw-bold'
                textAlign={'center'}>
                Gestion des utilisateurs
            </Typography>
            <Box
            // sx={{
            //     height: 400,
            //     width: "99%",
            // }}
            >
                <DataGrid
                    rows={users}
                    columns={columns}
                    pagination
                    slots={{
                        toolbar: CustomToolbar,
                        pagination: CustomPagination,
                    }}
                    slotProps={{
                        columnMenu: { background: 'red' },
                    }}
                    initialState={{
                        ...users.initialState,
                        pagination: { paginationModel: { pageSize: 25 } },
                    }}
                    sx={{
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: '#40a9ff',
                            borderBottom: "none",
                            color: "#fff !important"
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: '#40a9ff',
                            color: "#fff !important"
                        },
                    }}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>
            <Dialog
                open={deleteDialogOpen}
                onClose={closeDilaog}
            >
                <DialogTitle
                    sx={{
                        color: '#fff',
                        background: 'red',
                        textAlign: 'center',
                        marginBottom: '1rem',
                    }}
                >Confirmer la suppression</DialogTitle>
                <DialogContent>
                    <Typography>
                        Êtes-vous sûr de vouloir supprimer cet utilisateur?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDilaog}>Annuler</Button>
                    <Button onClick={handleDeleteConfirm}
                        sx={{
                            color: 'white !important',
                            backgroundColor: 'red !important',
                        }}
                    >
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="success">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default UsersList;
