import API_URL from './apiUrl';

class FactureService {
    createFacture(factureData) {
        return API_URL.post("/factures/create", factureData);
    }

    factureCommande(factureData) {
        return API_URL.post("/factures/commande/facture", factureData);
    }

    getAllFactures() {
        return API_URL.get("/factures");
    }

    updateFacture(factureId, updatedFacture) {
        return API_URL.put(`/factures/update/${factureId}`, updatedFacture);
    }

    fetchFacture(factureId) {
        return API_URL.get(`/factures/${factureId}`);
    }

    deleteFacture(factureId) {
        return API_URL.delete(`/factures/delete/${factureId}`);
    }

}

export default new FactureService();
