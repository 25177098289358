import {
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_FAILURE,
    DELETE_USER_FAILURE,
    DELETE_USER_SUCCESS,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    GET_USER_FAILURE,
    GET_USER_SUCCESS,
} from "../../actions/types";

const initialState = {
    users: [],
    user: null,
    error: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload,
                error: null,
            };
        case GET_ALL_USERS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                user: null,
                error: null,
            };
        case DELETE_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                error: null,
            };
        case UPDATE_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                error: null,
            };
        case GET_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
