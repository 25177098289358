import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOutOfStock, deleteStock } from '../../actions/StockActions';
import { Link } from "react-router-dom";
import {
    Box,
    Typography,
    IconButton,
    TextField,
    Snackbar,
    Alert,
    Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import AccessDenied from "../Errors/AccessDenied";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery } from "@mui/material";
import {
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import { Delete, BorderColor } from '@mui/icons-material';
import UpdateStock from './UpdateStock';

const OutOfStocksList = ({ currentUser }) => {
    const dispatch = useDispatch();
    const ofStocks = useSelector((state) => state.stock.ofStock);
    const [selectedItem, setSelectedItem] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredStock, setFilteredStock] = useState([]);

    useEffect(() => {
        dispatch(fetchOutOfStock());
    }, [dispatch]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const filteredStock = ofStocks?.stocks?.filter((stock) => {
            const product = stock?.product?.name || '';

            return (
                product.toLowerCase().includes(searchTerm.toLowerCase())

            );
        })
            .sort((a, b) => {
                if (a.lastDate === '0000-00-00' && b.lastDate !== '0000-00-00') {
                    return 1;
                } else if (b.lastDate === '0000-00-00' && a.lastDate !== '0000-00-00') {
                    return -1;
                } else {
                    return new Date(b.lastDate) - new Date(a.lastDate);
                }
            });

        setFilteredStock(filteredStock);
    }, [ofStocks, searchTerm]);


    const Pagination = ({ page, onPageChange, className }) => {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <MuiPagination
                color="secondary"
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                    onPageChange(event, newPage - 1);
                }}
            />
        );
    }

    const CustomPagination = (props) => {
        return <GridPagination ActionsComponent={Pagination} {...props} />;
    }

    const columns = [
        {
            field: 'lastDate', headerName: 'Dernière date mise à jour',
            ...(isMobile ? { width: 120 } : { flex: 1.5 }),
        },
        {
            field: 'product?.name', headerName: 'Produit',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row?.product?.name || '',
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    <IconButton
                        className='me-1 ms-1'
                        size='small'
                        color='error'
                        onClick={() => openDielog(params.row)}>
                        <Delete />
                    </IconButton>
                    <Tooltip title='Modifier' >
                        <IconButton
                            className='mS-1'
                            size='small'
                            color='info'
                            onClick={() => openUpdateDielog(params.row)}
                        >
                            <BorderColor />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
    ];

    const handleDeleteConfirm = () => {
        dispatch(deleteStock(selectedItem))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Supprimé avec succès.');
                setSnackbarOpen(true);
                dispatch(fetchOutOfStock());
            })
            .catch((error) => {
                console.error('Error deleting user:', error);
                setSnackbarSeverity('error');
                setSnackbarMessage('Error deleting user. Please try again.');
                setSnackbarOpen(true);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };

    const openDielog = (item) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    }

    const closeDilaog = () => {
        setSelectedItem(null);
        setDeleteDialogOpen(false);
    }

    const openUpdateDielog = (item) => {
        setSelectedItem(item);
        setUpdateDialogOpen(true);
    }

    const closeUpdateDilaog = () => {
        setSelectedItem(null);
        setUpdateDialogOpen(false);
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            {currentUser && (currentUser.role === "Administrateurs" || currentUser.permissions.includes("Consulter les projets")) ? (
                <>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            marginTop: '12px',
                            fontWeight: 'bold',
                            color: '#3e4396',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                        }}
                        variant="h4"
                        gutterBottom
                    >
                        Liste des produits en rupture de stock : {ofStocks?.count} stocks
                    </Typography>
                    <Box>
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Rechercher par produit"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                        <Box
                            m="0 0 0 0"
                            height="75vh"
                        >
                            <DataGrid
                                rows={filteredStock || []}
                                columns={columns}
                                pagination
                                slots={{
                                    toolbar: GridToolbar,
                                    pagination: CustomPagination,
                                }}
                                slotProps={{
                                    columnMenu: { background: 'red' },
                                }}
                                initialState={{
                                    ...filteredStock?.initialState,
                                    pagination: { paginationModel: { pageSize: 25 } },
                                }}
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            />
                        </Box>
                    </Box>
                    <Dialog
                        open={deleteDialogOpen}
                        onClose={closeDilaog}
                    >
                        <DialogTitle
                            sx={{
                                color: '#fff',
                                background: 'red',
                                textAlign: 'center',
                                marginBottom: '1rem',
                            }}
                        >Confirmer la suppression</DialogTitle>
                        <DialogContent>
                            <Typography>
                                Êtes-vous sûr de vouloir supprimer ce produit?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDilaog}>Annuler</Button>
                            <Button onClick={handleDeleteConfirm}
                                sx={{
                                    color: 'white !important',
                                    backgroundColor: 'red !important',
                                }}
                            >
                                Supprimer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={updateDialogOpen}
                        onClose={closeUpdateDilaog}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle
                            textAlign={'center'}
                            fontSize={'25px'}
                            fontWeight={'bold'}
                        >
                            Modifier Stock
                        </DialogTitle>
                        <DialogContent>
                            {currentUser && ((currentUser?.role === "Administrateurs") || currentUser.permissions.includes("Importer les fichiers")) && (
                                <>
                                    <UpdateStock stock={selectedItem} />
                                </>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeUpdateDilaog}>Annuler</Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};

export default OutOfStocksList;

