import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCategory, fetchCategories } from '../../actions/CategoriesActions';
import {
    Container,
    Typography,
    TextField,
    Button,
    Snackbar,
    Alert,
} from '@mui/material';
import AccessDenied from '../Errors/AccessDenied';

const AddCategory = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const { user: currentUser } = useSelector((state) => state.auth);

    const handleSubmit = () => {
        const Data = {
            name,
        };

        dispatch(createCategory(Data))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Catégory créé avec succès !');
                setSnackbarOpen(true);
                dispatch(fetchCategories());
                setName('');
            })
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage('Erreur lors de la création. Veuillez réessayer.');
                setSnackbarOpen(true);
                console.error(error);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            {currentUser && ((currentUser?.role === "Administrateurs") || currentUser.permissions.includes("Ajouter des fournisserus")) ? (
                <Container maxWidth="md">
                    <Typography
                        variant="h2"
                        align="center"
                        gutterBottom
                        mb={3}
                        textAlign={'center'}
                        color='primary'
                    >
                        Ajouter une catégorie
                    </Typography>
                    <form style={{ marginTop: '1rem' }}>
                        <TextField
                            label="Nom"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary" sx={{ mt: 2 }}
                            onClick={handleSubmit}
                        >
                            Sauvegarder
                        </Button>
                    </form>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Container>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};


export default AddCategory;
