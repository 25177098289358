import  API_URL from './apiUrl';

class OrderService {
  createOrder(orderData) {
    return API_URL.post("/order/create/", orderData);
  }

  getAllOrders() {
    return API_URL.get("/order");
  }

  updateOrder(orderId, updatedOrder) {
    return API_URL.put(`/order/update/${orderId}`, updatedOrder);
  }

  confirmOrder(orderId, updatedOrder) {
    return API_URL.put(`/order/confirm/${orderId}`, updatedOrder);
  }

  fetchOrder(orderId) {
    return API_URL.get(`/order/${orderId}`);
  }

  deleteOrder(orderId) {
    return API_URL.delete(`/order/delete/${orderId}`);
  }
  
}

export default new OrderService();
