import notificationServices from "../services/notificationServices";
import {
    GET_UNREAD_NOTIFICATIONS_SUCCESS,
    GET_UNREAD_NOTIFICATIONS_FAILURE,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_FAILURE,
} from './types';


export const fetchUnreadNotifications = () => {
    return (dispatch) => {
        return notificationServices.getUnredNotifications()
            .then((response) => {
                dispatch({
                    type: GET_UNREAD_NOTIFICATIONS_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: GET_UNREAD_NOTIFICATIONS_FAILURE,
                    payload: error.message
                });
                throw error;
            });
    }
}

export const markAsRead = (notificationId, updatedNotif) => {
    return (dispatch) => {
        return notificationServices.markAsRead(notificationId, updatedNotif)
            .then((response) => {
                dispatch({
                    type: UPDATE_NOTIFICATION_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_NOTIFICATION_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};