
import ProductService from '../services/productServices';
import {
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILURE,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAILURE,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_FAILURE,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAILURE,
    GET_ALL_PRODUCTS_SUCCESS,
    GET_ALL_PRODUCTS_FAILURE,
    UPLOAD_FILES_SUCCESS,
    UPLOAD_FILES_FAILURE,
    GET_FILES_SUCCESS,
    GET_FILES_FAILURE,
} from './types';

export const createProduct = (productData) => {
    return (dispatch) => {
        return ProductService.createProduct(productData)
            .then((response) => {
                dispatch({
                    type: CREATE_PRODUCT_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_PRODUCT_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const getAllProducts = () => {
    return (dispatch) => {
        return ProductService.getAllProducts()
            .then((response) => {
                dispatch({
                    type: GET_ALL_PRODUCTS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_ALL_PRODUCTS_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const fetchAllProducts = () => {
    return (dispatch) => {
        return ProductService.fetchAllProducts()
            .then((response) => {
                dispatch({
                    type: GET_ALL_PRODUCTS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_ALL_PRODUCTS_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const updateProduct = (productId, updatedProduct) => {
    return (dispatch) => {
        return ProductService.updateProduct(productId, updatedProduct)
            .then((response) => {
                dispatch({
                    type: UPDATE_PRODUCT_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_PRODUCT_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const getProduct = (productId) => {
    return (dispatch) => {
        return ProductService.fetchProduct(productId)
            .then((response) => {
                dispatch({
                    type: GET_PRODUCT_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: GET_PRODUCT_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

// export const deleteProduct = (productId) => {
//     return (dispatch) => {
//         return ProductService.deleteProduct(productId)
//             .then((response) => {
//                 dispatch({
//                     type: DELETE_PRODUCT_SUCCESS,
//                     payload: response.data,
//                 });
//                 return response;
//             })
//             .catch((error) => {
//                 dispatch({
//                     type: DELETE_PRODUCT_FAILURE,
//                     payload: error.message,
//                 });
//                 throw error;
//             });
//     };
// };

export const deleteProduct = (productId) => {
    return (dispatch) => {
        return ProductService.deleteProduct(productId)
            .then((response) => {
                dispatch({
                    type: DELETE_PRODUCT_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_PRODUCT_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const uploadProductFiles = (productId, formData) => {
    return async (dispatch) => {
        try {
            const response = await ProductService.uploadFilesToProduct(productId, formData);
            dispatch({
                type: UPLOAD_FILES_SUCCESS,
                payload: response.data,
            });
            return response;
        } catch (error) {
            dispatch({
                type: UPLOAD_FILES_FAILURE,
                payload: error.response ? error.response.data : 'An error occurred',
            });
            throw error;
        }
    };
};

export const fetchProductFiles = (productId) => {
    return (dispatch) => {
        return ProductService.getAllProductFiles(productId)
            .then((response) => {
                dispatch({
                    type: GET_FILES_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_FILES_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const fetchProductFilesWithContent = (productId) => {
    return (dispatch) => {
        return ProductService.getAllProductFilesContent(productId)
            .then((response) => {
                dispatch({
                    type: GET_FILES_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_FILES_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const fetchFileContent = (productId, fileName) => {
    return async (dispatch) => {
        try {
            const response = await ProductService.getFileContent(productId, fileName);
            return response;
        } catch (error) {
            console.error('Error fetching file content:', error);
            throw error;
        }
    };
};

export const downloadFile = (productId, fileName) => {
    return async (dispatch) => {
        try {
            const response = await ProductService.downloadFile(productId, fileName);

            if (response.data instanceof Blob) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;

                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
            } else {
                console.error('Invalid file content in the response.');
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };
};

export const deleteFile = (productId, fileName) => {
    return async (dispatch) => {
        try {
            const response = await ProductService.deleteFile(productId, fileName);
            return response;
        } catch (error) {
            console.error('Error deleting file:', error);
            throw error;
        }
    };
};