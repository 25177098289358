import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducers/index';

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(),
  devTools: process.env.NODE_ENV !== 'production', 
});

// export { store };
export default store;

// import { createStore } from 'redux';
// import reducer from './reducer';

// const store = createStore(reducer);
// const persister = 'Free';

// export { store };
