import {
  CREATE_PERMISSION_SUCCESS,
  CREATE_PERMISSION_FAILURE,
  UPDATE_PERMISSION_SUCCESS,
  UPDATE_PERMISSION_FAILURE,
  GET_PERMISSION_SUCCESS,
  GET_PERMISSION_FAILURE,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAILURE,
  GET_ALL_PERMISSIONS_SUCCESS,
  GET_ALL_PERMISSIONS_FAILURE,
} from '../../actions/types';

const initialState = {
  permission: null,
  permissions: [],
  error: null,
};

const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PERMISSION_SUCCESS:
      return {
        ...state,
        permission: action.payload,
        error: null,
      };
    case CREATE_PERMISSION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_PERMISSION_SUCCESS:
      return {
        ...state,
        permission: action.payload,
        error: null,
      };
    case UPDATE_PERMISSION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_PERMISSION_SUCCESS:
      return {
        ...state,
        permission: action.payload,
        error: null,
      };
    case GET_PERMISSION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
        permission: null,
        error: null,
      };
    case DELETE_PERMISSION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
        error: null,
      };
    case GET_ALL_PERMISSIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default permissionReducer;