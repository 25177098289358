import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllProducts } from '../../actions/ProductsActions';
import { getAllFournisseurs } from '../../actions/FournisseurActions';
import { fetchOrder, updateOrder } from '../../actions/OrdersActions';
import {
    Container,
    Typography,
    TextField,
    Button,
    Autocomplete,
    MenuItem,
    Box,
    Snackbar,
    Alert,
} from '@mui/material';
import AccessDenied from '../Errors/AccessDenied';
import { useParams } from 'react-router-dom';
import { decodeId } from '../../Crypte';

const UpdateOrder = ({ currentUser }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const orderId = decodeId(id);
    const [date, setDate] = useState('');
    const [quantity, setQuantity] = useState('');
    const [expectedDate, setExpectedDate] = useState('');
    const [statut, setStatut] = useState('');
    const [pricePeru, setPricePeru] = useState('');
    const [prix, setPrix] = useState('');
    const [autre, setAutre] = useState(false);
    const [productId, setProductId] = useState('');
    const [fournisseurId, setFournisseurId] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const products = useSelector((state) => state.products.products);
    const fournisseurs = useSelector((state) => state.fournisseurs.fournisseurs);
    const order = useSelector((state) => state.orders.order);

    useEffect(() => {
        dispatch(fetchAllProducts());
        dispatch(getAllFournisseurs());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchOrder(orderId));
    }, [dispatch, orderId]);

    useEffect(() => {
        if (order) {
            setDate(order?.date);
            setExpectedDate(order?.expectedDate);
            setFournisseurId(order?.fournisseurId);
            setPricePeru(order?.pricePeru);
            setPrix(order?.prix);
            setProductId(order?.productId);
            setQuantity(order?.quantity);
            setStatut(order?.statut);
        }
    }, [order]);

    const handleSubmit = () => {
        const Data = {
            date,
            quantity,
            prix,
            statut,
            expectedDate: expectedDate || null,
            pricePeru: pricePeru || null,
            productId,
            fournisseurId,
        };

        dispatch(updateOrder(order?.id, Data))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Achat créé avec succès !');
                setSnackbarOpen(true);
                dispatch(fetchOrder(orderId));
                setDate('');
                setExpectedDate('');
                setFournisseurId('');
                setPricePeru('');
                setPrix('');
                setProductId('');
                setQuantity('');
                setStatut('');
            })
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage('Erreur lors de la création. Veuillez réessayer.');
                setSnackbarOpen(true);
                console.error(error);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const productsOptions = products?.map((product) => ({
        value: product.id,
        label: product.name,
    }));

    const fournisseursOptions = fournisseurs?.map((fournisseur) => ({
        value: fournisseur.id,
        label: fournisseur.name,
    }));

    return (
        <>
            {currentUser && ((currentUser?.role === "Administrateurs") || currentUser.permissions.includes("Ajouter des produits")) ? (
                <Container maxWidth="md">
                    <Typography
                        variant="h2"
                        align="center"
                        gutterBottom
                        mb={3}
                        textAlign={'center'}
                        color='primary'
                    >
                        Modifier l'achat: {order?.numero}
                    </Typography>
                    <Box>
                        <Autocomplete
                            sx={{ marginBottom: '1rem' }}
                            options={productsOptions}
                            getOptionLabel={(option) => option.label}
                            value={productsOptions?.find((option) => option.value === productId) || null}
                            isSearchable
                            disabled={statut === 'Confirmé'}
                            onChange={(event, newValue) => {
                                setProductId(newValue.value);
                            }}
                            renderInput={(params) => <TextField {...params} margin="normal" label="Filtrer les produit par nom..." />}
                        />
                        <TextField
                            label="La date d'achat"
                            variant="outlined"
                            fullWidth
                            type='date'
                            margin="normal"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                        <TextField
                            label="Quantité"
                            variant="outlined"
                            fullWidth
                            type='number'
                            margin="normal"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            disabled={statut === 'Confirmé'}
                        />
                        <TextField
                            label="Prix"
                            variant="outlined"
                            type='number'
                            fullWidth
                            margin="normal"
                            value={prix}
                            onChange={(e) => setPrix(e.target.value)}
                            disabled={statut === 'Confirmé'}
                        />
                        <TextField
                            label="Prix du unité"
                            variant="outlined"
                            type='number'
                            fullWidth
                            margin="normal"
                            value={pricePeru}
                            onChange={(e) => setPricePeru(e.target.value)}
                            disabled={statut === 'Confirmé'}
                        />
                        <TextField
                            label="La date d'achat"
                            variant="outlined"
                            fullWidth
                            type='date'
                            margin="normal"
                            value={expectedDate}
                            onChange={(e) => setExpectedDate(e.target.value)}
                        />
                        <Autocomplete
                            sx={{ marginBottom: '1rem' }}
                            options={fournisseursOptions}
                            getOptionLabel={(option) => option.label}
                            value={fournisseursOptions?.find((option) => option.value === fournisseurId) || null}
                            isSearchable
                            onChange={(event, newValue) => {
                                setFournisseurId(newValue.value);
                            }}
                            renderInput={(params) => <TextField {...params} margin="normal" label="Filtrer les fournisseurs par nom..." />}
                        />
                        {(statut === 'Annulé' || statut === 'En attente') &&
                            <TextField
                                select
                                label="Statut"
                                fullWidth
                                margin="normal"
                                value={statut}
                                onChange={(e) => setStatut(e.target.value)}
                            >
                                <MenuItem value={'En attente'} disabled>Sélectionner...</MenuItem>
                                <MenuItem value='En attente'>En attente</MenuItem>
                                {/* <MenuItem value='Confirmé'>Confirmé</MenuItem> */}
                                <MenuItem value='Annulé'>Annulé</MenuItem>
                            </TextField>
                        }
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary" sx={{ mt: 2 }}
                            onClick={handleSubmit}
                        >
                            Modifier
                        </Button>
                    </Box>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Container>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};


export default UpdateOrder;
