import React, { useState } from 'react';
import { Grid, Button, Typography, Snackbar, LinearProgress, Alert, InputLabel } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { uploadProductFiles } from "../../actions/ProductsActions";
import { useDispatch, useSelector } from 'react-redux';

const FileUpload = ({ productId }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadError, setUploadError] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const handleFileChange = (e) => {
        const files = e.target.files;
        setSelectedFiles([...files]);
    };

    const handleUpload = async () => {
        console.log(selectedFiles);
        if (selectedFiles.length === 0) {
            return;
        }

        const formData = new FormData();
        selectedFiles.forEach((file) => {
            formData.append('files', file);
        });

        try {
            setUploadProgress(0);
            setUploadError(null);

            const response = await dispatch(uploadProductFiles(productId, formData, (progressEvent) => {
                const progress = Math.round((100 * progressEvent.loaded) / progressEvent.total);
                setUploadProgress(progress);
            })) || {};

            const responseData = response.data || [];
            if (Array.isArray(responseData)) {
                responseData.forEach(item => {
                    console.log(item);
                });
            }

            setSelectedFiles([]);
            setUploadProgress(0);
            setUploadSuccess(true);
            setTimeout(() => setUploadSuccess(false), 3000);
        } catch (error) {
            setUploadError('An error occurred during the upload.');
        }
    };

    return (
        <>
            {currentUser && ((currentUser?.role === "Administrateurs") || currentUser.permissions.includes("Importer les fichiers")) && (
                <Grid container spacing={2} textAlign={'center'}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="file-upload">
                            <input
                                id="file-upload"
                                type="file"
                                accept=".jpeg,.jpg,.png"
                                multiple
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            {selectedFiles?.map((file, index) => (
                                <Typography key={index} variant="body2">
                                    {file.name}
                                </Typography>
                            ))}
                            <Button
                                variant="contained"
                                color="primary"
                                component="label"
                                htmlFor="file-upload"
                            >
                                <CloudUpload fontSize="small" style={{ marginRight: '8px' }} /> Sélectionnez les fichiers
                            </Button>
                        </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="info" onClick={handleUpload}>
                            Ajouter
                        </Button>
                    </Grid>
                    {uploadProgress > 0 && (
                        <Grid item xs={12}>
                            <LinearProgress variant="determinate" value={uploadProgress} />
                        </Grid>
                    )}
                    {uploadError && (
                        <Grid item xs={12}>
                            <Typography color="error">{uploadError}</Typography>
                        </Grid>
                    )}
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={uploadSuccess}
                        autoHideDuration={3000}
                        onClose={() => setUploadSuccess(false)}
                    >
                        <Alert variant="filled" onClose={() => setUploadSuccess(false)} severity={'success'}>
                            Fichiers importés avec succès
                        </Alert>
                    </Snackbar>
                </Grid>
            )}
        </>
    );
};

export default FileUpload;
