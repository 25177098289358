import React, { useRef } from "react";
import logo from "../../assets/images/logotest.png";
import { Button, Paper, Typography, Grid } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import './style.css';
const PaymentReceipt = ({ commande }) => {
    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', options);
    };

    const today = new Date();
    const cardRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => cardRef.current,
    });

    return (
        <>
            <Paper ref={cardRef}>
                <div class="page-content container">
                    <div class="container px-0">
                        <div class="row mt-4">
                            <div class="col-12 col-lg-12">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="text-center text-150">
                                            <img src={logo} height={'70px'} alt="" />
                                        </div>
                                    </div>
                                </div>

                                <hr class="row brc-default-l1 mx-n1 mb-4 mt-4" />

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div>
                                            <span class="text-sm text-grey-m2 align-middle">Client:&nbsp;</span>
                                            <span class="text-600 text-110 text-blue align-middle">{commande?.client?.full_name}</span>
                                        </div>
                                        <div class="text-grey-m2">

                                            {commande?.client?.type === 'personne physique' &&
                                                <div class="my-1">
                                                    CIN:&nbsp; {commande?.client?.cin || '-'}
                                                </div>
                                            }
                                            <div class="my-1">
                                                Telephone:&nbsp; {commande.client?.telephone || '-'}
                                            </div>
                                            <div class="my-1">
                                                Adresse:&nbsp; {commande.client?.adresse || '-'}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                                        <hr class="d-sm-none" />
                                        <div class="text-grey-m2">
                                            <div class="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                                                Commande
                                            </div>

                                            <div class="my-2"><i class="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span class="text-600 text-90">Numero:</span> #{commande?.numero}</div>

                                            <div class="my-2"><i class="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span class="text-600 text-90">Date:</span>{formatDate(commande?.date)}</div>

                                        </div>
                                    </div>
                                </div>

                                <div class="mt-5">
                                    <div class="row text-600 text-white bgc-default-tp1 py-25">
                                        <div class="col-9 col-sm-5">Produit</div>
                                        <div class="d-none d-sm-block col-2">Prix Unitaire</div>
                                        <div class="d-none d-sm-block col-4 col-sm-2">Quantité</div>
                                        <div class="d-none d-sm-block col-sm-2">Total</div>
                                    </div>
                                    {commande?.items.map((item, index) => (
                                        <>
                                            <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                                                <div class="col-9 col-sm-5">{item?.product?.name}</div>
                                                <div class="d-none d-sm-block col-2"> {item?.prixUnit}</div>
                                                <div class="d-none d-sm-block col-2">{item?.quantity}</div>
                                                <div class="d-none d-sm-block col-2 text-95"> {item?.totalPrice}</div>
                                            </div>

                                        </>
                                    ))}

                                    <div class="row border-b-2 brc-default-l2"></div>

                                    <div class="row mt-3">
                                        <div class="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                                        </div>

                                        <div class="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                                            <div class="row my-2 align-items-center bgc-primary-l3 p-2">
                                                <div class="col-5 text-80 text-right">
                                                    Total
                                                </div>
                                                <div class="col-7">
                                                    <span class="text-150 text-success-d3 opacity-2">{commande?.prix}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
            <div style={{ marginTop: "1rem", display: "flex", justifyContent: "flex-end" }}>
                <Button variant="contained"
                    sx={{
                        color: "#fff !important",
                    }}
                    color="primary"
                    onClick={handlePrint}
                >
                    Print
                </Button>
            </div>
        </>
    );
};

export default PaymentReceipt;


{/* <Paper ref={cardRef}
style={{
    padding: '30px',
    marginBottom: '20px',
    boxShadow: '0px !important',
}}>
<Typography variant="h3" mb={5} textAlign={'center'}>
    Reçu de paiement
</Typography>
<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
    <img src={logo} height={'70px'} alt="" />
    <Typography variant="h3">{commande?.client?.full_name}</Typography>
</div>
<br />
<Typography variant="body2" color="textSecondary" paragraph>
</Typography>

<div style={{ borderTop: "1px solid #ccc", marginTop: "1rem", paddingTop: "1rem" }}>
    <Grid container spacing={3} mt={1} ml={1} pt={2} pb={2}>
        <Grid xs={12} sm={6}>
            {commande?.client?.type === 'personne physique' &&
                <div>CIN:&nbsp;
                    <strong>
                        {commande?.client?.cin || '-'}
                    </strong>
                </div>
            }
            <Typography variant="body2">
                Telephone:&nbsp; {commande.client?.telephone || '-'}
                <br />
                Adresse:&nbsp; {commande.client?.adresse || '-'}
            </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
        <Typography variant="body2" color="textSecondary" paragraph>
                Date:&nbsp;
                <strong>{formatDate(today)}</strong>
            </Typography>
        </Grid>
    </Grid>
</div>
<hr />
<table style={{ marginTop: "0.3rem", width: "100%" }}>
    <thead>
        <tr>
            <th scope="col" style={{ fontSize: "1.3rem", fontWeight: "bold", borderBottom: "1px solid #ccc", textAlign: "start", padding: '8px' }}>Produit</th>
            <th scope="col" style={{ fontSize: "1.3rem", fontWeight: "bold", borderBottom: "1px solid #ccc", textAlign: "end", padding: '8px' }}>Prix Unitaire</th>
            <th scope="col" style={{ fontSize: "1.3rem", fontWeight: "bold", borderBottom: "1px solid #ccc", textAlign: "end", padding: '8px' }}>Quantité</th>
            <th scope="col" style={{ fontSize: "1.3rem", fontWeight: "bold", borderBottom: "1px solid #ccc", textAlign: "end", padding: '8px' }}>Total</th>
        </tr>
    </thead>
    <tbody>
        {commande?.items.map((item, index) => (
            <>
                <tr key={index} style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <td style={{ padding: '10px', fontSize: "1.3rem", borderBottom: "1px solid #ccc" }}>
                        {item?.product?.name}
                    </td>
                    <td style={{ padding: '10px', fontSize: "1.3rem", borderBottom: "1px solid #ccc" }}>
                        {item?.prixUnit}
                    </td>
                    <td style={{ textAlign: "end", paddingRight: '8px', fontSize: "1.3rem", borderBottom: "1px solid #ccc" }}>
                        {item?.quantity}
                    </td>
                    <td style={{ textAlign: "end", paddingRight: '8px', fontSize: "1.3rem", borderBottom: "1px solid #ccc" }}>
                        {item?.totalPrice}
                    </td>
                </tr>
            </>
        ))}
    </tbody>
</table>

<div style={{ marginTop: "1rem", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
    <Typography variant="h2" style={{ marginRight: "1rem" }}>Total:</Typography>
    <Typography variant="h1" color="success">
        {commande?.prix}
    </Typography>
</div>
</Paper>

<div style={{ marginTop: "1rem", display: "flex", justifyContent: "flex-end" }}>
<Button variant="contained"
    sx={{
        color: "#fff !important",
    }}
    color="primary"
    onClick={handlePrint}
>
    Print
</Button>
</div> */}