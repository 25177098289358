import {
  CREATE_STOCK_SUCCESS,
  CREATE_STOCK_FAILURE,
  UPDATE_STOCK_SUCCESS,
  UPDATE_STOCK_FAILURE,
  GET_STOCK_SUCCESS,
  GET_STOCK_FAILURE,
  DELETE_STOCK_SUCCESS,
  DELETE_STOCK_FAILURE,
  GET_ALL_STOCKS_SUCCESS,
  GET_ALL_STOCKS_FAILURE,
  GET_OUT_OF_STOCK_SUCCESS,
  GET_OUT_OF_STOCK_FAILURE,
} from '../../actions/types';

const initialState = {
  stock: null,
  stocks: [],
  ofStock: [],
  error: null,
};

const stockReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_STOCK_SUCCESS:
      return {
        ...state,
        stock: action.payload,
        error: null,
      };
    case CREATE_STOCK_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_STOCK_SUCCESS:
      return {
        ...state,
        stock: action.payload,
        error: null,
      };
    case UPDATE_STOCK_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_STOCK_SUCCESS:
      return {
        ...state,
        stock: action.payload,
        error: null,
      };
    case GET_STOCK_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_STOCK_SUCCESS:
      return {
        ...state,
        stock: null,
        error: null,
      };
    case DELETE_STOCK_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_STOCKS_SUCCESS:
      return {
        ...state,
        stocks: action.payload,
        error: null,
      };
    case GET_ALL_STOCKS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_OUT_OF_STOCK_SUCCESS:
      return {
        ...state,
        ofStock: action.payload,
        error: null,
      };
    case GET_OUT_OF_STOCK_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default stockReducer;