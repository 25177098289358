import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCommande } from '../../actions/CommandeActions';
import { fetchAllClients } from '../../actions/ClientActions';
import { fetchAllProducts } from '../../actions/ProductsActions';
import { Snackbar, TextField, Button, Grid, Typography, Divider, Alert, MenuItem, Select as MuiSelect, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { ControlPoint } from '@mui/icons-material';
import CreateRecette from '../recettes/CreateRecette';
import { Container, Card, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
const AddSimpleCommande = ({ currentUser }) => {
    const dispatch = useDispatch();
    const [date, setDate] = useState('');
    const [prix, setPrix] = useState(0);
    const [items, setItems] = useState([]);
    const [itemPriceUnit, setItemPriceUnit] = useState('');
    const [itemQuantity, setItemQuantity] = useState('');
    const [withClient, setWithClient] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const clients = useSelector((state) => state.clients.clients);
    const products = useSelector((state) => state.products.products);
    const [productId, setProductId] = useState(null);
    const [commandeCreated, setCommandeCreated] = useState(null);
    const [productName, setProductName] = useState('');
    const [clientId, setClientId] = useState(null);
    const [isAdvancesDialogOpen, setIsAdvancesDialogOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchAllClients());
        dispatch(fetchAllProducts());
    }, [dispatch]);

    const handleAddItem = () => {
        if (!productId || !itemQuantity || !itemPriceUnit) return;

        const newItem = {
            label: productName,
            productId: productId,
            prixUnit: parseFloat(itemPriceUnit),
            totalPrice: parseFloat(itemPriceUnit) * parseFloat(itemQuantity),
            quantity: parseFloat(itemQuantity),
        };

        setItems((prevItems) => [...prevItems, newItem]);
        setProductName('');
        setProductId(null);
        setItemPriceUnit('');
        setItemQuantity('');
    };

    const handleRemoveItem = (index) => {
        setItems((prevItems) => prevItems.filter((item, i) => i !== index));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const Data = {
            date,
            prix,
            type: 'Commande simple',
            statut: 'En instance',
            clientId: clientId || null,
            items,
        };

        dispatch(createCommande(Data))
            .then((response) => {
                openSnackbar('Commande créée avec succès', 'success');
                setIsSubmitted(true);
                console.log(response);
                setCommandeCreated(response?.data);
                setDate('');
                setPrix(0);
                setClientId(null);
                setItems([]);
                setProductId(null);
                setItemPriceUnit('');
                setItemQuantity('');
            })
            .catch((error) => {
                openSnackbar(error?.response?.data?.message, 'error');
                console.log('Error creating commande:', error);
            });
    };


    const openSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity || 'success');
        setSnackbarOpen(true);
    };

    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };
    console.log(commandeCreated);
    const productOptions = products.map((product) => ({
        value: product.id,
        label: product.name,
        prix: product.prix,
    }));

    const clientOptions = clients.map((client) => ({
        value: client.id,
        label: client.full_name,
    }));

    useEffect(() => {
        const totalPrix = items.reduce((total, item) => total + item.totalPrice, 0);
        if (totalPrix !== prix) {
            setPrix(totalPrix.toFixed(2));
        }
    }, [items, prix]);


    return (
        <>
            {isSubmitted ? (
                <>
                    <Container>
                        <Box mt={2}>
                            <Card sx={{ p: 3, py: 4 }}>
                                <Box textAlign="center" mt={2}>
                                    <Typography variant="h4" mb={2}
                                        sx={{
                                            fontSize: '29px',
                                            fontWeight: 'bold',
                                            color: '#4caf50',
                                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                                        }}
                                    >
                                        Votre commande a été créé avec succès!
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: "bold",
                                            borderRadius: "rounded",
                                            color: "#3e4396",
                                        }}
                                    >
                                        COMMANDE : {commandeCreated?.commande?.numero}
                                    </Typography>
                                    <Box mt={3}>
                                        <Grid container spacing={2} justifyContent="center">
                                            <Grid item xs={12} sm={6}>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: "#4caf50",
                                                        color: "#fff",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        padding: "10px 20px",
                                                        margin: "16px",
                                                    }}
                                                    onClick={() => setCommandeCreated(null)}
                                                >
                                                    Ajouter un autre
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: "#4caf50",
                                                        color: "#fff",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        padding: "10px 20px",
                                                        margin: "16px",
                                                    }}
                                                    onClick={() => setIsAdvancesDialogOpen(true)}
                                                >
                                                    Ajouter recette
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                        <Dialog
                            open={isAdvancesDialogOpen}
                            onClose={() => setIsAdvancesDialogOpen(false)}
                            fullWidth
                            maxWidth="md"
                        >
                            <>
                                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                                    Ajouter un Avance
                                </DialogTitle>
                                <DialogContent>
                                    <CreateRecette currentUser={currentUser} commandeId={commandeCreated?.commande?.id} />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setIsAdvancesDialogOpen(false)} color="primary">
                                        Close
                                    </Button>
                                </DialogActions>
                            </>
                        </Dialog>

                    </Container>
                </>
            ) : (
                <Grid container spacing={2}>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={closeSnackbar}
                    >
                        <Alert variant='filled' onClose={closeSnackbar} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>

                    <Grid item xs={12}>
                        <Typography variant="h2" textAlign={'center'} gutterBottom>
                            Créer une Commande
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        select
                                        value={withClient}
                                        onChange={(e) => setWithClient(e.target.value)}
                                        label="Par client existant"
                                        fullWidth
                                    >
                                        <MenuItem value={false} disabled>
                                            <em>sélectionner ...</em>
                                        </MenuItem>
                                        <MenuItem value={true}>Oui</MenuItem>
                                        <MenuItem value={false}>Non</MenuItem>
                                    </TextField>
                                </Grid>
                                {withClient &&
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            fullWidth
                                            options={clientOptions}
                                            getOptionLabel={(option) => option.label}
                                            value={clientOptions?.find((option) => option.value === clientId) || null}
                                            onChange={(e, newValue) => {
                                                setClientId(newValue?.value);
                                            }}
                                            renderInput={(params) => <TextField required {...params} label="Client" />}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        type="date"
                                        label="Date"
                                        value={date}
                                        onChange={(e) => setDate(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Prix"
                                        value={prix}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="h4" mt={2} textAlign={'center'} gutterBottom>
                                        Produits
                                    </Typography>
                                    <Divider textAlign='center' sx={{
                                        width: '10%',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        alignContent: 'center',
                                        justifyContent: 'center',
                                        color: '#0288d1 !important',
                                        backgroundColor: '#0288d1 !important',
                                        padding: '3px',
                                        marginBottom: '1rem',
                                        margin: 'auto'
                                    }} />
                                    <Autocomplete
                                        fullWidth
                                        options={productOptions}
                                        getOptionLabel={(option) => option.label}
                                        value={productOptions?.find((option) => option.value === productId) || null}
                                        onChange={(e, newValue) => {
                                            setProductId(newValue?.value);
                                            setItemPriceUnit(newValue?.prix || '');
                                            setProductName(newValue?.label);
                                        }}
                                        renderInput={(params) => <TextField {...params} margin='normal' label="Produit" />}
                                    />
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Prix unitaire"
                                        margin='normal'
                                        value={itemPriceUnit}
                                        onChange={(e) => setItemPriceUnit(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Quantité"
                                        margin='normal'
                                        value={itemQuantity}
                                        onChange={(e) => setItemQuantity(e.target.value)}
                                    />
                                    <Button onClick={handleAddItem} sx={{
                                        backgroundColor: '#00BFFF',
                                        color: '#fff !important',
                                        "&:hover": {
                                            backgroundColor: '#fff',
                                            color: "#00BFFF !important",
                                            fontWeight: 'bold',
                                        },
                                    }}>
                                        <ControlPoint />&nbsp;Ajouter
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Produit</TableCell>
                                                    <TableCell>Prix unitaire</TableCell>
                                                    <TableCell>Quantité</TableCell>
                                                    <TableCell>Total</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {items.map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{item.label}</TableCell>
                                                        <TableCell>{item.prixUnit}</TableCell>
                                                        <TableCell>{item.quantity}</TableCell>
                                                        <TableCell>{item.totalPrice}</TableCell>
                                                        <TableCell>
                                                            <Button color='error' onClick={() => handleRemoveItem(index)}>Supprimer</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type="submit" variant="contained" color="primary">
                                        Créer Commande
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default AddSimpleCommande;
