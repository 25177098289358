import {
    CREATE_CLIENT_SUCCESS,
    CREATE_CLIENT_FAILURE,
    GET_ALL_CLIENTS_SUCCESS,
    GET_ALL_CLIENTS_FAILURE,
    GET_CLIENT_SUCCESS,
    GET_CLIENT_FAILURE,
    UPDATE_CLIENT_SUCCESS,
    UPDATE_CLIENT_FAILURE,
    DELETE_CLIENT_SUCCESS,
    DELETE_CLIENT_FAILURE,
} from '../../actions/types';
const initialState = {
    client: null,
    clients: [],
    error: null,
};

const clientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_CLIENT_SUCCESS:
            return {
                ...state,
                client: action.payload,
                error: null,
            };
        case CREATE_CLIENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case UPDATE_CLIENT_SUCCESS:
            return {
                ...state,
                client: action.payload,
                error: null,
            };
        case UPDATE_CLIENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_CLIENT_SUCCESS:
            return {
                ...state,
                client: action.payload,
                error: null,
            };
        case GET_CLIENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_CLIENT_SUCCESS:
            return {
                ...state,
                client: null,
                error: null,
            };
        case DELETE_CLIENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_ALL_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: action.payload,
                error: null,
            };
        case GET_ALL_CLIENTS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default clientsReducer;