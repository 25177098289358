import React from 'react';
import { useTheme } from '@mui/material/styles';
import testImage from "../assets/images/logotest.png";

const Logo = () => {
  const theme = useTheme();

  return (
    <img src={testImage} width={'50%'} alt="Test Logo" />
  );
};

export default Logo;
