import  API_URL from './apiUrl';

class CommandeService {
  createCommande(commandeData) {
    return API_URL.post("/commandes/create/", commandeData);
  }

  getAllCommandes() {
    return API_URL.get("/commandes");
  }

  fetchAllCommandes() {
    return API_URL.get("/commandes/fetch/all");
  }

  getExpiredCommandes() {
    return API_URL.get("/commandes/fetch/delivred/expiredDate");
  }

  updateCommande(commandeId, updatedCommande) {
    return API_URL.put(`/commandes/update/${commandeId}`, updatedCommande);
  }

  updateSimpleCommande(commandeId, updatedCommande) {
    return API_URL.put(`/commandes/update/commande/${commandeId}`, updatedCommande);
  }

  fetchCommande(commandeId) {
    return API_URL.get(`/commandes/${commandeId}`);
  }

  deleteCommande(commandeId) {
    return API_URL.delete(`/commandes/delete/${commandeId}`);
  }
  
}

export default new CommandeService();
