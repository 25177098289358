import CategoriesService from '../services/categoriesServices';
import {
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAILURE,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAILURE,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAILURE,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILURE,
    GET_ALL_CATEGORIES_SUCCESS,
    GET_ALL_CATEGORIES_FAILURE,
} from './types';

export const createCategory = (categoryData) => {
    return (dispatch) => {
        return CategoriesService.createCategory(categoryData)
            .then((response) => {
                dispatch({
                    type: CREATE_CATEGORY_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_CATEGORY_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const fetchCategories = () => {
    return (dispatch) => {
        return CategoriesService.getAllCategories()
            .then((response) => {
                dispatch({
                    type: GET_ALL_CATEGORIES_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_ALL_CATEGORIES_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const updateCategory = (categoryId, updatedCategory) => {
    return (dispatch) => {
        return CategoriesService.updateCategory(categoryId, updatedCategory)
            .then((response) => {
                dispatch({
                    type: UPDATE_CATEGORY_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_CATEGORY_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const getCategory = (categoryId) => {
    return (dispatch) => {
        return CategoriesService.fetchCategory(categoryId)
            .then((response) => {
                dispatch({
                    type: GET_CATEGORY_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_CATEGORY_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const deleteCategory = (categoryId) => {
    return (dispatch) => {
        return CategoriesService.deleteCategory(categoryId)
            .then((response) => {
                dispatch({
                    type: DELETE_CATEGORY_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_CATEGORY_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};
