import {
  GET_UNREAD_NOTIFICATIONS_SUCCESS,
  GET_UNREAD_NOTIFICATIONS_FAILURE,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAILURE,
} from '../../actions/types';
const initialState = {
  notification: null,
  notifications: [],
  unreadNotif: [],
  error: null,
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        unreadNotif: action.payload,
        error: null,
      };
    case GET_UNREAD_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: action.payload,
        error: null,
      };
    case UPDATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default notificationsReducer;