import { IconKey, IconUser } from '@tabler/icons-react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupsIcon from '@mui/icons-material/Groups';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

const icons = {
  PeopleAltIcon,
  GroupsIcon,
  SupervisorAccountIcon
};

const pages = {
  id: 'pages',
  title: 'Pages',
  caption: 'Pages Caption',
  type: 'group',
  children: [
    {
      id: 'users',
      title: 'Utilisateurs',
      type: 'collapse',
      icon: icons.PeopleAltIcon,

      children: [
        {
          id: 'userslist',
          title: 'liste des utilisateurs',
          type: 'item',
          url: '/users/list',
          breadcrumbs: false
        },
        {
          id: 'adduser',
          title: 'Ajouter un utilisateur',
          type: 'item',
          url: '/users/add',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'clients',
      title: 'Clients',
      type: 'collapse',
      icon: icons.GroupsIcon,

      children: [
        {
          id: 'clientslist',
          title: 'liste des clients',
          type: 'item',
          url: '/clients/list',
          breadcrumbs: false
        },
        {
          id: 'addclient',
          title: 'Ajouter un client',
          type: 'item',
          url: '/clients/add',
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'fournisseurs',
      title: 'Fournisseurs',
      type: 'collapse',
      icon: icons.SupervisorAccountIcon,

      children: [
        {
          id: 'fournisseurslist',
          title: 'fournisseurs',
          type: 'item',
          url: '/fournisseurs/list',
          breadcrumbs: false
        },
        {
          id: 'addfournisseur',
          title: 'Ajouter',
          type: 'item',
          url: '/fournisseurs/add',
          breadcrumbs: false
        }
      ]
    },
  ]
};

export default pages;
