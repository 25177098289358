import  API_URL from './apiUrl';

class CategoriesService {
  createCategory(categoriesData) {
    return API_URL.post("/categories/create/", categoriesData);
  }

  getAllCategories() {
    return API_URL.get("/categories");
  }

  updateCategory(categoriesId, updatedCategory) {
    return API_URL.put(`/categories/update/${categoriesId}`, updatedCategory);
  }

  fetchCategory(categoriesId) {
    return API_URL.get(`/categories/${categoriesId}`);
  }

  deleteCategory(categoriesId) {
    return API_URL.delete(`/categories/delete/${categoriesId}`);
  }
  
}

export default new CategoriesService();
