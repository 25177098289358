import React, { useEffect, useState } from 'react';
import { Typography, Button, Select, FormControl, MenuItem, InputLabel, Grid, Divider } from '@mui/material';
import { Box } from '@mui/system';
import { fetchProductFilesWithContent, getProduct } from '../../actions/ProductsActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { decodeId } from '../../Crypte';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

const ProductDetail = () => {
    const { id } = useParams();
    const productId = decodeId(id);
    const [mainImage, setMainImage] = useState('');
    const dispatch = useDispatch();
    const files = useSelector((state) => state.files.files);
    const product = useSelector((state) => state.products.product);

    useEffect(() => {
        dispatch(fetchProductFilesWithContent(productId));
        dispatch(getProduct(productId));
    }, [dispatch, productId]);


    useEffect(() => {
        if (files.length > 0) {
            setMainImage(`data:image/png;base64,${files[0].content}`);
        }
    }, [files]);

    const handleThumbnailClick = (content) => {
        setMainImage(`data:image/png;base64,${content}`);
    };
    const stockTotal = product?.stocks?.reduce((total, stock) => total + parseFloat(stock.quantity), 0);
    let statusColor, statusLabel;
    if (stockTotal > 0) {
        statusColor = 'green';
        statusLabel = 'En stock';
    } else {
        statusColor = 'red';
        statusLabel = 'En rupture de stock';
    }
    return (
        <Box py={5}>
            <Grid container spacing={5}>
                <Grid item xs={12} lg={6}>
                    <Box border="1px solid #ccc" borderRadius="4px" mb={3} display="flex" justifyContent="center">
                        <img src={mainImage} alt="" style={{ maxWidth: '100%', maxHeight: '100vh', margin: 'auto' }} />
                    </Box>
                    <Box display="flex" justifyContent="center" mb={3}>
                        {files.map((file, index) => (
                            <Box key={index} border="1px solid #ccc" borderRadius="4px" mx={1} onClick={() => handleThumbnailClick(file.content)} style={{ cursor: 'pointer' }}>
                                <img src={`data:image/png;base64,${file.content}`} alt="" width="60" height="60" />
                            </Box>
                        ))}
                    </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Box pl={{ lg: 3 }}>
                        <Typography variant="h2" mt={2} gutterBottom>
                            {product?.name}
                        </Typography>
                        <Divider sx={{
                            width: '25%',
                            alignItems: 'left',
                            alignContent: 'left',
                            color: '#0288d1 !important',
                            backgroundColor: '#0288d1 !important',
                            padding: '3px',
                            marginBottom: '1rem',
                        }} />
                        <Box display="flex" alignItems="center" mb={3} justifyContent={'space-between'}>
                            <Box display="flex" alignItems="center" mr={2}>
                                <Typography display={'flex'} color={'warning'}>
                                    <StarIcon color={'warning'} />
                                    <StarIcon color={'warning'} />
                                    <StarIcon color={'warning'} />
                                    <StarIcon color={'warning'} />
                                    <StarHalfIcon color={'warning'} />
                                    <Typography variant="body2" className="text-muted">4.5</Typography>
                                </Typography>
                            </Box>
                            <Typography variant="body2" className="text-muted"><ShoppingCartCheckoutIcon />154 orders</Typography>
                            <Typography
                                sx={{
                                    backgroundColor: statusColor,
                                    color: "#fff !important",
                                    padding: '10px',
                                    fontWeight: 'bold'
                                }}>
                                {statusLabel}
                            </Typography>
                        </Box>
                        <Box mb={3} display={'flex'}>
                            <Typography variant="h5">{product?.prix}MAD</Typography>
                            <Typography variant="body2" className="text-muted">&nbsp;/per {product?.type_mater.toUpperCase()}</Typography>
                        </Box>
                        <Typography paragraph>
                            {product?.description}
                        </Typography>
                        <Grid container spacing={2} mb={3}>
                            <Grid item xs={6}>
                                <Typography><strong>Type &nbsp;:</strong> {product?.type || '-'}</Typography>
                                <Typography><strong>Color :</strong> {product?.color || '-'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography><strong>Hauteur :</strong> {product?.height || '-'}</Typography>
                                <Typography><strong>Largeur :</strong> {product?.width || '-'}</Typography>
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid container spacing={2} mb={4}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="size-label">Size</InputLabel>
                                    <Select
                                        labelId="size-label"
                                        id="size"
                                        label="Size"
                                        defaultValue=""
                                        disabled
                                        value={product?.size}
                                    >
                                        <MenuItem value={null} disabled>...</MenuItem>
                                        <MenuItem value='Petit(e)'>Petit(e)</MenuItem>
                                        <MenuItem value='Moyen'>Moyen</MenuItem>
                                        <MenuItem value='Grand(e)'>Grand(e)</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="quantity-label">Quantity</InputLabel>
                                    <Select
                                        labelId="quantity-label"
                                        id="quantity"
                                        label="Quantity"
                                        defaultValue=""
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={30}>30</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Button variant="contained" color="warning" className="shadow-0" href="#">Buy now</Button>
                        <Button variant="contained" color="primary" className="shadow-0 ms-2" href="#"><i className="me-1 fa fa-shopping-basket"></i>Add to cart</Button>
                        <Button variant="outlined" className="py-2 icon-hover px-3 ms-2" href="#"><i className="me-1 fa fa-heart fa-lg"></i>Save</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProductDetail;
