import API_URL from './apiUrl';

class NotificationServices {

    getUnredNotifications() {
        return API_URL.get('/notifications/unread');
    }

    markAsRead(notificationId, updatedNotif) {
        return API_URL.put(`/notifications/markasread/${notificationId}`, updatedNotif);
    }

}

export default new NotificationServices();