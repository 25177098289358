import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFournisseurs, deleteFournisseur } from '../../actions/FournisseurActions';
import { Link } from "react-router-dom";
import {
    Box,
    Typography,
    IconButton,
    TextField,
    Snackbar,
    Alert,
} from "@mui/material";
import { DataGrid, frFR } from "@mui/x-data-grid";
import AccessDenied from "../Errors/AccessDenied";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery } from "@mui/material";
import { encodeId } from '../../Crypte';
import {
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { Delete, BorderColor, ControlPoint } from '@mui/icons-material';

const FournisseursList = ({ currentUser }) => {
    const dispatch = useDispatch();
    const fournisseurs = useSelector((state) => state.fournisseurs.fournisseurs);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredFournisseurs, setFilteredFournisseurs] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        dispatch(getAllFournisseurs());
    }, [dispatch]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const filteredFournisseurs = fournisseurs.filter((fournisseur) => {
            const name = fournisseur.name || '';
            return (
                name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        })
            .sort((a, b) => {
                if (a.createdAt === '0000-00-00' && b.createdAt !== '0000-00-00') {
                    return 1;
                } else if (b.createdAt === '0000-00-00' && a.createdAt !== '0000-00-00') {
                    return -1;
                } else {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                }
            });

        setFilteredFournisseurs(filteredFournisseurs);
    }, [fournisseurs, searchTerm]);


    const Pagination = ({ page, onPageChange, className }) => {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <MuiPagination
                color="secondary"
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                    onPageChange(event, newPage - 1);
                }}
            />
        );
    }

    const CustomPagination = (props) => {
        return <GridPagination ActionsComponent={Pagination} {...props} />;
    }

    const CustomButton = () => {

        return (
            <>
                {currentUser && (currentUser.role === "Administrateurs" || currentUser?.permissions?.includes("Consulter les factures")) &&
                    <IconButton
                        color="primary"
                        component={Link}
                        to={`/fournisseurs/add`}
                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-1ihd25c-MuiButtonBase-root-MuiButton-root"
                    >
                        <ControlPoint sx={{ mr: '7px' }} />
                        Ajouter
                    </IconButton>
                }
            </>
        );
    };
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <CustomButton style={{ marginRight: '2rem' }} />
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const columns = [
        {
            field: 'name', headerName: 'Nom',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.name || '',
        },
        {
            field: 'telephone', headerName: 'Téléphone',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'email', headerName: 'E-mail',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    <IconButton
                        className='me-1 ms-1'
                        size='small'
                        color='error'
                        onClick={() => openDielog(params.row)}>
                        <Delete />
                    </IconButton>
                    <IconButton
                        component={Link}
                        className='mS-1'
                        size='small'
                        color='info'
                        to={`/fournisseurs/update/${encodeId(params.id)}`}
                    >
                        <BorderColor />
                    </IconButton>
                </>
            ),
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
    ];

    const handleDeleteConfirm = () => {
        dispatch(deleteFournisseur(selectedItem))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('User deleted successfully.');
                setSnackbarOpen(true);
                dispatch(getAllFournisseurs());
            })
            .catch((error) => {
                console.error('Error deleting user:', error);
                setSnackbarSeverity('error');
                setSnackbarMessage('Error deleting user. Please try again.');
                setSnackbarOpen(true);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };

    const openDielog = (item) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    }

    const closeDilaog = () => {
        setSelectedItem(null);
        setDeleteDialogOpen(false);
    }
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            {currentUser && (currentUser.role === "Administrateurs" || currentUser.permissions.includes("Consulter les projets")) ? (
                <>
                    <Typography
                        sx={{
                            fontSize: '36px',
                            textAlign: 'center',
                            marginTop: '12px',
                            fontWeight: 'bold',
                            color: '#3e4396',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                        }}
                        variant="h2"
                        gutterBottom
                    >
                        Fournisseurs
                    </Typography>
                    <Box>
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Rechercher un projet par référence, client, ou numéro"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                        <Box
                            m="0 0 0 0"
                            height="75vh"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .name-column--cell": {
                                    color: "leaderboard",
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: "secondary",
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: "primary",
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    borderTop: "none",
                                    backgroundColor: "secondary",
                                },
                                "& .MuiCheckbox-root": {
                                    color: `leaderboard !important`,
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `primary !important`,
                                },
                            }}
                        >
                            <DataGrid
                                rows={filteredFournisseurs}
                                columns={columns}
                                pagination
                                slots={{
                                    toolbar: CustomToolbar,
                                    pagination: CustomPagination,
                                }}
                                slotProps={{
                                    columnMenu: { background: 'red' },
                                }}
                                initialState={{
                                    ...filteredFournisseurs.initialState,
                                    pagination: { paginationModel: { pageSize: 25 } },
                                }}
                                sx={{
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: '#40a9ff',
                                        borderBottom: "none",
                                        color: "#fff !important"
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        borderTop: "none",
                                        backgroundColor: '#40a9ff',
                                        color: "#fff !important"
                                    },
                                }}
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            />
                        </Box>
                    </Box>
                    <Dialog
                        open={deleteDialogOpen}
                        onClose={closeDilaog}
                    >
                        <DialogTitle
                            sx={{
                                color: '#fff',
                                background: 'red',
                                textAlign: 'center',
                                marginBottom: '1rem',
                            }}
                        >Confirmer la suppression</DialogTitle>
                        <DialogContent>
                            <Typography>
                                Êtes-vous sûr de vouloir supprimer cet fournisseur?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDilaog}>Annuler</Button>
                            <Button onClick={handleDeleteConfirm}
                                sx={{
                                    color: 'white !important',
                                    backgroundColor: 'red !important',
                                }}
                            >
                                Supprimer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};

export default FournisseursList;

