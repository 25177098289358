import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCommandes } from "../../actions/CommandeActions";
import { createRecette } from "../../actions/RecettesActions";
import {
    Typography,
    TextField,
    Button,
    Autocomplete,
    MenuItem,
    Snackbar,
    Alert,
    Grid,
    Box,
} from '@mui/material';

const AddRecette = () => {
    const dispatch = useDispatch();
    const commandes = useSelector((state) => state.commandes.commandes);
    const [prix, setPrix] = useState('');
    const [date, setDate] = useState('');
    const [type, setType] = useState('');
    const [commandeId, setCommandeId] = useState('');

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        dispatch(getAllCommandes());
    }, [dispatch]);

    const handleSubmit = () => {
        const Data = {
            type,
            date,
            prix,
            commandeId,
        };

        dispatch(createRecette(Data))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Recette créé avec succès !');
                setSnackbarOpen(true);
                setType('');
                setDate('');
                setPrix('');
                setCommandeId('');
            })
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage(error?.response?.data?.message);
                setSnackbarOpen(true);
                console.error(error);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const commandesOptions = commandes
    ?.filter(commande => commande.date) 
    .map((commande) => ({
      value: commande.id,
      label: commande.numero,
      date: new Date(commande.date) 
    }))
    .sort((a, b) => b.date - a.date); 

    return (
        <Box>
            <Typography
                variant="h2"
                align="center"
                gutterBottom
                mb={3}
                textAlign={'center'}
                color='primary'
            >
                Ajouter une recette
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        options={commandesOptions}
                        required
                        fullWidth
                        renderInput={(params) => <TextField required {...params} label="Commande" />}
                        onChange={(event, value) => setCommandeId(value?.value || '')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Prix"
                        variant="outlined"
                        fullWidth
                        type="number"
                        required
                        value={prix}
                        onChange={(e) => setPrix(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Date"
                        variant="outlined"
                        fullWidth
                        type="date"
                        required
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        select
                        label='Mode de paiement'
                        name="type"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        required
                        fullWidth
                    >
                        <MenuItem value="" disabled>
                            Sélectionnez le mode
                        </MenuItem>
                        <MenuItem value="Espèces">Espèces</MenuItem>
                        <MenuItem value="Virement bancaire">Virement bancaire</MenuItem>
                        <MenuItem value="Chèques">Chèques</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
            <Button variant="contained" className="mt-2" color="primary" onClick={handleSubmit}>
                Ajouter Recette
            </Button>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default AddRecette;
