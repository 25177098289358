import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    useMediaQuery,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Divider,
} from '@mui/material';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import burceMars from "../../assets/images/profile.jpg";
import { encodeId } from '../../Crypte';

const ClientInfo = ({ client }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const { user: currentUser } = useSelector((state) => state.auth);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogOpenFactures, setDialogOpenFactures] = useState(false);
    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', options);
    };

    const openDialog = () => {
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ marginBottom: '1rem' }}>
                            <CardContent>
                                <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                                    <Avatar alt="Client Avatar" src={burceMars} sx={{ width: 150, height: 150 }} />
                                    <Typography variant="h4" mt={3}>{client?.full_name}</Typography>
                                    <Typography variant="body1" color="textSecondary" mb={1}>{client?.type}</Typography>
                                    <Typography variant="body2" color="textSecondary">{formatDate(client?.createdAt) || "-"}</Typography>
                                    <Box mt={1}>
                                        {currentUser && ((currentUser?.role === "Administrateurs")
                                            || currentUser.permissions.includes("Consulter les projets")) &&
                                            <Button variant="contained" sx={{ marginRight: '0.5rem' }} color="success" onClick={openDialog}>
                                                <PermMediaIcon fontSize='medium' />&nbsp; Commandes
                                            </Button>
                                        }
                                        {currentUser && ((currentUser?.role === "Administrateurs")
                                            || currentUser.permissions.includes("Consulter les factures")) &&
                                            <Button variant="contained" sx={{ marginLeft: '0.5rem' }} color="warning" onClick={() => setDialogOpenFactures(true)}>
                                                <FileCopyIcon fontSize='medium' />&nbsp; Factures
                                            </Button>
                                        }
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                        <Card mt={5}>
                            <List sx={{ padding: '1rem' }}>
                                <ListItem>
                                    <ListItemText primary="Commandes" />
                                    <ListItemIcon>
                                        <PermMediaIcon fontSize='medium' />
                                    </ListItemIcon>
                                    <Typography variant="body2" color="textSecondary">0</Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Factures" />
                                    <ListItemIcon>
                                        <FileCopyIcon fontSize='medium' />
                                    </ListItemIcon>
                                    <Typography variant="body2" color="textSecondary">0</Typography>
                                </ListItem>
                            </List>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={8} >
                        <Card>
                            <CardContent>
                                <Typography variant="h3" mt={2} gutterBottom>
                                    Informations du client
                                </Typography>
                                <Divider sx={{
                                    width: '25%',
                                    alignItems: 'left',
                                    alignContent: 'left',
                                    color: '#0288d1 !important',
                                    backgroundColor: '#0288d1 !important',
                                    padding: '3px',
                                    marginBottom: '1rem',
                                }} />
                                <Grid container spacing={3} mt={1}>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="subtitle2">Full Name</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Typography variant="body2" color="textSecondary">{client?.full_name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="subtitle2">Phone</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Typography variant="body2" color="textSecondary">{client?.telephone}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="subtitle2">Adresse</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Typography variant="body2" color="textSecondary">{client?.adresse}</Typography>
                                    </Grid>
                                    {client?.type !== "personne physique" && (
                                        <>
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="subtitle2">ICE</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <Typography variant="body2" color="textSecondary">{client?.ice}</Typography>
                                            </Grid>
                                        </>
                                    )}
                                    {(client?.type !== "personne physique" && client?.type !== "organisme public") && (
                                        <>
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="subtitle2">RC</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <Typography variant="body2" color="textSecondary">{client?.rc}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="subtitle2">IF</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <Typography variant="body2" color="textSecondary">{client?.ifc}</Typography>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12} sm={12}>
                                        <Button component={Link} to={`/clients/update/${encodeId(client.id)}`} variant="contained" color="info">
                                            <BorderColorIcon />
                                            Modifier
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Dialog
                open={dialogOpen}
                onClose={closeDialog}
                fullWidth
                maxWidth="lg"
            >
                <>
                    <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                        Les Commandes
                    </DialogTitle>
                    <DialogContent>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </>
            </Dialog>
            <Dialog
                open={dialogOpenFactures}
                onClose={() => setDialogOpenFactures(false)}
                fullWidth
                maxWidth="lg"
            >
                <>
                    <DialogTitle textAlign={'center'} style={{ fontSize: '24px' }}>
                        Les Factures
                    </DialogTitle>
                    <DialogContent>
                        {/* <ClientFactures factures={factures?.factures} isMobile={isMobile} /> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialogOpenFactures(false)} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </>
            </Dialog>
        </>
    );
}
export default ClientInfo;


