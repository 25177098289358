import FactureService from '../services/factureServices';
import {
  CREATE_FACTURE_SUCCESS,
  CREATE_FACTURE_FAILURE,
  UPDATE_FACTURE_SUCCESS,
  UPDATE_FACTURE_FAILURE,
  GET_FACTURE_SUCCESS,
  GET_FACTURE_FAILURE,
  DELETE_FACTURE_SUCCESS,
  DELETE_FACTURE_FAILURE,
  GET_ALL_FACTURES_SUCCESS,
  GET_ALL_FACTURES_FAILURE,
} from './types';

export const createFacture = (factureData) => {
  return (dispatch) => {
    return FactureService.createFacture(factureData)
      .then((response) => {
        dispatch({
          type: CREATE_FACTURE_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        dispatch({
          type: CREATE_FACTURE_FAILURE,
          payload: error.message,
        });
        return Promise.reject(error);
      });
  };
};

export const factureCommande = (factureData) => {
    return (dispatch) => {
      return FactureService.factureCommande(factureData)
        .then((response) => {
          dispatch({
            type: CREATE_FACTURE_SUCCESS,
            payload: response.data,
          });
          return response;
        })
        .catch((error) => {
          dispatch({
            type: CREATE_FACTURE_FAILURE,
            payload: error.message,
          });
          throw error;
        });
    };
  };

export const fetchAllFactures = () => {
  return (dispatch) => {
    return FactureService.getAllFactures()
      .then((response) => {
        dispatch({
          type: GET_ALL_FACTURES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_FACTURES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateFacture = (factureId, updatedFacture) => {
  return (dispatch) => {
    return FactureService.updateFacture(factureId, updatedFacture)
      .then((response) => {
        dispatch({
          type: UPDATE_FACTURE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_FACTURE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const fetchFacture = (factureId) => {
  return (dispatch) => {
    return FactureService.fetchFacture(factureId)
      .then((response) => {
        dispatch({
          type: GET_FACTURE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_FACTURE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteFacture = (factureId) => {
  return (dispatch) => {
    return FactureService.deleteFacture(factureId)
      .then((response) => {
        dispatch({
          type: DELETE_FACTURE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: DELETE_FACTURE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

