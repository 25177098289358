import { useEffect, useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import {
  Avatar,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material';
import { Store, ShoppingCart, PriceCheck, CurrencyExchange } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchUnreadNotifications, markAsRead } from '../../../../actions/NotificationsActions';

const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  '&:hover': {
    background: theme.palette.primary.light
  },
  '& .MuiListItem-root': {
    padding: 0
  }
}));


const NotificationList = ({ unreadNotif }) => {
  const theme = useTheme();
  const notifications = unreadNotif?.notifications || [];
  const count = unreadNotif?.count || 0;
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options);
  };

  const chipSX = {
    height: 24,
    padding: '0 6px'
  };
  const chipErrorSX = {
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor: theme.palette.orange.light,
    marginRight: '5px'
  };


  const chipWarningSX = {
    ...chipSX,
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.light
  };

  const chipSuccessSX = {
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    height: 28
  };


  const NotifSwitch = (type) => {
    let iconType;
    switch (type) {
      case 'out_of_stock':
        iconType = <Store />;
        break;
      case 'expired_command':
        iconType = <ShoppingCart />;
        break;
      case 'recette_added':
        iconType = <PriceCheck />;
        break;
      case 'recette_updated':
        iconType = <CurrencyExchange />;
        break;
      default:
        iconType = null;
    }
    return iconType;
  }

  const LinkSwitch = (type) => {
    let notifLink;
    switch (type) {
      case 'out_of_stock':
        notifLink = '/stock/list/outofstock';
        break;
      case 'expired_command':
        notifLink = '/commandes/list/delivred/expired';
        break;
      case 'recette_added':
        notifLink = '/';
        break;
      case 'recette_updated':
        notifLink = '/';
        break;
      default:
        notifLink = '/';
    }
    return notifLink;
  }

  const handleRead = (notificationId) => {
    dispatch(markAsRead(notificationId, { isRead: 'Oui' }))
      .then(() => {
        dispatch(fetchUnreadNotifications());
      })
      .catch((error) => {
        console.error('Error:', error);
      })
  };


  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 330,
        py: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
          maxWidth: 300
        },
        '& .MuiListItemSecondaryAction-root': {
          top: 22
        },
        '& .MuiDivider-root': {
          my: 0
        },
        '& .list-container': {
          pl: 7
        }
      }}
    >
      {(count && count > 0) ? (
        <>
          {notifications?.map((notification, index) => (
            <>
              <ListItemWrapper>
                <ListItem alignItems="center">
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        color: theme.palette[notification?.severity]?.dark,
                        backgroundColor: theme.palette[notification?.severity]?.light,
                        border: 'none',
                        borderColor: theme.palette[notification?.severity]?.main
                      }}
                    >
                      {NotifSwitch(notification?.type)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={<Typography variant="subtitle1">{notification?.title}</Typography>} />
                  <ListItemSecondaryAction>
                    <Grid container justifyContent="flex-end">
                      <Grid item xs={12}>
                        <Typography variant="caption" display="block" gutterBottom>
                          {formatDate(notification?.date)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container">
                  <Grid item xs={12} sx={{ pb: 2 }}>
                    <Typography variant="subtitle2">{notification?.message} <br />
                      Total(e): {notification?.total}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item>
                        {/* <Chip label="Marquer comme lu" onClick={handleRead(notification?.id)} sx={chipErrorSX} /> */}
                        <Chip label="Marquer comme lu" onClick={() => handleRead(notification?.id)} sx={chipErrorSX} />

                      </Grid>
                      <Grid item>
                        <Chip label="voir" component={Link} to={LinkSwitch(notification?.type)} sx={chipSuccessSX} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItemWrapper>
              <Divider />
            </>
          ))}
        </>
      ) : (
        <> Acune notifications disponible!</>
      )}
    </List>
  );
};

export default NotificationList;
