import React, { useRef } from "react";
import logo from "../../assets/images/logotest.png";
import { ToWords } from 'to-words';
import { Button, Paper } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { Print } from "@mui/icons-material";
const FacturePreview = ({ facture }) => {
    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', options);
    };
    const toWords = new ToWords({ localeCode: 'fr-FR' });
    const tva = parseFloat(facture?.montant_ht) * 0.2;
    const [integerPart, decimalPart] = facture?.montant_ttc.split('.');
    let wordsIntegerPart = toWords.convert(integerPart);

    let wordsDecimalPart = '';
    if (decimalPart) {
        wordsDecimalPart = toWords.convert(decimalPart);
    }
    const cardRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => cardRef.current,
    });
    return (
        <>
            <Paper ref={cardRef}>
                <div className="page-content">
                    <div className="p-5">
                        <div className="row mt-4">
                            <div className="col-12 col-lg-12">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="text-start text-150">
                                            <img src={logo} height={'70px'} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="text-end mt-2">
                                            <span className="text-sm text-grey-m2 align-middle">Company :&nbsp;</span>
                                            <span className="text-600 text-110 text-blue align-middle">informations of the company</span>
                                        </div>
                                    </div>
                                </div>

                                <hr className="row brc-default-l1 mx-n1 mb-4 mt-4" />

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div>
                                            <span className="text-sm text-grey-m2 align-middle">Client:&nbsp;</span>
                                            <span className="text-600 text-110 text-blue align-middle">{facture?.commande?.client?.full_name}</span>
                                        </div>
                                        <div className="text-grey-m2">

                                            {facture?.commande?.client?.type === 'personne physique' &&
                                                <div className="my-1">
                                                    CIN:&nbsp; {facture?.commande?.client?.cin || '-'}
                                                </div>
                                            }
                                            <div className="my-1">
                                                Telephone:&nbsp; {facture?.commande?.client?.telephone || '-'}
                                            </div>
                                            <div className="my-1">
                                                Adresse:&nbsp; {facture?.commande?.client?.adresse || '-'}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                                        <hr className="d-sm-none" />
                                        <div className="text-grey-m2">

                                            <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span className="text-600 text-90">Numero:</span> #{facture?.numero}</div>

                                            <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span className="text-600 text-90">Date:</span> {formatDate(facture?.date)}</div>
                                            <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span className="text-600 text-90">Date de paiement:</span> {facture?.date_paiement ? formatDate(facture?.date_paiement) || '-' : '-'}</div>
                                            <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span className="text-600 text-90">Mode de paiement:</span> {facture?.mode_paiement || '-'}</div>

                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <div className="row text-600 text-white bgc-default-tp1 py-25">
                                        <div className="col-9 col-sm-5">Produit</div>
                                        <div className="d-none d-sm-block col-2">Prix Unitaire</div>
                                        <div className="d-none d-sm-block col-4 col-sm-2">Quantité</div>
                                        <div className="d-none d-sm-block col-sm-2">Total</div>
                                    </div>
                                    {facture?.items.map((item, index) => (
                                        <>
                                            <div className="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                                                <div className="col-9 col-sm-5">{item?.product?.name}</div>
                                                <div className="d-none d-sm-block col-2"> {item?.prixUnit}</div>
                                                <div className="d-none d-sm-block col-2">{item?.quantity}</div>
                                                <div className="d-none d-sm-block col-2 text-95"> {item?.totalPrice}</div>
                                            </div>

                                        </>
                                    ))}

                                    <div className="row border-b-2 brc-default-l2"></div>

                                    <div className="row mt-3">
                                        <div className="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                                        </div>

                                        <div className="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                                            <div className="row my-2">
                                                <div className="col-7 text-right">
                                                    Montant HT
                                                </div>
                                                <div className="col-5">
                                                    <span className="text-120 text-secondary-d1">{facture?.montant_ht}</span>
                                                </div>
                                            </div>

                                            <div className="row my-2">
                                                <div className="col-7 text-right">
                                                    TVA
                                                </div>
                                                <div className="col-5">
                                                    <span className="text-110 text-secondary-d1">{tva?.toFixed(2)}</span>
                                                </div>
                                            </div>
                                            <div className="row my-2 mt-2">
                                                <div className="col-7  mt-2 fw-bold text-100 text-right">
                                                    Total TTC
                                                </div>
                                                <div className="col-5  mt-2">
                                                    <span className="text-150  text-success-d3 opacity-2">{facture?.montant_ttc}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <p class="text-105 border p-3">
                                            <strong>
                                                Arrêtée la Présente Facture à la somme de :&nbsp;
                                            </strong>
                                            <em>{wordsIntegerPart}</em>
                                            &nbsp;Dirhams et <em>{wordsDecimalPart}</em> Cts,&nbsp;
                                            <strong>
                                                toutes taxes comprises</strong>
                                        </p>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                                        </div>

                                        <div className="col-12 col-sm-5 text-grey mt-5 text-90 order-first order-sm-last">
                                            <div className="row my-2">
                                                <div className="col-7 text-right ms-5 mt-5">
                                                    Signé :
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
            <div style={{ marginTop: "1rem", display: "flex", justifyContent: "flex-end" }}>
                <Button variant="contained"
                    sx={{
                        color: "#fff !important",
                    }}
                    color="primary"
                    onClick={handlePrint}
                >
                    <Print />&nbsp;Exporter
                </Button>
            </div>
        </>
    );
}

export default FacturePreview;