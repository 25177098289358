import RecetteService from '../services/recettesServices';
import {
    CREATE_RECETTE_SUCCESS,
    CREATE_RECETTE_FAILURE,
    UPDATE_RECETTE_SUCCESS,
    UPDATE_RECETTE_FAILURE,
    GET_RECETTE_SUCCESS,
    GET_RECETTE_FAILURE,
    DELETE_RECETTE_SUCCESS,
    DELETE_RECETTE_FAILURE,
    GET_ALL_RECETTES_SUCCESS,
    GET_ALL_RECETTES_FAILURE,
} from './types';


export const createRecette = (recetteData) => {
  return (dispatch) => {
    return RecetteService.createRecette(recetteData)
      .then((response) => {
        dispatch({
          type: CREATE_RECETTE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: CREATE_RECETTE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const fetchAllRecettes = () => {
  return (dispatch) => {
    return RecetteService.getAllRecettes()
      .then((response) => {
        dispatch({
          type: GET_ALL_RECETTES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_RECETTES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateRecette = (recetteId, updatedRecette) => {
  return (dispatch) => {
    return RecetteService.updateRecette(recetteId, updatedRecette)
      .then((response) => {
        dispatch({
          type: UPDATE_RECETTE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_RECETTE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const getRecette = (recetteId) => {
  return (dispatch) => {
    return RecetteService.fetchRecette(recetteId)
      .then((response) => {
        dispatch({
          type: GET_RECETTE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_RECETTE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteRecette = (recetteId) => {
  return (dispatch) => {
    return RecetteService.deleteRecette(recetteId)
      .then((response) => {
        dispatch({
          type: DELETE_RECETTE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: DELETE_RECETTE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};