import axios from 'axios';
import store from '../store/index';
import { logout } from '../actions/UsersActions';

// const baseURL = process.env.REACT_APP_API_URL || "http://localhost:8080/api";
const baseURL = process.env.REACT_APP_API_URL || "https://prosys.syscamp.ma/api";

const AxiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-type": "application/json",
    },
});

AxiosInstance.interceptors.request.use(
    (config) => {
        const { auth } = store.getState();
        const { accessToken } = auth.user || {};

        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

AxiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error;
        if (response && response.status === 401) {
            store.dispatch(logout());
        }
        return Promise.reject(error);
    }
);


export default AxiosInstance;