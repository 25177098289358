import {
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAILURE,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAILURE,
    GET_ORDER_SUCCESS,
    GET_ORDER_FAILURE,
    DELETE_ORDER_SUCCESS,
    DELETE_ORDER_FAILURE,
    GET_ALL_ORDERS_SUCCESS,
    GET_ALL_ORDERS_FAILURE,
  } from '../../actions/types';
  
  const initialState = {
    order: null,
    orders: [],
    error: null,
  };
  
  const orderReducer = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_ORDER_SUCCESS:
        return {
          ...state,
          order: action.payload,
          error: null,
        };
      case CREATE_ORDER_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      case UPDATE_ORDER_SUCCESS:
        return {
          ...state,
          order: action.payload,
          error: null,
        };
      case UPDATE_ORDER_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      case GET_ORDER_SUCCESS:
        return {
          ...state,
          order: action.payload,
          error: null,
        };
      case GET_ORDER_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      case DELETE_ORDER_SUCCESS:
        return {
          ...state,
          order: null,
          error: null,
        };
      case DELETE_ORDER_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      case GET_ALL_ORDERS_SUCCESS:
        return {
          ...state,
          orders: action.payload,
          error: null,
        };
      case GET_ALL_ORDERS_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default orderReducer;