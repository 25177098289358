import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories, deleteCategory } from '../../actions/CategoriesActions';
import { Link } from "react-router-dom";
import {
    Box,
    Typography,
    IconButton,
    Snackbar,
    Alert,
} from "@mui/material";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import AccessDenied from "../Errors/AccessDenied";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery } from "@mui/material";
import {
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { Delete, BorderColor, ControlPoint } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import AddCategory from './AddCategory';
import UpdateCategory from './UpdateCategory';

const CategoriesList = ({ currentUser }) => {
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.categories.categories);
    const [selectedItem, setSelectedItem] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        dispatch(fetchCategories());
    }, [dispatch]);

    const Pagination = ({ page, onPageChange, className }) => {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <MuiPagination
                color="secondary"
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                    onPageChange(event, newPage - 1);
                }}
            />
        );
    }

    const CustomPagination = (props) => {
        return <GridPagination ActionsComponent={Pagination} {...props} />;
    }

    const CustomButton = () => {

        return (
            <>
                {currentUser && (currentUser.role === "Administrateurs" || currentUser?.permissions?.includes("Consulter les factures")) &&
                    <IconButton
                        color="primary"
                        component={Link}
                        to={`/categories/add`}
                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-1ihd25c-MuiButtonBase-root-MuiButton-root"
                    >
                        <ControlPoint sx={{ mr: '7px' }} />
                        Ajouter
                    </IconButton>
                }
            </>
        );
    };
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <CustomButton style={{ marginRight: '2rem' }} />
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const columns = [
        {
            field: 'name', headerName: 'Nom',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.name || '',
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    <IconButton
                        className='me-1 ms-1'
                        size='small'
                        color='error'
                        onClick={() => openDielog(params.row)}>
                        <Delete />
                    </IconButton>
                    <IconButton
                        onClick={() => openUpdateDielog(params.row)}
                        className='mS-1'
                        size='small'
                        color='info'
                    >
                        <BorderColor />
                    </IconButton>
                </>
            ),
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
    ];

    const handleDeleteConfirm = () => {
        dispatch(deleteCategory(selectedItem))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('User deleted successfully.');
                setSnackbarOpen(true);
                dispatch(fetchCategories());
            })
            .catch((error) => {
                console.error('Error deleting user:', error);
                setSnackbarSeverity('error');
                setSnackbarMessage('Error deleting user. Please try again.');
                setSnackbarOpen(true);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };

    const openDielog = (item) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    }

    const closeDilaog = () => {
        setSelectedItem(null);
        setDeleteDialogOpen(false);
    }

    const openUpdateDielog = (item) => {
        setSelectedItem(item);
        setUpdateDialogOpen(true);
    }

    const closeUpdateDilaog = () => {
        setSelectedItem(null);
        setUpdateDialogOpen(false);
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const AddButton = (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.25rem"
            height="3.25rem"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{
                cursor: "pointer",
                background: "#0288d1",
                color: "#fff !important"
            }}
            onClick={() => setDialogOpen(true)}
        >
            <AddIcon fontSize="small" color="inherit" />
        </Box>
    );

    return (
        <>
            {currentUser && (currentUser.role === "Administrateurs" || currentUser.permissions.includes("Consulter les projets")) ? (
                <>
                    <Typography
                        sx={{
                            fontSize: '36px',
                            textAlign: 'center',
                            marginTop: '12px',
                            fontWeight: 'bold',
                            color: '#0288d1',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                        }}
                        variant="h2"
                        gutterBottom
                    >
                        Catégories
                    </Typography>
                    <Box>
                        <Box
                            m="0 0 0 0"
                            height="75vh"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .name-column--cell": {
                                    color: "leaderboard",
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: "secondary",
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: "primary",
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    borderTop: "none",
                                    backgroundColor: "secondary",
                                },
                                "& .MuiCheckbox-root": {
                                    color: `leaderboard !important`,
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `primary !important`,
                                },
                            }}
                        >
                            <DataGrid
                                rows={categories}
                                columns={columns}
                                pagination
                                slots={{
                                    toolbar: GridToolbar,
                                    pagination: CustomPagination,
                                }}
                                slotProps={{
                                    columnMenu: { background: 'red' },
                                }}
                                initialState={{
                                    ...categories.initialState,
                                    pagination: { paginationModel: { pageSize: 25 } },
                                }}
                                sx={{
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: '#40a9ff',
                                        borderBottom: "none",
                                        color: "#fff !important"
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        borderTop: "none",
                                        backgroundColor: '#40a9ff',
                                        color: "#fff !important"
                                    },
                                }}
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            />
                        </Box>
                        {AddButton}
                    </Box>
                    <Dialog
                        open={deleteDialogOpen}
                        onClose={closeDilaog}
                    >
                        <DialogTitle
                            sx={{
                                color: '#fff',
                                background: 'red',
                                textAlign: 'center',
                                marginBottom: '1rem',
                                fontSize: '20px',
                                fontWeight: 'bold',
                            }}
                        >Confirmer la suppression</DialogTitle>
                        <DialogContent>
                            <Typography>
                                Êtes-vous sûr de vouloir supprimer cet fournisseur?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDilaog}>Annuler</Button>
                            <Button onClick={handleDeleteConfirm}
                                sx={{
                                    color: 'white !important',
                                    backgroundColor: 'red !important',
                                }}
                            >
                                Supprimer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={dialogOpen}
                        onClose={() => setDialogOpen(false)}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                        </DialogTitle>
                        <DialogContent>
                            <AddCategory />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setDialogOpen(false)} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={updateDialogOpen}
                        onClose={closeUpdateDilaog}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                        </DialogTitle>
                        <DialogContent>
                            {selectedItem &&
                                <UpdateCategory currentUser={currentUser} category={selectedItem} />
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeUpdateDilaog} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};

export default CategoriesList;

