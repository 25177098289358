import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createClient } from '../../actions/ClientActions';
import {
    Container,
    Typography,
    TextField,
    Button,
    Alert,
    Grid,
    AlertTitle,
    Snackbar,
    FormControl,
    MenuItem,
} from '@mui/material';
import { Link } from 'react-router-dom';
import AccessDenied from "../Errors/AccessDenied";

const AddClient = ({ currentUser }) => {
    const dispatch = useDispatch();
    const [cin, setCin] = useState(null);
    const [full_name, setFullName] = useState('');
    const [adresse, setAdresse] = useState('');
    const [telephone, setTelephone] = useState('');
    const [ice, setICE] = useState('');
    const [rc, setRC] = useState('');
    const [ifc, setIFC] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [selectedOption, setSelectedOption] = useState('none');
    const [showForm, setShowForm] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const saveClient = () => {
        const clientData = {
            cin: selectedOption === 'organisme public' ? null : cin,
            full_name: full_name.toUpperCase(),
            adresse,
            telephone,
            type: selectedOption,
            ice: selectedOption !== 'personne physique' ? ice.trim() !== '' ? ice : null : null,
            rc: selectedOption === 'organisme public' ? null : rc.trim() !== '' ? rc : null,
            ifc: selectedOption !== 'personne physique' && selectedOption !== 'organisme public' ? ifc.trim() !== '' ? ifc : null : null,
        };

        dispatch(createClient(clientData))
            .then((response) => {
                setSubmitted(true);
                openSnackbar('Client créé avec succès', 'success');
                setCin('');
                setFullName('');
                setAdresse('');
                setTelephone('');
                setICE('');
                setRC('');
                setIFC('');
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    openSnackbar('Le client existe déjà', 'error');
                } else {
                    openSnackbar(error.response.data.message, 'error');
                }
            });
    };

    const onChangeCin = (e) => {
        setCin(e.target.value);
    };

    const onChangeFullName = (e) => {
        setFullName(e.target.value);
    };

    const onChangeAdresse = (e) => {
        setAdresse(e.target.value);
    };

    const onChangeTelephone = (e) => {
        setTelephone(e.target.value);
    };

    const onChangeICE = (e) => {
        setICE(e.target.value);
    };

    const onChangeRC = (e) => {
        setRC(e.target.value);
    };

    const onChangeIFC = (e) => {
        setIFC(e.target.value);
    };

    const handleOptionChange = (event) => {
        const selectedOption = event.target.value;
        const showForm = selectedOption !== 'none';
        setSelectedOption(selectedOption);
        setShowForm(showForm);
    };

    const openSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity || 'success');
        setSnackbarOpen(true);
    };

    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            {currentUser && ((currentUser?.role === "Administrateurs") || currentUser.permissions.includes("Ajouter des clients")) ? (
                <Container maxWidth="lg">
                    <Typography
                        textAlign={'center'}
                        variant="h3"
                        gutterBottom
                        sx={{
                            fontSize: '35px',
                            fontWeight: 'bold',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                        }}
                        color={'primary'}
                    >
                        Ajouter un nouveau client
                    </Typography>

                    {submitted ? (
                        <div>
                            <Alert severity="success">
                                <AlertTitle>Success</AlertTitle>
                                Le client a été créé avec succès —
                                <strong>
                                    <Link to="/clients" className="text-decoration-none ms-1">
                                        Vérifiez-le!
                                    </Link>
                                </strong>
                            </Alert>
                        </div>
                    ) : (
                        <div className=''>
                            <FormControl fullWidth>
                                <TextField
                                    select
                                    label="Type"
                                    margin="normal"
                                    required
                                    value={selectedOption}
                                    onChange={handleOptionChange}
                                
                                >
                                    <MenuItem value="personne physique">Personne physique</MenuItem>
                                    <MenuItem value="personne morale">Personne morale</MenuItem>
                                    <MenuItem value="organisme public">Organisme public</MenuItem>
                                </TextField>
                            </FormControl>
                            <TextField
                                fullWidth
                                id="fullName"
                                label="Nom"
                                required
                                value={full_name.toUpperCase()}
                                onChange={onChangeFullName}
                                name="fullName"
                                margin="normal"
                            />
                            {showForm && (
                                <>
                                    {(selectedOption !== 'personne physique') && (
                                        <TextField
                                            fullWidth
                                            id="ice"
                                            label="ICE"
                                            required
                                            value={ice}
                                            onChange={onChangeICE}
                                            name="ice"
                                            margin="normal"
                                            
                                        />
                                    )}
                                    {(selectedOption !== 'personne physique' && selectedOption !== 'organisme public') && (
                                        <TextField
                                            fullWidth
                                            id="rc"
                                            label="RC"
                                            required
                                            value={rc}
                                            onChange={onChangeRC}
                                            name="rc"
                                            margin="normal"
                                        
                                        />
                                    )}
                                    {(selectedOption !== 'personne physique' && selectedOption !== 'organisme public') && (
                                        <TextField
                                            fullWidth
                                            id="ifc"
                                            label="IFC"
                                            required
                                            value={ifc}
                                            onChange={onChangeIFC}
                                            name="ifc"
                                            margin="normal"
                                        />
                                    )}
                                </>
                            )}
                            {(selectedOption !== 'organisme public') && (
                                <TextField
                                    fullWidth
                                    id="cin"
                                    label="CIN"
                                    required={selectedOption === 'personne physique'}
                                    value={cin}
                                    onChange={onChangeCin}
                                    name="cin"
                                    margin="normal"
                                
                                />
                            )}
                            <TextField
                                fullWidth
                                id="adresse"
                                label="Adresse"
                                value={adresse}
                                onChange={onChangeAdresse}
                                name="adresse"
                                margin="normal"
                            
                            />
                            <TextField
                                fullWidth
                                id="telephone"
                                label="Telephone"
                                value={telephone}
                                onChange={onChangeTelephone}
                                name="telephone"
                                margin="normal"
                            
                            />
                            <Button
                                variant="contained"
                                color="info"
                                onClick={saveClient}
                                sx={{
                                    marginTop: '1rem',
                                }}
                            >
                                sauvegarder
                            </Button>
                        </div>
                    )}
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={closeSnackbar}
                    >
                        <Alert variant='filled' onClose={closeSnackbar} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Container>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};


export default AddClient;
