import {
    CREATE_FOURNISSEUR_SUCCESS,
    CREATE_FOURNISSEUR_FAILURE,
    UPDATE_FOURNISSEUR_SUCCESS,
    UPDATE_FOURNISSEUR_FAILURE,
    GET_FOURNISSEUR_SUCCESS,
    GET_FOURNISSEUR_FAILURE,
    DELETE_FOURNISSEUR_SUCCESS,
    DELETE_FOURNISSEUR_FAILURE,
    GET_ALL_FOURNISSEURS_SUCCESS,
    GET_ALL_FOURNISSEURS_FAILURE,
  } from '../../actions/types';
  const initialState = {
    fournisseur: null,
    fournisseurs: [],
    error: null,
  };
  
  const fournisseursReducer = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_FOURNISSEUR_SUCCESS:
        return {
          ...state,
          fournisseur: action.payload,
          error: null,
        };
      case CREATE_FOURNISSEUR_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      case UPDATE_FOURNISSEUR_SUCCESS:
        return {
          ...state,
          fournisseur: action.payload,
          error: null,
        };
      case UPDATE_FOURNISSEUR_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      case GET_FOURNISSEUR_SUCCESS:
        return {
          ...state,
          fournisseur: action.payload,
          error: null,
        };
      case GET_FOURNISSEUR_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      case DELETE_FOURNISSEUR_SUCCESS:
        return {
          ...state,
          fournisseur: null,
          error: null,
        };
      case DELETE_FOURNISSEUR_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      case GET_ALL_FOURNISSEURS_SUCCESS:
        return {
          ...state,
          fournisseurs: action.payload,
          error: null,
        };
      case GET_ALL_FOURNISSEURS_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default fournisseursReducer;