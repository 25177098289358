import React from "react";
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import themes from './themes';
import { Routes, Route } from "react-router-dom";
import { lazy } from 'react';
import Loadable from './ui-component/Loadable';
import NavigationScroll from './layout/NavigationScroll';
import UsersList from "./pages/users/UsersList";
import AddUser from "./pages/users/AddUser";
import EditUser from "./pages/users/EditUser";
import MainLayout from "./layout/MainLayout";
import NotFound from "./pages/Errors/NotFound";
import CreateFournisseur from "./pages/fournisseur/AddFournisseur";
import FournisseursList from "./pages/fournisseur/FournisseursList";
import EditFournisseur from "./pages/fournisseur/EditFournisseur";
import AddClient from "./pages/clients/AddClients";
import ClientsList from "./pages/clients/ClientsList";
import UpdateClient from "./pages/clients/EditClient";
import SamplePage from "./views/sample-page";
import CategoriesList from "./pages/categories/CategoriesList";
import AddProduct from "./pages/products/AddProduct";
import ProductsList from "./pages/products/ProductsList";
import UpdateProduct from "./pages/products/UpdateProduct";
import ProductDetail from "./pages/products/ProductDetails";
import CreateOrder from "./pages/orders/CreateOrder";
import OrdersList from "./pages/orders/OrdersList";
import UpdateOrder from "./pages/orders/UpdateOrder";
import CreateStock from "./pages/stock/CreateStock";
import StockList from "./pages/stock/StocksList";
import CreateCommande from "./pages/commandes/CreateCommande";
import CommandesList from "./pages/commandes/CommandesList";
import UpdateCommande from "./pages/commandes/UpdateCommande";
import FacturesList from "./pages/factures/FacturesList";
import AddRecette from "./pages/recettes/AddRecette";
import RecettesList from "./pages/recettes/RecettesList";
import AddSimpleCommande from "./pages/commandes/AddSimpleComm";
import Profile from "./pages/users/Profile";
import OutOfStocksList from "./pages/stock/ListOutOfStocks";
import CommandesExpiredDelivred from "./pages/commandes/CommandesDelivred";
import MinimalLayout from './layout/MinimalLayout';

function App() {
  const customization = useSelector((state) => state.customization);
  const DashboardDefault = Loadable(lazy(() => import('./views/dashboard/Default')));
  const { user: currentUser } = useSelector((state) => state.auth);
  const AuthLogin3 = Loadable(lazy(() => import('./views/pages/authentication/authentication3/Login3')));

  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            {currentUser ?
              (
                <>
                  <Routes>
                    <Route path="/" element={<MainLayout />}>
                      <Route index element={<DashboardDefault />} />
                      <Route path="dashboard" element={<DashboardDefault />} />
                      <Route path="sample-page" element={<SamplePage />} />
                      <Route path="users">
                        <Route path="list" element={<UsersList />} />
                        <Route path="profile" element={<Profile currentUser={currentUser} />} />
                        <Route path="add" element={<AddUser />} />
                        <Route path="edit/:id" element={<EditUser />} />
                      </Route>
                      <Route path="fournisseurs">
                        <Route path="list" element={<FournisseursList currentUser={currentUser} />} />
                        <Route path="add" element={<CreateFournisseur />} />
                        <Route path="update/:id" element={<EditFournisseur currentUser={currentUser} />} />
                      </Route>
                      <Route path="clients">
                        <Route path="list" element={<ClientsList currentUser={currentUser} />} />
                        <Route path="add" element={<AddClient currentUser={currentUser} />} />
                        <Route path="update/:id" element={<UpdateClient currentUser={currentUser} />} />
                      </Route>
                      <Route path="categories">
                        <Route path="list" element={<CategoriesList currentUser={currentUser} />} />
                      </Route>
                      <Route path="products">
                        <Route path="list" element={<ProductsList currentUser={currentUser} />} />
                        <Route path="add" element={<AddProduct currentUser={currentUser} />} />
                        <Route path="update/:id" element={<UpdateProduct currentUser={currentUser} />} />
                        <Route path="details/:id" element={<ProductDetail currentUser={currentUser} />} />

                      </Route>
                      <Route path="purchases">
                        <Route path="list" element={<OrdersList currentUser={currentUser} />} />
                        <Route path="add" element={<CreateOrder currentUser={currentUser} />} />
                        <Route path="update/:id" element={<UpdateOrder currentUser={currentUser} />} />

                      </Route>
                      <Route path="stock">
                        <Route path="list" element={<StockList currentUser={currentUser} />} />
                        <Route path="add" element={<CreateStock currentUser={currentUser} />} />
                        <Route path="list/outofstock" element={<OutOfStocksList currentUser={currentUser} />} />

                      </Route>
                      <Route path="commandes">
                        <Route path="list" element={<CommandesList currentUser={currentUser} />} />
                        <Route path="add" element={<CreateCommande currentUser={currentUser} />} />
                        <Route path="add/simple" element={<AddSimpleCommande currentUser={currentUser} />} />
                        <Route path="list/delivred/expired" element={<CommandesExpiredDelivred currentUser={currentUser} />} />
                        <Route path="update/:id" element={<UpdateCommande currentUser={currentUser} />} />

                      </Route>
                      <Route path="recettes">
                        <Route path="add" element={<AddRecette currentUser={currentUser} />} />
                        <Route path="list" element={<RecettesList currentUser={currentUser} />} />

                      </Route>
                      <Route path="factures">
                        <Route path="list" element={<FacturesList currentUser={currentUser} />} />
                      </Route>
                      <Route path="*" element={<NotFound />} />
                    </Route>
                  </Routes>
                </>
              ) : (
                <>
                  <Routes>
                    <Route path="/" element={<MinimalLayout />}>
                      <Route path="/" element={<AuthLogin3 />} />
                      <Route path="*" element={<AuthLogin3 />} />
                    </Route>
                  </Routes>
                </>
              )}
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
