// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill } from '@tabler/icons-react';
import { ListAlt, ChecklistRtl, AddShoppingCart, Store } from '@mui/icons-material';

const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  ChecklistRtl,
  ListAlt,
  AddShoppingCart,
  Store
};

const utilities = {
  id: 'utilities',
  title: 'Utilities',
  type: 'group',
  children: [
    {
      id: 'products',
      title: 'Products',
      type: 'item',
      url: '/products/list',
      icon: icons.ChecklistRtl,
      breadcrumbs: false
    },
    {
      id: 'categories',
      title: 'Catégories',
      type: 'item',
      url: '/categories/list',
      icon: icons.ListAlt,
      breadcrumbs: false
    },
    {
      id: 'purchases',
      title: 'Achat',
      type: 'item',
      url: '/purchases/list',
      icon: icons.AddShoppingCart,
      breadcrumbs: false
    },
    {
      id: 'stock',
      title: 'Stock',
      type: 'item',
      url: '/stock/list',
      icon: icons.Store,
      breadcrumbs: false
    }
  ]
};

export default utilities;
