import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAllClients, deleteClient } from '../../actions/ClientActions';
import {
    Container,
    Typography,
    TextField,
    List,
    ListItem,
    ListItemText,
    Button,
    Pagination,
    Box,
    Snackbar,
    Alert,
    Grid,
    Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import AccessDenied from '../Errors/AccessDenied';
import AddIcon from '@mui/icons-material/Add';
import ClientInfo from './ClientInfo';


const ClientsList = ({ currentUser }) => {
    const [currentClient, setCurrentClient] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [currentPage, setCurrentPage] = useState(1);
    // const [dialogOpen, setDialogOpen] = useState(false);
    const clientsPerPage = window.innerWidth <= 768 ? 8 : 15;
    const [searchTerm, setSearchTerm] = useState('');
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const dispatch = useDispatch();
    const clients = useSelector((state) => state.clients.clients);
    const [dialogProfOpen, setDialogProfOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchAllClients());
    }, [dispatch]);

    const openDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    // const openDialog = () => {
    //     setDialogOpen(true);
    // };

    // const closeDialog = () => {
    //     setDialogOpen(false);
    // };

    const handleConfirmDelete = () => {
        if (currentClient) {
            dispatch(deleteClient(currentClient.id))
                .then(() => {
                    dispatch(fetchAllClients());
                    setCurrentClient(null);
                    setCurrentIndex(-1);
                    setSnackbarMessage('Client supprimé avec succès');
                    setSnackbarOpen(true);
                    closeDeleteModal();
                })
                .catch(() => {
                    setSnackbarMessage('Une erreur s\'est produite lors de la suppression du client');
                    setSnackbarOpen(true);
                });
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSetActiveTutorial = (client, index) => {
        setCurrentClient(client);
        setCurrentIndex(index);
    };

    const handleSearch = (e) => {
        setCurrentPage(1);
        setSearchTerm(e.target.value);
    };

    const indexOfLastClient = currentPage * clientsPerPage;
    const indexOfFirstClient = indexOfLastClient - clientsPerPage;
    const filteredClients = clients.filter((client) =>
        client.full_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
        .sort((a, b) => {
            if (a.createdAt === '0000-00-00' && b.createdAt !== '0000-00-00') {
                return 1;
            } else if (b.createdAt === '0000-00-00' && a.createdAt !== '0000-00-00') {
                return -1;
            } else {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }
        });

    const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);

    const renderClients = () => {
        return (
            <List>
                {currentClients.map((client, index) => (
                    <ListItem
                        button
                        key={index}
                        selected={index === currentIndex}
                        onClick={() => handleSetActiveTutorial(client, index)}
                    >
                        <ListItemText primary={client.full_name} />
                    </ListItem>
                ))}
            </List>
        );
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(filteredClients.length / clientsPerPage);

        return (
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)}
                color="info"
                sx={{ mt: 3 }}
            />
        );
    };

    const AddButton = (
        <Box
            component={Link}
            to={'/clients/add'}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.25rem"
            height="3.25rem"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{
                cursor: "pointer",
                background: "#0288d1",
                color: "#fff !important"
            }}
        >
            <AddIcon fontSize="small" color="inherit" />
        </Box>
    );

    const openDialogProf = () => {
        setDialogProfOpen(true);
    };

    const closeDialogProf = () => {
        setDialogProfOpen(false);
    };

    return (
        <>
            {currentUser && ((currentUser?.role === "Administrateurs") || currentUser.permissions.includes("Consulter des clients")) ? (
                <Container maxWidth="lg" sx={{ mt: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h2" align="center">
                                Clients
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" gutterBottom>
                                Liste des clients
                            </Typography>
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Chercher un client"
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                            {renderClients()}
                            {filteredClients.length > clientsPerPage && renderPagination()}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {currentClient ? (
                                <div>
                                    <Typography variant="h4" gutterBottom>
                                        Informations de Client
                                    </Typography>
                                    {(currentUser?.role === "Administrateurs" || currentUser.permissions.includes("Consulter tous les informations de clients")) && (
                                        <>
                                            <Grid container spacing={1} mt={4}>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        <strong>ID:</strong> {currentClient.id}
                                                    </Typography>
                                                </Grid>
                                                {currentClient.type !== 'personne physique' && (
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            <strong>ICE:</strong> {currentClient.ice}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                {(currentClient.type !== 'personne physique' && currentClient.type !== 'organisme public') && (
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            <strong>RC:</strong> {currentClient.rc}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                {(currentClient.type !== 'personne physique' && currentClient.type !== 'organisme public') && (
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            <strong>IF:</strong> {currentClient.ifc}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        <strong>Type:</strong> {currentClient.type}
                                                    </Typography>
                                                </Grid>
                                                {currentClient.type !== 'organisme public' && (
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            <strong>CIN:</strong> {currentClient.cin}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        <strong>Nom & Prénom:</strong> {currentClient.full_name}
                                                    </Typography>
                                                </Grid>
                                                {(currentUser?.role === "Administrateurs" || currentUser.permissions.includes("Consulter tous les informations de clients")) && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Typography>
                                                                <strong>Téléphone:</strong> {currentClient.telephone}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography>
                                                                <strong>Adresse:</strong> {currentClient.adresse}
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </>
                                    )}
                                    <Box mt={2}>
                                        {currentUser && ((currentUser?.role === "Administrateurs" || currentUser.permissions.includes("Consulter les fichiers") || currentUser.permissions.includes("Importer les fichiers"))) && (
                                            <Button variant="contained" color="info" onClick={openDialogProf}>
                                                Carte Client
                                            </Button>
                                        )}
                                        {currentUser && ((currentUser?.role === "Administrateurs" || currentUser.permissions.includes("Supprimer les clients"))) && (
                                            <Button onClick={openDeleteModal} variant="contained" color="error" sx={{ marginLeft: '1rem' }} >
                                                Supprimer
                                            </Button>
                                        )}
                                        {currentUser && ((currentUser?.role === "Administrateurs" || currentUser.permissions.includes("Consulter les fichiers") || currentUser.permissions.includes("Importer les fichiers"))) && (
                                            <Button variant="contained" color="warning" sx={{ marginLeft: '1rem' }}>
                                                Fichiers
                                            </Button>
                                        )}
                                    </Box>
                                </div>
                            ) : (
                                <Typography variant="body1">
                                    Veuillez cliquer sur un client...
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    {AddButton}
                    <Dialog
                        open={isDeleteModalOpen}
                        onClose={closeDeleteModal}
                    >
                        <DialogTitle
                            sx={{
                                color: '#fff',
                                background: 'red',
                                textAlign: 'center',
                                marginBottom: '1rem',
                            }}
                        >Confirmer la suppression</DialogTitle>
                        <DialogContent>
                            <Typography>
                                Êtes-vous sûr de vouloir supprimer ce client ?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDeleteModal}>Annuler</Button>
                            <Button onClick={handleConfirmDelete}
                                sx={{
                                    color: 'white !important',
                                    backgroundColor: 'red !important',
                                }}
                            >
                                Supprimer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={snackbarMessage.includes('succès') ? 'success' : 'error'}
                            sx={{ width: '100%' }}
                        >
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={dialogProfOpen}
                        onClose={closeDialogProf}
                        fullWidth
                        maxWidth="100%"
                    >
                        {currentClient && (
                            <>
                                <DialogTitle textAlign={'center'} style={{ fontSize: '24px' }}>
                                    Détails de la Client
                                </DialogTitle>
                                <DialogContent>
                                    <ClientInfo client={currentClient} />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={closeDialogProf} color="primary">
                                        Fermer
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                    </Dialog>
                </Container>
            ) : (
                <AccessDenied />
            )}
        </>
    );
};

export default ClientsList;


