import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllStocks, deleteStock } from '../../actions/StockActions';
import { Link } from "react-router-dom";
import {
    Box,
    Typography,
    IconButton,
    TextField,
    Snackbar,
    Alert,
    Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import AccessDenied from "../Errors/AccessDenied";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery } from "@mui/material";
import { encodeId } from '../../Crypte';
import {
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { Delete, BorderColor, ControlPoint, CloudUpload } from '@mui/icons-material';
import { Add } from '@mui/icons-material';
import UpdateStock from './UpdateStock';

const StockList = ({ currentUser }) => {
    const dispatch = useDispatch();
    const stocks = useSelector((state) => state.stock.stocks);
    const [selectedItem, setSelectedItem] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredStock, setFilteredStock] = useState([]);

    useEffect(() => {
        dispatch(fetchAllStocks());
    }, [dispatch]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const filteredStock = stocks.filter((stock) => {
            const product = stock?.product?.name || '';

            return (
                product.toLowerCase().includes(searchTerm.toLowerCase())

            );
        })
            .sort((a, b) => {
                if (a.lastDate === '0000-00-00' && b.lastDate !== '0000-00-00') {
                    return 1;
                } else if (b.lastDate === '0000-00-00' && a.lastDate !== '0000-00-00') {
                    return -1;
                } else {
                    return new Date(b.lastDate) - new Date(a.lastDate);
                }
            });

        setFilteredStock(filteredStock);
    }, [stocks, searchTerm]);


    const Pagination = ({ page, onPageChange, className }) => {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <MuiPagination
                color="secondary"
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                    onPageChange(event, newPage - 1);
                }}
            />
        );
    }

    const CustomPagination = (props) => {
        return <GridPagination ActionsComponent={Pagination} {...props} />;
    }
    // {
    //     field: 'statut',
    //     headerName: 'Statut',
    //     ...(isMobile ? { width: 120 } : { flex: 1 }),
    //     valueGetter: (params) => params.row.statut || '-',
    //     renderCell: (params) => {
    //         let statusColor;

    //         switch (params.row.statut) {
    //             case 'En stock':
    //                 statusColor = 'green';
    //                 break;
    //             case 'En rupture de stock':
    //                 statusColor = 'red';
    //                 break;
    //             default:
    //                 statusColor = 'red';
    //         }

    //         return (
    //             <span style={{ backgroundColor: statusColor, color: "#fff" }}>{params.row.statut}</span>
    //         );
    //     },
    // },

    const columns = [
        {
            field: 'lastDate', headerName: 'Dernière date mise à jour',
            ...(isMobile ? { width: 120 } : { flex: 1.5 }),
        },
        {
            field: 'quantity', headerName: 'Quantité',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'product?.name', headerName: 'Produit',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row?.product?.name || '',
        },
        {
            field: 'fournisseur?.name', headerName: 'Fournisseur',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row?.fournisseur?.name || 'Acunne !',
        },
        {
            field: 'status', headerName: 'Statut',
            renderCell: (params) => {
                const { quantity } = params.row;
                let statut;
                let statusColor;

                if (quantity > 0) {
                    statut = 'En stock';
                    statusColor = 'green';
                } else {
                    statut = 'En rupture de stock';
                    statusColor = 'red';
                }

                return (
                    <span style={{ backgroundColor: statusColor, color: "#fff" }}>{statut}</span>
                );
            },
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    <IconButton
                        className='me-1 ms-1'
                        size='small'
                        color='error'
                        onClick={() => openDielog(params.row)}>
                        <Delete />
                    </IconButton>
                    <Tooltip title='Modifier' >
                        <IconButton
                            // component={Link}
                            className='mS-1'
                            size='small'
                            color='info'
                            // to={`/purchases/update/${encodeId(params.id)}`}
                            onClick={() => openUpdateDielog(params.row)}
                        >
                            <BorderColor />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
    ];

    const handleDeleteConfirm = () => {
        dispatch(deleteStock(selectedItem))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Supprimé avec succès.');
                setSnackbarOpen(true);
                dispatch(fetchAllStocks());
            })
            .catch((error) => {
                console.error('Error deleting user:', error);
                setSnackbarSeverity('error');
                setSnackbarMessage('Error deleting user. Please try again.');
                setSnackbarOpen(true);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };

    const openDielog = (item) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    }

    const closeDilaog = () => {
        setSelectedItem(null);
        setDeleteDialogOpen(false);
    }

    const openUpdateDielog = (item) => {
        setSelectedItem(item);
        setUpdateDialogOpen(true);
    }

    const closeUpdateDilaog = () => {
        setSelectedItem(null);
        setUpdateDialogOpen(false);
    }
    // const openUploadDielog = (item) => {
    //     setSelectedItem(item);
    //     setUploadDialogOpen(true);
    // }

    // const closeUploadDilaog = () => {
    //     setSelectedItem(null);
    //     setUploadDialogOpen(false);
    // }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const AddButton = (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.25rem"
            height="3.25rem"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{
                cursor: "pointer",
                background: "#0288d1",
                color: "#fff !important"
            }}
            component={Link}
            to={'/stock/add'}
        >
            <Add fontSize="small" color="inherit" />
        </Box>
    );
    return (
        <>
            {currentUser && (currentUser.role === "Administrateurs" || currentUser.permissions.includes("Consulter les projets")) ? (
                <>
                    <Typography
                        sx={{
                            fontSize: '36px',
                            textAlign: 'center',
                            marginTop: '12px',
                            fontWeight: 'bold',
                            color: '#3e4396',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                        }}
                        variant="h2"
                        gutterBottom
                    >
                        Stock
                    </Typography>
                    <Box>
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Rechercher par produit"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                        <Box
                            m="0 0 0 0"
                            height="75vh"
                        >
                            <DataGrid
                                rows={filteredStock}
                                columns={columns}
                                pagination
                                slots={{
                                    toolbar: GridToolbar,
                                    pagination: CustomPagination,
                                }}
                                slotProps={{
                                    columnMenu: { background: 'red' },
                                }}
                                initialState={{
                                    ...filteredStock.initialState,
                                    pagination: { paginationModel: { pageSize: 25 } },
                                }}
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            />
                        </Box>
                    </Box>
                    {AddButton}
                    <Dialog
                        open={deleteDialogOpen}
                        onClose={closeDilaog}
                    >
                        <DialogTitle
                            sx={{
                                color: '#fff',
                                background: 'red',
                                textAlign: 'center',
                                marginBottom: '1rem',
                            }}
                        >Confirmer la suppression</DialogTitle>
                        <DialogContent>
                            <Typography>
                                Êtes-vous sûr de vouloir supprimer ce produit?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDilaog}>Annuler</Button>
                            <Button onClick={handleDeleteConfirm}
                                sx={{
                                    color: 'white !important',
                                    backgroundColor: 'red !important',
                                }}
                            >
                                Supprimer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={updateDialogOpen}
                        onClose={closeUpdateDilaog}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle
                            textAlign={'center'}
                            fontSize={'25px'}
                            fontWeight={'bold'}
                        >
                            Modifier Stock
                        </DialogTitle>
                        <DialogContent>
                            {currentUser && ((currentUser?.role === "Administrateurs") || currentUser.permissions.includes("Importer les fichiers")) && (
                                <>
                                    <UpdateStock stock={selectedItem} />
                                </>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeUpdateDilaog}>Annuler</Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};

export default StockList;

