import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllFactures, deleteFacture } from '../../actions/FactureActions';
import { Link } from "react-router-dom";
import {
    Box,
    Typography,
    IconButton,
    TextField,
    Snackbar,
    Alert,
    Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import AccessDenied from "../Errors/AccessDenied";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery } from "@mui/material";
import { encodeId } from '../../Crypte';
import {
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import { Delete, BorderColor, Add, Visibility, Print, FileCopy } from '@mui/icons-material';
import FacturePreview from './FacturePreview';

const FacturesList = ({ currentUser }) => {
    const dispatch = useDispatch();
    const factures = useSelector((state) => state.factures.factures);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredFactures, setFilteredFactures] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [prevDialogOpen, setPrevDialogOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isFactureDialogOpen, setIsFactureDialogOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchAllFactures());
    }, [dispatch]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const filteredFactures = factures.filter((facture) => {
            const numero = facture?.numero || '';
            return (
                numero.toLowerCase().includes(searchTerm.toLowerCase())
            );
        })
            .sort((a, b) => {
                if (a.date === '0000-00-00' && b.date !== '0000-00-00') {
                    return 1;
                } else if (b.date === '0000-00-00' && a.date !== '0000-00-00') {
                    return -1;
                } else {
                    return new Date(b.date) - new Date(a.date);
                }
            });

        setFilteredFactures(filteredFactures);
    }, [factures, searchTerm]);


    const Pagination = ({ page, onPageChange, className }) => {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <MuiPagination
                color="secondary"
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                    onPageChange(event, newPage - 1);
                }}
            />
        );
    }

    const CustomPagination = (props) => {
        return <GridPagination ActionsComponent={Pagination} {...props} />;
    }

    const columns = [
        {
            field: 'numero', headerName: 'Numero',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'client?.full_name', headerName: 'Client',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row?.commande?.client?.full_name || '',
        },
        {
            field: 'montant_ttc', headerName: 'Montant TTC',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'statut',
            headerName: 'Statut de paiement',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.statut || '-',
            renderCell: (params) => {
                let statusColor;

                switch (params.row.statut) {
                    case 'En instance':
                        statusColor = 'orange';
                        break;
                    case 'Payé':
                        statusColor = 'green';
                        break;
                    default:
                        statusColor = 'red';
                }

                return (
                    <span style={{ backgroundColor: statusColor, color: "#fff" }}>{params.row.statut}</span>
                );
            },
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    <IconButton
                        size='small'
                        color='error'
                        onClick={() => openDielog(params.row)}>
                        <Delete />
                    </IconButton>
                    {/* <IconButton
                        component={Link}
                        size='small'
                        color='info'
                        to={`/factures/update/${encodeId(params.id)}`}
                    >
                        <BorderColor />
                    </IconButton> */}
                    <Tooltip title='Voir les détails' >
                        <IconButton
                            size='small'
                            color='success'
                            onClick={() => openPrevDielog(params.row)}
                        >
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                    {/* {params.row.statut === 'Payé' &&
                        <Tooltip title='imprimer le reçu de paiement' >
                            <IconButton
                                size='small'
                                sx={{
                                    color: 'orange'
                                }}
                                onClick={() => openDialog(params.row)}
                            >
                                <Print />
                            </IconButton>
                        </Tooltip>
                    } */}
                </>
            ),
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
    ];

    const handleDeleteConfirm = () => {
        dispatch(deleteFacture(selectedItem))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Supprimé avec succès.');
                setSnackbarOpen(true);
                dispatch(fetchAllFactures());
            })
            .catch((error) => {
                console.error('Error deleting:', error);
                setSnackbarSeverity('error');
                setSnackbarMessage('Erreur de suppression. Veuillez réessayer.');
                setSnackbarOpen(true);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };

    const openDielog = (item) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    }

    const closeDilaog = () => {
        setSelectedItem(null);
        setDeleteDialogOpen(false);
    }

    const openPrevDielog = (item) => {
        setSelectedItem(item);
        setPrevDialogOpen(true);
    }

    const closePrevDilaog = () => {
        setSelectedItem(null);
        setPrevDialogOpen(false);
    }

    const openFactureDialog = (item) => {
        setSelectedItem(item);
        setIsFactureDialogOpen(true);
    }

    const closeFactureDialog = () => {
        setSelectedItem(null);
        setIsFactureDialogOpen(false);
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const openDialog = (item) => {
        setSelectedItem(item);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setSelectedItem(null);
        setIsDialogOpen(false);
    };

    const AddButton = (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.25rem"
            height="3.25rem"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{
                cursor: "pointer",
                background: "#0288d1",
                color: "#fff !important"
            }}
            component={Link}
            to={'/factures/add'}
        >
            <Add fontSize="small" color="inherit" />
        </Box>
    );

    return (
        <>
            {currentUser && (currentUser.role === "Administrateurs" || currentUser.permissions.includes("Consulter les projets")) ? (
                <>
                    <Typography
                        sx={{
                            fontSize: '36px',
                            textAlign: 'center',
                            marginTop: '12px',
                            fontWeight: 'bold',
                            color: '#3e4396',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                        }}
                        variant="h2"
                        gutterBottom
                    >
                        Factures
                    </Typography>
                    <Box>
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Rechercher un projet par référence, client, ou numéro"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                        <Box
                            m="0 0 0 0"
                            height="75vh"
                        >
                            <DataGrid
                                rows={filteredFactures}
                                columns={columns}
                                pagination
                                slots={{
                                    toolbar: GridToolbar,
                                    pagination: CustomPagination,
                                }}
                                slotProps={{
                                    columnMenu: { background: 'red' },
                                }}
                                initialState={{
                                    ...filteredFactures.initialState,
                                    pagination: { paginationModel: { pageSize: 25 } },
                                }}
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            />
                        </Box>
                    </Box>
                    {AddButton}
                    <Dialog
                        open={deleteDialogOpen}
                        onClose={closeDilaog}
                    >
                        <DialogTitle
                            sx={{
                                color: '#fff',
                                background: 'red',
                                textAlign: 'center',
                                marginBottom: '1rem',
                            }}
                        >Confirmer la suppression</DialogTitle>
                        <DialogContent>
                            <Typography>
                                Êtes-vous sûr de vouloir supprimer cette facture ?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDilaog}>Annuler</Button>
                            <Button onClick={handleDeleteConfirm}
                                sx={{
                                    color: 'white !important',
                                    backgroundColor: 'red !important',
                                }}
                            >
                                Supprimer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={prevDialogOpen}
                        onClose={closePrevDilaog}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogContent>
                            {selectedItem &&
                                <FacturePreview facture={selectedItem} />
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closePrevDilaog}>Annuler</Button>
                        </DialogActions>
                    </Dialog>

                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert variant='filled' onClose={handleSnackbarClose} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};

export default FacturesList;

