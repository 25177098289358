import {
  CREATE_COMMANDE_SUCCESS,
  CREATE_COMMANDE_FAILURE,
  UPDATE_COMMANDE_SUCCESS,
  UPDATE_COMMANDE_FAILURE,
  GET_COMMANDE_SUCCESS,
  GET_COMMANDE_FAILURE,
  DELETE_COMMANDE_SUCCESS,
  DELETE_COMMANDE_FAILURE,
  GET_ALL_COMMANDES_SUCCESS,
  GET_ALL_COMMANDES_FAILURE,
  GET_ALL_EXPIRED_COMMANDES_SUCCESS,
  GET_ALL_EXPIRED_COMMANDES_FAILURE,
} from '../../actions/types';

const initialState = {
  commande: null,
  commandes: [],
  delivExper: [],
  error: null,
};

const commandeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_COMMANDE_SUCCESS:
      return {
        ...state,
        commande: action.payload,
        error: null,
      };
    case CREATE_COMMANDE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_COMMANDE_SUCCESS:
      return {
        ...state,
        commande: action.payload,
        error: null,
      };
    case UPDATE_COMMANDE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_COMMANDE_SUCCESS:
      return {
        ...state,
        commande: action.payload,
        error: null,
      };
    case GET_COMMANDE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_COMMANDE_SUCCESS:
      return {
        ...state,
        commande: null,
        error: null,
      };
    case DELETE_COMMANDE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_COMMANDES_SUCCESS:
      return {
        ...state,
        commandes: action.payload,
        error: null,
      };
    case GET_ALL_COMMANDES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_EXPIRED_COMMANDES_SUCCESS:
      return {
        ...state,
        delivExper: action.payload,
        error: null,
      };
    case GET_ALL_EXPIRED_COMMANDES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default commandeReducer;