import  API_URL from './apiUrl';

class RecetteService {
  createRecette(recetteData) {
    return API_URL.post("/recettes/create/", recetteData);
  }

  getAllRecettes() {
    return API_URL.get("/recettes");
  }

  updateRecette(recetteId, updatedRecette) {
    return API_URL.put(`/recettes/update/${recetteId}`, updatedRecette);
  }

  fetchRecette(recetteId) {
    return API_URL.get(`/recettes/${recetteId}`);
  }

  deleteRecette(recetteId) {
    return API_URL.delete(`/recettes/delete/${recetteId}`);
  }
  
}

export default new RecetteService();
