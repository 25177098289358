import { ShoppingCartCheckout, Description, CurrencyExchange } from '@mui/icons-material';
// import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
const icons = {
  ShoppingCartCheckout,
  Description,
  CurrencyExchange
};


const other = {
  id: 'finance',
  title: 'Gestion financière',
  type: 'group',
  children: [
    {
      id: 'commandes',
      title: 'Commandes',
      type: 'item',
      url: '/commandes/list',
      icon: icons.ShoppingCartCheckout,
      breadcrumbs: false
    },
    {
      id: 'recettes',
      title: 'Recettes',
      type: 'item',
      url: '/recettes/list',
      icon: icons.CurrencyExchange,
      breadcrumbs: false
    },
    {
      id: 'factures',
      title: 'Factures',
      type: 'item',
      url: '/factures/list',
      icon: icons.Description,
      breadcrumbs: false
    },
    // {
    //   id: 'documentation',
    //   title: 'Documentation',
    //   type: 'item',
    //   url: 'https://codedthemes.gitbook.io/berry/',
    //   icon: icons.IconHelp,
    //   external: true,
    //   target: true
    // }
  ]
};

export default other;
