import ClientServices from '../services/clientServices';
import {
    CREATE_CLIENT_SUCCESS,
    CREATE_CLIENT_FAILURE,
    GET_ALL_CLIENTS_SUCCESS,
    GET_ALL_CLIENTS_FAILURE,
    GET_CLIENT_SUCCESS,
    GET_CLIENT_FAILURE,
    UPDATE_CLIENT_SUCCESS,
    UPDATE_CLIENT_FAILURE,
    DELETE_CLIENT_SUCCESS,
    DELETE_CLIENT_FAILURE,
} from './types';

export const createClient = (clientData) => {
    return (dispatch) => {
      return ClientServices.createClient(clientData)
        .then((response) => {
          dispatch({
            type: CREATE_CLIENT_SUCCESS,
            payload: response.data,
          });
          return response;
        })
        .catch((error) => {
          dispatch({
            type: CREATE_CLIENT_FAILURE,
            payload: error.message,
          });
          throw error;
        });
    };
  };
  
  export const fetchAllClients = () => {
    return (dispatch) => {
      return ClientServices.getAllClient()
        .then((response) => {
          dispatch({
            type: GET_ALL_CLIENTS_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_ALL_CLIENTS_FAILURE,
            payload: error.message,
          });
        });
    };
  };
  
  export const updateClient = (clientId, updatedClient) => {
    return (dispatch) => {
      return ClientServices.updateClient(clientId, updatedClient)
        .then((response) => {
          dispatch({
            type: UPDATE_CLIENT_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_CLIENT_FAILURE,
            payload: error.message,
          });
        });
    };
  };
  
  export const getClient = (clientId) => {
    return (dispatch) => {
      return ClientServices.fetchClient(clientId)
        .then((response) => {
          dispatch({
            type: GET_CLIENT_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_CLIENT_FAILURE,
            payload: error.message,
          });
        });
    };
  };
  
  export const deleteClient = (clientId) => {
    return (dispatch) => {
      return ClientServices.deleteClient(clientId)
        .then((response) => {
          dispatch({
            type: DELETE_CLIENT_SUCCESS,
            payload: response.data,
          });
          return response;
        })
        .catch((error) => {
          dispatch({
            type: DELETE_CLIENT_FAILURE,
            payload: error.message,
          });
          throw error;
        });
    };
  };