import OrderService from '../services/orderServices';
import {
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  GET_ALL_ORDERS_SUCCESS,
  GET_ALL_ORDERS_FAILURE,
} from './types';

export const createOrder = (orderData) => {
  return (dispatch) => {
    return OrderService.createOrder(orderData)
      .then((response) => {
        dispatch({
          type: CREATE_ORDER_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: CREATE_ORDER_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const fetchAllOrders = () => {
  return (dispatch) => {
    return OrderService.getAllOrders()
      .then((response) => {
        dispatch({
          type: GET_ALL_ORDERS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_ORDERS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateOrder = (orderId, updatedOrder) => {
  return (dispatch) => {
    return OrderService.updateOrder(orderId, updatedOrder)
      .then((response) => {
        dispatch({
          type: UPDATE_ORDER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_ORDER_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const orderConfirmation = (orderId, updatedOrder) => {
  return (dispatch) => {
    return OrderService.confirmOrder(orderId, updatedOrder)
      .then((response) => {
        dispatch({
          type: UPDATE_ORDER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_ORDER_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const fetchOrder = (orderId) => {
  return (dispatch) => {
    return OrderService.fetchOrder(orderId)
      .then((response) => {
        dispatch({
          type: GET_ORDER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ORDER_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteOrder = (orderId) => {
  return (dispatch) => {
    return OrderService.deleteOrder(orderId)
      .then((response) => {
        dispatch({
          type: DELETE_ORDER_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: DELETE_ORDER_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};
