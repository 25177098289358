import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    Paper,
    Divider,
    useMediaQuery,
    Grid,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import burceMars from "../../assets/images/profile.jpg";
import { useSelector, useDispatch } from 'react-redux';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { Link } from 'react-router-dom';
import UpdateUser from './UpdateUser';
import { fetchUserData } from '../../actions/UsersActions';
import UpdatePassword from './UpdatePassword';

const Profile = ({ currentUser }) => {
    const [tabValue, setTabValue] = useState(0);
    const userId = currentUser?.id;
    const [profileImage, setProfileImage] = useState(burceMars);
    const isMobile = useMediaQuery('(max-width:600px)');
    const dispatch = useDispatch();
    const user = useSelector((state) => state.users.user);

    useEffect(() => {
        dispatch(fetchUserData(userId));
    }, [dispatch, userId]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Container
                sx={{
                    padding: "2rem",
                    marginTop: "3%",
                    marginBottom: "3%",
                    borderRadius: "0.5rem",
                    background: "#fff",
                }}
                fullWidth
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <Paper
                            sx={{
                                textAlign: "center",
                                padding: "2%",
                                textAlign: "center",
                                "& img": {
                                    width: "60%",
                                    height: "100%",
                                },
                                "& .file": {
                                    position: "relative",
                                    overflow: "hidden",
                                    marginTop: "-20%",
                                    width: "100%",
                                    border: "none",
                                    borderRadius: 0,
                                    fontSize: "15px",
                                    background: "#212529b8",
                                },
                                "& .file input": {
                                    position: "absolute",
                                    opacity: 0,
                                    right: 0,
                                    top: 0,
                                },
                            }}
                        >
                            <Box className="profile-img p-2">
                                <img
                                    src={profileImage}
                                    alt={user?.nom}
                                    style={{ width: "100%", height: '80%', borderRadius: "10px" }}
                                />
                                <Box className="file btn btn-primary">
                                    <PhotoCameraIcon />
                                    &nbsp;Change Photo
                                    <input
                                        type="file"
                                    />
                                </Box>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box
                            sx={{
                                "& h5": {
                                    color: "#333",
                                },
                                "& h6": {
                                    color: "#0062cc",
                                },
                                "& .proile-rating": {
                                    fontSize: "12px",
                                    color: "#818182",
                                    marginTop: "5%",
                                    "& span": {
                                        color: "#495057",
                                        fontSize: "15px",
                                        fontWeight: 600,
                                    },
                                },
                                "& .profile-head .nav-tabs": {
                                    marginBottom: "5%",
                                },
                                "& .profile-head .nav-tabs .nav-link": {
                                    fontWeight: 600,
                                    border: "none",
                                },
                                "& .profile-head .nav-tabs .nav-link.active": {
                                    border: "none",
                                    borderBottom: "2px solid #0062cc",
                                },
                                "& .profile-edit-btn": {
                                    border: "none",
                                    borderRadius: "1.5rem",
                                    width: "70%",
                                    padding: "2%",
                                    fontWeight: 600,
                                    color: "#6c757d",
                                    cursor: "pointer",
                                },
                            }}
                        >
                            <div className="profile-head">
                                <Typography variant="h2">
                                    {user?.nom?.toLocaleUpperCase()}&nbsp;{user?.prenom?.toLocaleUpperCase()}
                                </Typography>
                                <Divider
                                    style={{
                                        margin: "0px !important",
                                        width: "50%",
                                        height: "4px",
                                        backgroundColor: "rgba(18, 104, 255, 1)",
                                    }}
                                />
                                {/* <div className="d-flex">
                                    <Typography variant="h5">Poste:&nbsp;</Typography>
                                    <Typography variant="h6">{user?.role}</Typography>
                                </div> */}
                                <p
                                    className="proile-rating"
                                    style={{ marginTop: "14px !important" }}
                                >
                                    DATE DE RECRUTEMENT: <span>{user?.date_recrutement || '-'}</span>
                                </p>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    id="myTab"
                                    role="tablist"
                                >
                                    <Tab
                                        label="Informations"
                                        id="home-tab"
                                        aria-controls="home"
                                        aria-selected={tabValue === 0}
                                    />
                                    <Tab
                                        label="Modifier"
                                        id="taches-tab"
                                        aria-controls="taches"
                                        aria-selected={tabValue === 1}
                                    />
                                    <Tab
                                        label="Mot de passe"
                                        id="profile-tab"
                                        aria-controls="profile"
                                        aria-selected={tabValue === 2}
                                    />
                                </Tabs>
                                {tabValue === 0 && (
                                    <div className='container mt-4'>
                                        <Grid item xs={12}>
                                            <div
                                                style={{ display: "flex" }}
                                            >
                                                <div style={{ flex: "1", marginTop: "12px" }}>
                                                    <Typography
                                                        variant="h6"
                                                    >
                                                        <strong>Nom & Prénom:</strong>
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"

                                                    >
                                                        <strong>Email:</strong>
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"

                                                    >
                                                        <strong>Username:</strong>
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"

                                                    >
                                                        <strong>Telephone:</strong>
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"

                                                    >
                                                        <strong>Adresse:</strong>
                                                    </Typography>

                                                </div>
                                                <div style={{ flex: "1", marginTop: "12px" }}>
                                                    <Typography variant="h6">
                                                        {user?.nom}&nbsp;{user?.prenom}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {user?.email || "-"}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {user?.username || "-"}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {user?.telephone || "-"}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {user?.adresse || "-"}
                                                    </Typography>

                                                </div>
                                            </div>
                                        </Grid>
                                    </div>
                                )}
                                {tabValue === 1 && (
                                    <div className='mt-4'>
                                        <UpdateUser user={user} />
                                    </div>
                                )}
                                {tabValue === 2 && (
                                    <div className='mt-4'>
                                        <UpdatePassword userId={user?.id} />
                                    </div>
                                )}
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        {!isMobile &&
                            <Button
                                className="profile-edit-btn"
                                name="btnAddMore"
                                variant="contained"
                                sx={{
                                    color: "#fff !important",
                                }}
                                onClick={() => setTabValue(1)}
                            >
                                <ChangeCircleIcon />
                                &nbsp;Modifier
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Profile;
