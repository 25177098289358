import { Typography, Grid, Card, CardHeader, CardContent, CardMedia, Box, LinearProgress } from '@mui/material';
import { useDispatch } from 'react-redux';

const CommandeDetails = ({ commande }) => {
    const dispatch = useDispatch();

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', options);
    };
    return (
        <Box>
            <Grid justifyContent="center">
                <Grid item xs={12} md={10} lg={8}>
                    <Card sx={{ borderRadius: 10 }}>
                        <CardHeader title={`Commande Numero: ${commande?.numero}`} sx={{ backgroundColor: '#e7f5fe', textAlign: 'center' }} />
                        <CardContent>
                            <Grid container spacing={3} p={3}>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body2" color="textSecondary" gutterBottom><strong>Client:</strong> {commande?.client?.full_name}</Typography>
                                    <Typography variant="body2" color="textSecondary" gutterBottom><strong>Type:</strong> {commande?.client?.type}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body2" color="textSecondary" gutterBottom><strong>Telephone:</strong> {commande?.client?.telephone}</Typography>
                                    <Typography variant="body2" color="textSecondary" gutterBottom><strong>Adresse:</strong> {commande?.client?.adresse}</Typography>
                                </Grid>
                            </Grid>
                            {commande?.items?.map((item, index) => (
                                <Card key={index} sx={{ boxShadow: 'none', borderRadius: 10, border: '1px solid #e0e0e0', marginBottom: 2 }}>
                                    <CardContent>
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item xs={12} sm={6} md={2}>
                                                {/* <CardMedia
                                                    component="img"
                                                    src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/Products/13.webp"
                                                    alt="Phone"
                                                /> */}
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={2}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{item?.product?.name}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={2}>
                                                <Typography variant="body2">Prix Unitaire: {item?.prixUnit}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={2}>
                                                <Typography variant="body2">Quantité: {item?.quantity}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={2}>
                                                <Typography variant="body2">Total: {item?.totalPrice}</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            ))}
                        </CardContent>
                        <CardContent>
                            <Typography variant="subtitle1">Order Details</Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" color="textSecondary">Date</Typography>
                                <Typography variant="body2">{formatDate(commande?.date)}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" color="textSecondary">Statut de paiement:</Typography>
                                <Typography variant="body2">{commande?.statut}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" color="textSecondary">Sera livrée: </Typography>
                                <Typography variant="body2">{commande?.delivered}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                <Typography variant="body2" color="textSecondary">Facturé :</Typography>
                                <Typography variant="body2">{commande?.isFacture}</Typography>
                            </Box>
                        </CardContent>
                        <Box sx={{ backgroundColor: '#47a3ff', borderRadius: '0 0 10px 10px' }} py={2} px={4} textAlign={'end'}>
                            <Typography variant="body1" sx={{ color: 'white' }}>Total HT: <span style={{ fontSize: '1.5rem', fontWeight: 'bold', marginLeft: 2 }}>{commande?.prix}</span></Typography>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CommandeDetails;
