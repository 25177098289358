import StockService from '../services/stockServices';
import {
  CREATE_STOCK_SUCCESS,
  CREATE_STOCK_FAILURE,
  UPDATE_STOCK_SUCCESS,
  UPDATE_STOCK_FAILURE,
  GET_STOCK_SUCCESS,
  GET_STOCK_FAILURE,
  DELETE_STOCK_SUCCESS,
  DELETE_STOCK_FAILURE,
  GET_ALL_STOCKS_SUCCESS,
  GET_ALL_STOCKS_FAILURE,
  GET_OUT_OF_STOCK_SUCCESS,
  GET_OUT_OF_STOCK_FAILURE,
} from './types';

export const createStock = (stockData) => {
  return (dispatch) => {
    return StockService.createStock(stockData)
      .then((response) => {
        dispatch({
          type: CREATE_STOCK_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: CREATE_STOCK_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const fetchAllStocks = () => {
  return (dispatch) => {
    return StockService.getAllStocks()
      .then((response) => {
        dispatch({
          type: GET_ALL_STOCKS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_STOCKS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateStock = (stockId, updatedStock) => {
  return (dispatch) => {
    return StockService.updateStock(stockId, updatedStock)
      .then((response) => {
        dispatch({
          type: UPDATE_STOCK_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_STOCK_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const fetchStock = (stockId) => {
  return (dispatch) => {
    return StockService.fetchStock(stockId)
      .then((response) => {
        dispatch({
          type: GET_STOCK_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_STOCK_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteStock = (stockId) => {
  return (dispatch) => {
    return StockService.deleteStock(stockId)
      .then((response) => {
        dispatch({
          type: DELETE_STOCK_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: DELETE_STOCK_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const fetchOutOfStock = () => {
  return (dispatch) => {
    return StockService.getOutOfStock()
      .then((response) => {
        dispatch({
          type: GET_OUT_OF_STOCK_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_OUT_OF_STOCK_FAILURE,
          payload: error.message,
        });
      });
  };
};