import API_URL from './apiUrl';

class UserServices {
    register(userdata) {
        return API_URL.post("/auth/signup", userdata);
    }
    login(userdata) {
        return API_URL.post("/auth/signin", userdata)
            .then((response) => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;
            });
    };

    logout = () => {
        localStorage.removeItem("user");
    };

    getAllUsers() {
        return API_URL.get("/users");
    };

    getUser(id) {
        return API_URL.get(`/users/user/${id}`);
    };

    deleteUser(id) {
        return API_URL.delete(`/users/user/${id}`);
    };

    updateUser(id, data) {
        return API_URL.put(`/users/update/user/${id}`, data);
    };

    updateUserPassword(id, data) {
        return API_URL.put(`/users/user/${id}/password`, data);
    };

    updateUserPermissions(id, permissionIds) {
        return API_URL.put(`/auth/user/${id}/permissions/update`, permissionIds);
    };

}

export default new UserServices();