import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserPassword } from '../../actions/UsersActions';
import {
    Container,
    Button,
    TextField,
    Grid,
    Snackbar,
    Alert,
} from '@mui/material';

const UpdatePassword = ({ userId }) => {
    const dispatch = useDispatch();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { success, error } = useSelector((state) => state);
    const [passwordError, setPasswordError] = useState(null);
    const [passwordSuccess, setPasswordSuccess] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setPasswordError('Passwords do not match');
            return;
        }

        const data = {
            currentPassword,
            newPassword,
        };

        dispatch(updateUserPassword(userId, data))
            .then(() => {
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setPasswordError(null);
                setPasswordSuccess('Password updated successfully');
                openSnackbar('Password updated successfully', 'success');
            })
            .catch(() => {
                setPasswordSuccess(null);
                setPasswordError('Failed to update password');
                openSnackbar('Failed to update password', 'error');
            });
    };

    const openSnackbar = (message, severity) => {
        setSnackbarOpen(true);
    };

    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Mot de passe actuel"
                            type="password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Nouveau mot de passe"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Confirmez le mot de passe"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </Grid>
                    {passwordError && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {passwordError}
                        </Alert>
                    )}
                    {passwordSuccess && (
                        <Alert severity="success" sx={{ mt: 2 }}>
                            {passwordSuccess}
                        </Alert>
                    )}
                </Grid>
                <Button
                    type="submit"
                    variant="contained"
                    color="info"
                    className='mt-2'
                >
                    Mettre à jour
                </Button>

            </form>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <Alert onClose={closeSnackbar} severity="success">
                    Password updated successfully
                </Alert>
            </Snackbar>
        </>
    );
};

export default UpdatePassword;
