import React, { useState, useEffect } from "react";
import { updateStock, fetchAllStocks } from '../../actions/StockActions';
import {
    TextField,
    Button,
    Snackbar,
    Alert,
} from '@mui/material';
import { useDispatch } from "react-redux";

const UpdateStock = ({ stock }) => {
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const currentDate = new Date();

    useEffect(() => {
        if (stock) {
            setQuantity(stock?.quantity);
        }
    }, [stock]);

    const handleSubmit = () => {
        dispatch(updateStock(stock?.id, { quantity: quantity, lastDate: currentDate }))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('édité avec succès!');
                setSnackbarOpen(true);
                dispatch(fetchAllStocks());
            })
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage('Erreur. Veuillez réessayer.');
                setSnackbarOpen(true);
                console.error(error);
            });
    };

    
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <TextField
                label="Quantité"
                variant="outlined"
                fullWidth
                type='number'
                margin="normal"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
            />
            <Button
                type="submit"
                variant="contained"
                color="primary" sx={{ mt: 2 }}
                onClick={handleSubmit}
            >
                Sauvegarder
            </Button>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default UpdateStock;