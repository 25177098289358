import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateClient, getClient } from "../../actions/ClientActions";
import { useParams, Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import AccessDenied from "../Errors/AccessDenied";
import { useSelector } from "react-redux";
import {
    Container,
    Typography,
    TextField,
    MenuItem,
    FormControl,
    Alert,
    AlertTitle,
    Button,
    Grid,
} from "@mui/material";
import { decodeId } from "../../Crypte";

const UpdateClient = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const clientId = decodeId(id);
    //   const [currentClient, setCurrentClient] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const { user: currentUser } = useSelector((state) => state.auth);
    const [cin, setCin] = useState("");
    const [full_name, setFullName] = useState("");
    const [adresse, setAdresse] = useState("");
    const [telephone, setTelephone] = useState("");
    const [type, setType] = useState("personne physique");
    const [ice, setICE] = useState("");
    const [rc, setRC] = useState("");
    const [ifc, setIFC] = useState("");
    const currentClient = useSelector((state) => state.clients.client);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        dispatch(getClient(clientId));
    }, [dispatch, clientId]);

    useEffect(() => {
        if (currentClient) {
            setCin(currentClient.cin);
            setFullName(currentClient.full_name);
            setAdresse(currentClient.adresse);
            setTelephone(currentClient.telephone);
            setType(currentClient.type);
            setICE(currentClient.ice);
            setRC(currentClient.rc);
            setIFC(currentClient.ifc)
        }
    }, [currentClient]);

    const updateContent = () => {
        const updatedClient = {
            cin,
            full_name,
            adresse,
            telephone,
            type,
            ice,
            rc,
            ifc,
        };
        dispatch(updateClient(clientId, updatedClient))
            .then((response) => {
                console.log(response);
                setSnackbarSeverity('success');
                setSnackbarMessage('Le client a été mis à jour avec succès!');
                setSnackbarOpen(true);
                // setMessage("The Client was updated successfully!");
            })
            .catch((error) => {
                console.log(error);
                setSnackbarSeverity('error');
                if (error.response && error.response.status === 400) {
                    openSnackbar("The client already exists", "error");
                } else {
                    openSnackbar(error.response.data.message, "error");
                }
            });
    };


    const onChangeCin = (e) => {
        setCin(e.target.value);
    };

    const onChangeFullName = (e) => {
        setFullName(e.target.value);
    };

    const onChangeAdresse = (e) => {
        setAdresse(e.target.value);
    };

    const onChangeTelephone = (e) => {
        setTelephone(e.target.value);
    };

    const onChangeType = (e) => {
        setType(e.target.value);
    };

    const onChangeICE = (e) => {
        setICE(e.target.value);
    };

    const onChangeRC = (e) => {
        setRC(e.target.value);
    };

    const onChangeIFC = (e) => {
        setIFC(e.target.value);
    };

    const openSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            {currentUser && ((currentUser?.role === "Administrateurs") || currentUser.permissions.includes("Modifier les clients")) ? (
                <Container maxWidth="lg">
                    <Typography textAlign={'center'}
                        sx={{
                            boxShadow: '0 1em 1em -1em rgba(0, 0, 0, .25)',
                        }}
                        variant="h4" gutterBottom>
                        Mettre à jour le client:&nbsp;{currentClient?.full_name}
                    </Typography>
                    {submitted ? (
                        <div className="mt-2">
                            <Alert severity="success">
                                <AlertTitle>Success</AlertTitle>
                                Le client a été mis à jour avec succès —
                                <strong>
                                    <Link to="/clients" className="text-decoration-none ms-1">
                                        Vérifiez-le!
                                    </Link>
                                </strong>
                            </Alert>
                        </div>
                    ) : (
                        <div>
                            <FormControl fullWidth>
                                <TextField
                                    select
                                    label="Type"
                                    margin="normal"
                                    value={type}
                                    onChange={onChangeType}
                                >
                                    <MenuItem value="personne physique">Personne physique</MenuItem>
                                    <MenuItem value="personne morale">Personne morale</MenuItem>
                                    <MenuItem value="organisme public">Organisme public</MenuItem>
                                </TextField>
                            </FormControl>
                            <TextField
                                fullWidth
                                id="fullName"
                                label="Nom Complet"
                                required
                                value={full_name}
                                onChange={onChangeFullName}
                                name="fullName"
                                margin="normal"
                            />
                            {type !== "none" && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        {type !== "personne physique" && (
                                            <TextField
                                                fullWidth
                                                id="ice"
                                                label="ICE"
                                                value={ice}
                                                onChange={onChangeICE}
                                                name="ice"
                                                margin="normal"
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {(type !== "personne physique" && type !== "organisme public") && (
                                            <TextField
                                                fullWidth
                                                id="rc"
                                                label="RC"
                                                value={rc}
                                                onChange={onChangeRC}
                                                name="rc"
                                                margin="normal"
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {(type !== "personne physique" && type !== "organisme public") && (
                                            <TextField
                                                fullWidth
                                                id="ifc"
                                                label="IF"
                                                value={ifc}
                                                onChange={onChangeIFC}
                                                name="ifc"
                                                margin="normal"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {type !== "organisme public" && (
                                <TextField
                                    fullWidth
                                    id="cin"
                                    label="CIN"
                                    value={cin}
                                    onChange={onChangeCin}
                                    name="cin"
                                    margin="normal"
                                />
                            )}
                            <TextField
                                fullWidth
                                id="adresse"
                                label="Adresse"
                                value={adresse}
                                onChange={onChangeAdresse}
                                name="adresse"
                                margin="normal"
                            />
                            <TextField
                                fullWidth
                                id="telephone"
                                label="Telephone"
                                value={telephone}
                                onChange={onChangeTelephone}
                                name="telephone"
                                margin="normal"
                            />
                            <Button
                                variant="contained"
                                color="info"
                                onClick={updateContent}
                                className="mt-4"
                            >
                                Modifier
                            </Button>
                        </div>
                    )}
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={closeSnackbar}
                    >
                        <Alert variant="filled" onClose={closeSnackbar} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Container>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};

export default UpdateClient;
