import {
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAILURE,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAILURE,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAILURE,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILURE,
    GET_ALL_CATEGORIES_SUCCESS,
    GET_ALL_CATEGORIES_FAILURE,
  } from '../../actions/types';

const initialState = {
  category: null,
  categories: [],
  error: null,
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.payload,
        error: null,
      };
    case CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.payload,
        error: null,
      };
    case UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.payload,
        error: null,
      };
    case GET_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        category: null,
        error: null,
      };
    case DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        error: null,
      };
    case GET_ALL_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default categoryReducer;