import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../actions/UsersActions';
import {
    TextField,
    Typography,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormControl,
    Button,
    Grid,
    Snackbar,
    Box,
} from '@mui/material';
import AccessDenied from '../Errors/AccessDenied';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const AddUser = () => {
    const dispatch = useDispatch();
    const [userData, setUserData] = useState({
        username: '',
        cin: '',
        nom: '',
        prenom: '',
        email: '',
        password: '',
        adresse: '',
        date_recrutement: '',
        telephone: '',
        role: '',
    });
    const { user: currentUser } = useSelector((state) => state.auth);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value,
        });
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(register(userData))
            .then(() => {
                setSnackbarType('success');
                setSnackbarMessage('Personnel créé avec succès');
                setOpenSnackbar(true);
                setUserData({
                    username: '',
                    cin: '',
                    nom: '',
                    prenom: '',
                    email: '',
                    password: '',
                    adresse: '',
                    date_recrutement: '',
                    telephone: '',
                    role: '',
                });
            })
            .catch((error) => {
                setSnackbarType('error');
                setSnackbarMessage(error?.message);
                setOpenSnackbar(true);
            });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <>
            {(currentUser && ((currentUser?.role === "Administrateurs") || (currentUser.permissions.includes("Ajouter des utilisateurs")))) ? (
                <div>
                    <Box sx={{
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        marginBottom: '1rem',
                    }} >
                        <PersonAddIcon color='primary' sx={{ margin: '0.5rem' }} fontSize='large' />
                        <Typography
                            variant="h4"
                            sx={{
                                margin: '0.5rem',

                            }}
                            mb={3}
                            textAlign={'center'}
                            color='primary'
                        >
                            Ajouter un nouvel utilisateur
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit} >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant='standard'
                                    label="Username"
                                    name="username"
                                    value={userData.username}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant='standard'
                                    label="CIN"
                                    name="cin"
                                    value={userData.cin}
                                    onChange={handleChange}
                                    fullWidth


                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant='standard'
                                    label="Nom"
                                    name="nom"
                                    value={userData.nom}
                                    onChange={handleChange}
                                    fullWidth


                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant='standard'
                                    label="Prenom"
                                    name="prenom"
                                    value={userData.prenom}
                                    onChange={handleChange}
                                    fullWidth


                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant='standard'
                                    label="Email"
                                    name="email"
                                    value={userData.email}
                                    onChange={handleChange}
                                    fullWidth


                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant='standard'
                                    label="Password"
                                    name="password"
                                    type="password"
                                    value={userData.password}
                                    onChange={handleChange}
                                    fullWidth


                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant='standard'
                                    label="Adresse"
                                    name="adresse"
                                    value={userData.adresse}
                                    onChange={handleChange}
                                    fullWidth


                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant='standard'
                                    label="Date Recrutement"
                                    name="date_recrutement"
                                    type="date"
                                    value={userData.date_recrutement}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant='standard'
                                    label="Telephone"
                                    name="telephone"
                                    value={userData.telephone}
                                    onChange={handleChange}
                                    fullWidth


                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography>Poste</Typography>
                                <FormControl>
                                    <RadioGroup name="role" value={userData.role} onChange={handleChange}>
                                        <FormControlLabel value={'Administrateurs'} control={<Radio />} label={'Administrateurs'} />
                                        <FormControlLabel value={'Employé'} control={<Radio />} label={'Employé'} />
                                        <FormControlLabel value={'Autre'} control={<Radio />} label={'Autre'} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary">
                                    Ajouter
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        message={snackbarMessage}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        ContentProps={{
                            sx: {
                                backgroundColor: snackbarType === 'success' ? 'green' : 'red',
                            },
                        }}
                    />
                </div>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
}

export default AddUser;
