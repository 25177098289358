import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Snackbar, Typography, Box, Card, CardHeader, CardContent, Grid } from "@mui/material";
import { Alert } from "@mui/material";
import { factureCommande } from "../../actions/FactureActions";
import './style.css';
import { updateSimpleCommande } from "../../actions/CommandeActions";

const FactureCommande = ({ commande, closeDialogFacture }) => {
    const dispatch = useDispatch();
    const [confirm, setConfirm] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [factureCreated, setFactureCreated] = useState(false);

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', options);
    };

    const handleConfirm = () => {
        setConfirm(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const montantTtc = parseFloat(commande?.prix) * 1.2;
    const tva = parseFloat(commande?.prix) * 0.2;

    const handleCreateFacture = () => {
        const Data = {
            montant_ttc: montantTtc,
            montant_ht: commande?.prix,
            date: new Date(),
            statut: commande?.statut,
            commandeId: commande?.id,
        };

        dispatch(factureCommande(Data))
            .then(() => {
                dispatch(updateSimpleCommande(commande?.id, { isFacture: 'Oui'}));
                setFactureCreated(true);
                setSnackbarSeverity("success");
                setSnackbarMessage("Facture créée avec succès.");
                setSnackbarOpen(true);
                closeDialogFacture();
            })
            .catch((error) => {
                setSnackbarSeverity("error");
                setSnackbarMessage("Échec de la création de la facture.");
                setSnackbarOpen(true);
                console.error("Error creating facture:", error);
            });
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert variant='filled' onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {confirm && !factureCreated && (
                <Box sx={{ mt: 2, p: 2 }}>
                    <Typography variant="h4" gutterBottom>
                        Etes-vous sûr de vouloir facturer cette commande ?
                    </Typography>
                    <Button className="mt-2" variant="contained" color="primary" onClick={handleCreateFacture}>
                        Confirmer
                    </Button>
                </Box>
            )}

            {!confirm && !factureCreated && (
                <>
                    <div class="page-content container">
                        <div class="container px-0">
                            <div class="row mt-4">
                                <div class="col-12 col-lg-12">
                                    <hr class="row brc-default-l1 mx-n1 mb-4 mt-4" />

                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div>
                                                <span class="text-sm text-grey-m2 align-middle">Client:&nbsp;</span>
                                                <span class="text-600 text-110 text-blue align-middle">{commande?.client?.full_name}</span>
                                            </div>
                                            <div class="text-grey-m2">

                                                {commande?.client?.type === 'personne physique' &&
                                                    <div class="my-1">
                                                        CIN:&nbsp; {commande?.client?.cin || '-'}
                                                    </div>
                                                }
                                                <div class="my-1">
                                                    Telephone:&nbsp; {commande.client?.telephone || '-'}
                                                </div>
                                                <div class="my-1">
                                                    Adresse:&nbsp; {commande.client?.adresse || '-'}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                                            <hr class="d-sm-none" />
                                            <div class="text-grey-m2">
                                                <div class="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                                                    Commande
                                                </div>

                                                <div class="my-2"><i class="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span class="text-600 text-90">Numero:</span> #{commande?.numero}</div>

                                                <div class="my-2"><i class="fa fa-circle text-blue-m2 text-xs mr-1"></i> <span class="text-600 text-90">Date:</span>{formatDate(commande?.date)}</div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-5">
                                        <div class="row text-600 text-white bgc-default-tp1 py-25">
                                            <div class="col-9 col-sm-5">Produit</div>
                                            <div class="d-none d-sm-block col-2">Prix Unitaire</div>
                                            <div class="d-none d-sm-block col-4 col-sm-2">Quantité</div>
                                            <div class="d-none d-sm-block col-sm-2">Total</div>
                                        </div>
                                        {commande?.items.map((item, index) => (
                                            <>
                                                <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                                                    <div class="col-9 col-sm-5">{item?.product?.name}</div>
                                                    <div class="d-none d-sm-block col-2"> {item?.prixUnit}</div>
                                                    <div class="d-none d-sm-block col-2">{item?.quantity}</div>
                                                    <div class="d-none d-sm-block col-2 text-95"> {item?.totalPrice}</div>
                                                </div>

                                            </>
                                        ))}

                                        <div class="row border-b-2 brc-default-l2"></div>
                                        <div className="row mt-3">
                                        <div className="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                                        </div>

                                        <div className="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                                            <div className="row my-2">
                                                <div className="col-7 text-right">
                                                    Montant HT
                                                </div>
                                                <div className="col-5">
                                                    <span className="text-120 text-secondary-d1">{commande?.prix}</span>
                                                </div>
                                            </div>

                                            <div className="row my-2">
                                                <div className="col-7 text-right">
                                                    TVA
                                                </div>
                                                <div className="col-5">
                                                    <span className="text-110 text-secondary-d1">{tva?.toFixed(2)}</span>
                                                </div>
                                            </div>
                                            <div className="row my-2 mt-2">
                                                <div className="col-7  mt-2 fw-bold text-100 text-right">
                                                    Total TTC
                                                </div>
                                                <div className="col-5  mt-2">
                                                    <span className="text-150  text-success-d3 opacity-2">{montantTtc?.toFixed(2)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        {/* <div class="row mt-3">
                                            <div class="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                                            </div>

                                            <div class="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                                                <div class="row my-2 align-items-center bgc-primary-l3 p-2">
                                                    <div class="col-5 text-80 text-right">
                                                        Total
                                                    </div>
                                                    <div class="col-7">
                                                        <span class="text-150 text-success-d3 opacity-2">{commande?.prix}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Box sx={{ mt: 2, textAlign: 'end', mr: '1rem' }}>
                        <Button variant="contained" sx={{ textAlign: 'end' }} color="primary" onClick={handleConfirm}>
                            Facturer
                        </Button>
                    </Box>
                </>
            )}
        </>
    );
};

export default FactureCommande;
