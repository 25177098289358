import  API_URL from './apiUrl';

class StockService {
  createStock(stockData) {
    return API_URL.post("/stock/create/", stockData);
  }

  getAllStocks() {
    return API_URL.get("/stock");
  }

  updateStock(stockId, updatedStock) {
    return API_URL.put(`/stock/update/${stockId}`, updatedStock);
  }

  fetchStock(stockId) {
    return API_URL.get(`/stock/${stockId}`);
  }

  deleteStock(stockId) {
    return API_URL.delete(`/stock/delete/${stockId}`);
  }

  getOutOfStock() {
    return API_URL.get("/stock/stocks/outOfStock");
  }
  
}

export default new StockService();
