import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../actions/CategoriesActions';
import { getProduct, updateProduct } from '../../actions/ProductsActions';
import {
    Container,
    Typography,
    TextField,
    Button,
    Autocomplete,
    MenuItem,
    Snackbar,
    Alert,
} from '@mui/material';
import AccessDenied from '../Errors/AccessDenied';
import { decodeId } from '../../Crypte';
import { useParams } from "react-router-dom";

const UpdateProduct = ({ currentUser }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const productId = decodeId(id);
    const [name, setName] = useState('');
    const [size, setSize] = useState('');
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [height, setHeight] = useState('');
    const [width, setWidth] = useState('');
    const [weight, setWeight] = useState('');
    const [typeMater, setTypeMater] = useState('');
    const [couleur, setCouleur] = useState('');
    const [prix, setPrix] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const product = useSelector((state) => state.products.product);
    const categories = useSelector((state) => state.categories.categories);

    useEffect(() => {
        dispatch(getProduct(productId));
    }, [dispatch, productId]);


    useEffect(() => {
        dispatch(fetchCategories());
    }, [dispatch]);

    useEffect(() => {
        if (product) {
            setName(product?.name);
            setType(product?.type);
            setCouleur(product?.color);
            setSize(product?.size);
            setDescription(product?.description);
            setHeight(product?.height);
            setWidth(product?.width);
            setWeight(product?.weight);
            setTypeMater(product?.type_mater);
            setPrix(product?.prix);
            setCategoryId(product?.categoryId);
        }
    }, [product]);

    const handleSubmit = () => {
        const Data = {
            name,
            type,
            description,
            height: height || null,
            width: width || null,
            weight: weight || null,
            color: couleur || null,
            size: size || null,
            type_mater: typeMater,
            prix,
            categoryId,
        };

        dispatch(updateProduct(productId, Data))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Produit créé avec succès !');
                setSnackbarOpen(true);
                dispatch(getProduct(productId))
                setName('');
            })
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage('Erreur lors de la création. Veuillez réessayer.');
                setSnackbarOpen(true);
                console.error(error);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const categoriesOptions = categories?.map((category) => ({
        value: category.id,
        label: category.name,
    }));

    return (
        <>
            {currentUser && ((currentUser?.role === "Administrateurs") || currentUser.permissions.includes("Ajouter des produits")) ? (
                <Container maxWidth="md">
                    <Typography
                        variant="h1"
                        align="center"
                        gutterBottom
                        mb={3}
                        textAlign={'center'}
                        color='primary'
                    >
                        Modifier le produit: {product?.name}
                    </Typography>
                    <form style={{ marginTop: '1rem' }}>
                        <TextField
                            label="Nom du produit"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            label="Prix"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="prix"
                            value={prix}
                            onChange={(e) => setPrix(e.target.value)}
                        />
                        <TextField
                            select
                            label="Type de matiere du produit"
                            name="typeMater"
                            fullWidth
                            margin="normal"
                            value={typeMater}
                            onChange={(e) => setTypeMater(e.target.value)}
                        >
                            <MenuItem value="" disabled>Select...</MenuItem>
                            <MenuItem value="m">m(mètre)</MenuItem>
                            <MenuItem value="kg">kg(kilogramme)</MenuItem>
                            <MenuItem value="l">l(litres)</MenuItem>
                            <MenuItem value="u">unité</MenuItem>
                        </TextField>
                        <Autocomplete
                            sx={{ marginBottom: '1rem' }}
                            options={categoriesOptions}
                            getOptionLabel={(option) => option.label}
                            value={categoriesOptions?.find((option) => option.value === categoryId) || null}
                            isSearchable
                            onChange={(event, newValue) => {
                                setCategoryId(newValue.value);
                            }}
                            renderInput={(params) => <TextField {...params} margin="normal" label="Filtrer les catégories par nom..." />}
                        />
                        <TextField
                            label="Déscription"
                            name="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            multiline
                            fullWidth
                            rows={4}
                        />
                        <TextField
                            label="type"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="type"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        />
                        <TextField
                            select
                            label="Taille"
                            name="size"
                            fullWidth
                            margin="normal"
                            value={size}
                            onChange={(e) => setSize(e.target.value)}
                        >
                            <MenuItem value={null} disabled>Sélectionner...</MenuItem>
                            <MenuItem value='Petit(e)'>Petit(e)</MenuItem>
                            <MenuItem value='Moyen'>Moyen</MenuItem>
                            <MenuItem value='Grand(e)'>Grand(e)</MenuItem>
                        </TextField>
                        <TextField
                            label="Couleur"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="couleur"
                            value={couleur}
                            onChange={(e) => setCouleur(e.target.value)}
                        />
                        <TextField
                            label="Hauteur"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="height"
                            value={height}
                            onChange={(e) => setHeight(e.target.value)}
                        />
                        <TextField
                            label="Largeur"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="width"
                            value={width}
                            onChange={(e) => setWidth(e.target.value)}
                        />
                        <TextField
                            label="Poids"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="weight"
                            value={weight}
                            onChange={(e) => setWeight(e.target.value)}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary" sx={{ mt: 2 }}
                            onClick={handleSubmit}
                        >
                            Modifier
                        </Button>
                    </form>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Container>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};


export default UpdateProduct;
