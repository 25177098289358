import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateCategory, fetchCategories } from '../../actions/CategoriesActions';
import {
    TextField,
    Typography,
    Button,
    Grid,
    Snackbar,
    Alert,
    Box,
    Container,
} from '@mui/material';
import AccessDenied from '../Errors/AccessDenied';

const UpdateCategory = ({ currentUser, category }) => {
    const dispatch = useDispatch();
    const categoryId = category?.id;
    const [name, setName] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    // useEffect(() => {
    //     dispatch(getFournisseur(fournisseurId));
    // }, [dispatch, fournisseurId]);

    useEffect(() => {
        if (category) {
            setName(category?.name);
        }
    }, [category]);

    const handleUpdate = () => {
        const updatedData = {
            name,
        };

        dispatch(updateCategory(categoryId, updatedData))
            .then(() => {
                openSnackbar("catégorie updated successfully", "success");
                dispatch(fetchCategories());
            })
            .catch((error) => {
                openSnackbar("Failed to update catégorie", "error");
                console.log(error);
            });
    };

    const openSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
            {(currentUser && ((currentUser?.role === "Administrateurs") || (currentUser.permissions.includes("Ajouter des utilisateurs")))) ? (
                <Container>
                    <Box sx={{
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        marginBottom: '1rem',
                    }} >
                        <Typography
                            variant="h2"
                            className='fw-bold'
                            sx={{
                                margin: '0.5rem',
                            }}
                            color='primary'
                            mb={3}
                            textAlign={'center'}
                        >
                            Modifier la catégorie: {category?.name}
                        </Typography>
                    </Box>
                    <form >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Nom"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit"
                                    variant="contained"
                                    onClick={handleUpdate}
                                >
                                    Modifier
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={closeSnackbar}
                    >
                        <Alert variant='filled' onClose={closeSnackbar} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Container>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
}

export default UpdateCategory;