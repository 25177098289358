import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllCommandes, deleteCommande } from '../../actions/CommandeActions';
import { Link } from "react-router-dom";
import {
    Box,
    Typography,
    IconButton,
    TextField,
    Snackbar,
    Alert,
    Tooltip,
    Card,
    CardHeader
} from "@mui/material";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import AccessDenied from "../Errors/AccessDenied";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery } from "@mui/material";
import { encodeId } from '../../Crypte';
import {
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import { Delete, BorderColor, Add, Visibility, Print, FileCopy } from '@mui/icons-material';
import CommandeDetails from './CommandeDetails';
import PaymentReceipt from './PaymentReceipt';
import FactureCommande from './FactureCommande';
import { Container } from '@mui/system';

const CommandesList = ({ currentUser }) => {
    const dispatch = useDispatch();
    const commandes = useSelector((state) => state.commandes.commandes);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCommandes, setFilteredCommandes] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [prevDialogOpen, setPrevDialogOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [isFactureDialogOpen, setIsFactureDialogOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchAllCommandes());
    }, [dispatch]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const filteredCommandes = commandes.filter((commande) => {
            const name = commande.name || '';
            return (
                name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        })
            .sort((a, b) => {
                if (a.date === '0000-00-00' && b.date !== '0000-00-00') {
                    return 1;
                } else if (b.date === '0000-00-00' && a.date !== '0000-00-00') {
                    return -1;
                } else {
                    return new Date(b.date) - new Date(a.date);
                }
            });

        setFilteredCommandes(filteredCommandes);
    }, [commandes, searchTerm]);


    const Pagination = ({ page, onPageChange, className }) => {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <MuiPagination
                color="secondary"
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                    onPageChange(event, newPage - 1);
                }}
            />
        );
    }

    const CustomPagination = (props) => {
        return <GridPagination ActionsComponent={Pagination} {...props} />;
    }

    const columns = [
        {
            field: 'numero', headerName: 'Numero',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'client?.full_name', headerName: 'Client',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row?.client?.full_name || 'Sans client',
        },
        {
            field: 'prix', headerName: 'Prix',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'statut',
            headerName: 'Statut de paiement',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.statut || '-',
            renderCell: (params) => {
                let statusColor;

                switch (params.row.statut) {
                    case 'En instance':
                        statusColor = 'orange';
                        break;
                    case 'Payé':
                        statusColor = 'green';
                        break;
                    default:
                        statusColor = 'red';
                }

                return (
                    <span style={{ backgroundColor: statusColor, color: "#fff" }}>{params.row.statut}</span>
                );
            },
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    <IconButton
                        size='small'
                        color='error'
                        onClick={() => openDielog(params.row)}>
                        <Delete />
                    </IconButton>
                    <IconButton
                        component={Link}
                        size='small'
                        color='info'
                        to={`/commandes/update/${encodeId(params.id)}`}
                    >
                        <BorderColor />
                    </IconButton>

                    <Tooltip title='Voir les détails' >
                        <IconButton
                            size='small'
                            color='success'
                            onClick={() => openPrevDielog(params.row)}
                        >
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                    {params.row.statut === 'Payé' &&
                        <Tooltip title='imprimer le reçu de paiement' >
                            <IconButton
                                size='small'
                                sx={{
                                    color: 'orange'
                                }}
                                onClick={() => openDialog(params.row)}
                            >
                                <Print />
                            </IconButton>
                        </Tooltip>
                    }
                    {!params.row.facture && (
                        <Tooltip title='Facturer la commande' >
                            <IconButton
                                size='small'
                                sx={{
                                    color: 'orange'
                                }}
                                onClick={() => openFactureDialog(params.row)}
                            >
                                <FileCopy />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            ),
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
    ];

    const handleDeleteConfirm = () => {
        dispatch(deleteCommande(selectedItem))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Supprimé avec succès.');
                setSnackbarOpen(true);
                dispatch(fetchAllCommandes());
            })
            .catch((error) => {
                console.error('Error deleting:', error);
                setSnackbarSeverity('error');
                setSnackbarMessage('Erreur de suppression. Veuillez réessayer.');
                setSnackbarOpen(true);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };

    const openDielog = (item) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    }

    const closeDilaog = () => {
        setSelectedItem(null);
        setDeleteDialogOpen(false);
    }

    const openPrevDielog = (item) => {
        setSelectedItem(item);
        setPrevDialogOpen(true);
    }

    const closePrevDilaog = () => {
        setSelectedItem(null);
        setPrevDialogOpen(false);
    }

    const openFactureDialog = (item) => {
        setSelectedItem(item);
        setIsFactureDialogOpen(true);
    }

    const closeFactureDialog = () => {
        setSelectedItem(null);
        setIsFactureDialogOpen(false);
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const openDialog = (item) => {
        setSelectedItem(item);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setSelectedItem(null);
        setIsDialogOpen(false);
    };

    const AddButton = (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.25rem"
            height="3.25rem"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{
                cursor: "pointer",
                background: "#0288d1",
                color: "#fff !important"
            }}
            onClick={() => setIsCreateDialogOpen(true)}
        >
            <Add fontSize="small" color="inherit" />
        </Box>
    );

    return (
        <>
            {currentUser && (currentUser.role === "Administrateurs" || currentUser.permissions.includes("Consulter les projets")) ? (
                <>
                    <Typography
                        sx={{
                            fontSize: '36px',
                            textAlign: 'center',
                            marginTop: '12px',
                            fontWeight: 'bold',
                            color: '#3e4396',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                        }}
                        variant="h2"
                        gutterBottom
                    >
                        Commandes
                    </Typography>
                    <Box>
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Rechercher un projet par référence, client, ou numéro"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                        <Box
                            m="0 0 0 0"
                            height="75vh"
                        >
                            <DataGrid
                                rows={filteredCommandes}
                                columns={columns}
                                pagination
                                slots={{
                                    toolbar: GridToolbar,
                                    pagination: CustomPagination,
                                }}
                                slotProps={{
                                    columnMenu: { background: 'red' },
                                }}
                                initialState={{
                                    ...filteredCommandes.initialState,
                                    pagination: { paginationModel: { pageSize: 25 } },
                                }}
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            />
                        </Box>
                    </Box>
                    {AddButton}
                    <Dialog
                        open={deleteDialogOpen}
                        onClose={closeDilaog}
                    >
                        <DialogTitle
                            sx={{
                                color: '#fff',
                                background: 'red',
                                textAlign: 'center',
                                marginBottom: '1rem',
                            }}
                        >Confirmer la suppression</DialogTitle>
                        <DialogContent>
                            <Typography>
                                Êtes-vous sûr de vouloir supprimer cette commande ?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDilaog}>Annuler</Button>
                            <Button onClick={handleDeleteConfirm}
                                sx={{
                                    color: 'white !important',
                                    backgroundColor: 'red !important',
                                }}
                            >
                                Supprimer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={prevDialogOpen}
                        onClose={closePrevDilaog}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogContent>
                            {selectedItem &&
                                <CommandeDetails commande={selectedItem} />
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closePrevDilaog}>Annuler</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={isDialogOpen}
                        onClose={closeDialog}
                        fullWidth
                        maxWidth="lg"
                    >
                        <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                            Reçu de paiement
                        </DialogTitle>
                        <DialogContent>
                            {selectedItem &&
                                <PaymentReceipt commande={selectedItem} />
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDialog} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={isFactureDialogOpen}
                        onClose={closeFactureDialog}
                        fullWidth
                        maxWidth="lg"
                    >
                        <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                            Facturer la commande
                        </DialogTitle>
                        <DialogContent>
                            {selectedItem &&
                                <FactureCommande commande={selectedItem} closeDialogFacture={closeFactureDialog} />
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeFactureDialog} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={isCreateDialogOpen}
                        onClose={() => setIsCreateDialogOpen(false)}
                        fullWidth
                        maxWidth="sm"
                    >
                        <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                            Ajouter une commande
                        </DialogTitle>
                        <DialogContent>
                            <Container sx={{ textAlign: 'center' }}>
                                <Button
                                    className='me-1'
                                    sx={{
                                        padding: "8px",
                                        backgroundColor: "#1e90ff",
                                        color: '#fff !important',
                                        "&:hover": {
                                            backgroundColor: "#f8f8f8",
                                            color: '#1e90ff !important',
                                            padding: "15px"
                                        },
                                    }}
                                    component={Link}
                                    to={'/commandes/add/simple'}
                                > Simple commande</Button>
                                <Button
                                    className='ms-1'
                                    sx={{
                                        padding: "8px",
                                        backgroundColor: "#1e90ff",
                                        color: '#fff !important',
                                        "&:hover": {
                                            backgroundColor: "#f8f8f8",
                                            color: '#1e90ff !important',
                                            padding: "15px"
                                        },
                                    }}
                                    component={Link}
                                    to={'/commandes/add'}
                                >Grosse commande</Button>
                            </Container>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setIsCreateDialogOpen(false)}>Annuler</Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert variant='filled' onClose={handleSnackbarClose} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};

export default CommandesList;

