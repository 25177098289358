import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllProducts } from '../../actions/ProductsActions';
import { getAllFournisseurs } from '../../actions/FournisseurActions';
import { createStock } from '../../actions/StockActions';
import {
    Container,
    Typography,
    TextField,
    Button,
    Autocomplete,
    MenuItem,
    Box,
    Snackbar,
    Alert,
} from '@mui/material';
import AccessDenied from '../Errors/AccessDenied';

const CreateStock = ({ currentUser }) => {
    const dispatch = useDispatch();
    // const [lastDate, setLastDate] = useState('');
    const currentDate = new Date();
    const [quantity, setQuantity] = useState('');
    const [statut, setStatut] = useState('');
    const [autre, setAutre] = useState(false);
    const [productId, setProductId] = useState('');
    const [fournisseurId, setFournisseurId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const products = useSelector((state) => state.products.products);
    const fournisseurs = useSelector((state) => state.fournisseurs.fournisseurs);

    useEffect(() => {
        dispatch(fetchAllProducts());
        dispatch(getAllFournisseurs());
    }, [dispatch]);

    useEffect(() => {
        if (quantity && quantity > 0) {
            setStatut('En stock');
        } else {
            setStatut('En rupture de stock');
        }
    }, [quantity]);

    const handleSubmit = () => {
        const Data = {
            lastDate: currentDate,
            quantity,
            statut,
            productId,
            fournisseurId,
        };

        dispatch(createStock(Data))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Stock créé avec succès !');
                setSnackbarOpen(true);
                setFournisseurId(null);
                // setLastDate('');
                setAutre(false);
                setProductId(null);
                setQuantity('');
                setStatut('');
            })
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage('Erreur lors de la création. Veuillez réessayer.');
                setSnackbarOpen(true);
                console.error(error);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const productsOptions = products?.map((product) => ({
        value: product.id,
        label: product.name,
    }));

    const fournisseursOptions = fournisseurs?.map((fournisseur) => ({
        value: fournisseur.id,
        label: fournisseur.name,
    }));

    return (
        <>
            {currentUser && ((currentUser?.role === "Administrateurs") || currentUser.permissions.includes("Ajouter des produits")) ? (
                <Container maxWidth="md">
                    <Typography
                        variant="h2"
                        align="center"
                        gutterBottom
                        mb={3}
                        textAlign={'center'}
                        color='primary'
                    >
                        Céer un stock
                    </Typography>
                    <Box>
                        <Autocomplete
                            sx={{ marginBottom: '1rem' }}
                            options={productsOptions}
                            getOptionLabel={(option) => option.label}
                            value={productsOptions?.find((option) => option.value === productId) || null}
                            isSearchable
                            onChange={(event, newValue) => {
                                setProductId(newValue.value);
                            }}
                            renderInput={(params) => <TextField {...params} margin="normal" label="Filtrer les produit par nom..." />}
                        />
                        <TextField
                            select
                            label="Par Fournisseur"
                            fullWidth
                            margin="normal"
                            value={autre}
                            onChange={(e) => setAutre(e.target.value)}
                        >
                            <MenuItem value={false} disabled>Select...</MenuItem>
                            <MenuItem value={true}>oui</MenuItem>
                        </TextField>
                        {autre && (
                            <>
                                <Autocomplete
                                    sx={{ marginBottom: '1rem' }}
                                    options={fournisseursOptions}
                                    getOptionLabel={(option) => option.label}
                                    value={fournisseursOptions?.find((option) => option.value === fournisseurId) || null}
                                    isSearchable
                                    onChange={(event, newValue) => {
                                        setFournisseurId(newValue.value);
                                    }}
                                    renderInput={(params) => <TextField {...params} margin="normal" label="Filtrer les fournisseurs par nom..." />}
                                />
                            </>
                        )}
                        {/* <TextField
                            label="La date d'achat"
                            variant="outlined"
                            fullWidth
                            type='date'
                            margin="normal"
                            value={lastDate}
                            onChange={(e) => setLastDate(e.target.value)}
                        /> */}
                        <TextField
                            label="Quantité"
                            variant="outlined"
                            fullWidth
                            type='number'
                            margin="normal"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary" sx={{ mt: 2 }}
                            onClick={handleSubmit}
                        >
                            Sauvegarder
                        </Button>
                    </Box>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Container>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};

export default CreateStock;
