import API_URL from './apiUrl';

class FournisseurService {
    createFournisseur(fournisseurData) {
        return API_URL.post("/fournisseurs/create/", fournisseurData);
    }

    getAllFournisseurs() {
        return API_URL.get("/fournisseurs");
    }

    fetchUserFournisseur(userId) {
        return API_URL.get(`/fournisseurs/user/${userId}`);
    }

    updateFournisseur(fournisseurId, updatedFournisseur) {
        return API_URL.put(`/fournisseurs/update/${fournisseurId}`, updatedFournisseur);
    }

    fetchFournisseur(fournisseurId) {
        return API_URL.get(`/fournisseurs/${fournisseurId}`);
    }

    deleteFournisseur(fournisseurId) {
        return API_URL.delete(`/fournisseurs/delete/${fournisseurId}`);
    }
}

export default new FournisseurService();
