import CommandeService from '../services/commandeServices';
import {
  CREATE_COMMANDE_SUCCESS,
  CREATE_COMMANDE_FAILURE,
  UPDATE_COMMANDE_SUCCESS,
  UPDATE_COMMANDE_FAILURE,
  GET_COMMANDE_SUCCESS,
  GET_COMMANDE_FAILURE,
  DELETE_COMMANDE_SUCCESS,
  DELETE_COMMANDE_FAILURE,
  GET_ALL_COMMANDES_SUCCESS,
  GET_ALL_COMMANDES_FAILURE,
  GET_ALL_EXPIRED_COMMANDES_SUCCESS,
  GET_ALL_EXPIRED_COMMANDES_FAILURE,
} from './types';


export const createCommande = (commandeData) => {
  return (dispatch) => {
    return CommandeService.createCommande(commandeData)
      .then((response) => {
        dispatch({
          type: CREATE_COMMANDE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: CREATE_COMMANDE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const fetchAllCommandes = () => {
  return (dispatch) => {
    return CommandeService.getAllCommandes()
      .then((response) => {
        dispatch({
          type: GET_ALL_COMMANDES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_COMMANDES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const fetchExpiredDelivredCommandes = () => {
  return (dispatch) => {
    return CommandeService.getExpiredCommandes()
      .then((response) => {
        dispatch({
          type: GET_ALL_EXPIRED_COMMANDES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_EXPIRED_COMMANDES_FAILURE,
          payload: error.message,
        });
      });
  };
};


export const getAllCommandes = () => {
  return (dispatch) => {
    return CommandeService.fetchAllCommandes()
      .then((response) => {
        dispatch({
          type: GET_ALL_COMMANDES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_COMMANDES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateCommande = (commandeId, updatedCommande) => {
  return (dispatch) => {
    return CommandeService.updateCommande(commandeId, updatedCommande)
      .then((response) => {
        dispatch({
          type: UPDATE_COMMANDE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_COMMANDE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const updateSimpleCommande = (commandeId, updatedCommande) => {
  return (dispatch) => {
    return CommandeService.updateSimpleCommande(commandeId, updatedCommande)
      .then((response) => {
        dispatch({
          type: UPDATE_COMMANDE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_COMMANDE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const getCommande = (commandeId) => {
  return (dispatch) => {
    return CommandeService.fetchCommande(commandeId)
      .then((response) => {
        dispatch({
          type: GET_COMMANDE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_COMMANDE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteCommande = (commandeId) => {
  return (dispatch) => {
    return CommandeService.deleteCommande(commandeId)
      .then((response) => {
        dispatch({
          type: DELETE_COMMANDE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: DELETE_COMMANDE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};