import {
    CREATE_RECETTE_SUCCESS,
    CREATE_RECETTE_FAILURE,
    UPDATE_RECETTE_SUCCESS,
    UPDATE_RECETTE_FAILURE,
    GET_RECETTE_SUCCESS,
    GET_RECETTE_FAILURE,
    DELETE_RECETTE_SUCCESS,
    DELETE_RECETTE_FAILURE,
    GET_ALL_RECETTES_SUCCESS,
    GET_ALL_RECETTES_FAILURE,
} from '../../actions/types';

const initialState = {
    recette: null,
    recettes: [],
    error: null,
};

const recetteReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_RECETTE_SUCCESS:
            return {
                ...state,
                recette: action.payload,
                error: null,
            };
        case CREATE_RECETTE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case UPDATE_RECETTE_SUCCESS:
            return {
                ...state,
                recette: action.payload,
                error: null,
            };
        case UPDATE_RECETTE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_RECETTE_SUCCESS:
            return {
                ...state,
                recette: action.payload,
                error: null,
            };
        case GET_RECETTE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_RECETTE_SUCCESS:
            return {
                ...state,
                recette: null,
                error: null,
            };
        case DELETE_RECETTE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case GET_ALL_RECETTES_SUCCESS:
            return {
                ...state,
                recettes: action.payload,
                error: null,
            };
        case GET_ALL_RECETTES_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default recetteReducer;