import UserServices from '../services/userServices';
import * as types from './types';

export const register = (userData) => async (dispatch) => {
    try {
        const response = await UserServices.register(userData);
        dispatch({
            type: types.REGISTER_SUCCESS,
        });
        dispatch({
            type: types.SET_MESSAGE,
            payload: response.data.message,
        });
        return response;
    } catch (error) {
        dispatch({
            type: types.REGISTER_FAIL,
        });
        dispatch({
            type: types.SET_MESSAGE,
            payload: error.message,
        });
        throw error;
    }
};

export const login = (username, password) => (dispatch) => {
    return UserServices.login(username, password).then(
        (data) => {
            const token = data.accessToken;
            const timestamp = new Date().getTime();
            localStorage.setItem("token", token);
            localStorage.setItem("tokenTimestamp", timestamp);
            dispatch({
                type: types.LOGIN_SUCCESS,
                payload: { user: data },
            });
            console.log(data);
            return data;
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: types.LOGIN_FAIL,
            });

            dispatch({
                type: types.SET_MESSAGE,
                payload: message,
            });
            throw error;
        }
    );
};

export const logout = () => (dispatch) => {
    UserServices.logout();

    dispatch({
        type: types.LOGOUT,
    });
};

export const fetchAllUsers = () => {
    return (dispatch) => {
        return UserServices.getAllUsers()
            .then((response) => {
                dispatch({
                    type: types.GET_ALL_USERS_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: types.GET_ALL_USERS_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const fetchUserData = (userId) => {
    return (dispatch) => {
        return UserServices.getUser(userId)
            .then((response) => {
                dispatch({
                    type: types.GET_USER_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: types.GET_USER_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const deleteUser = (userId) => {
    return (dispatch) => {
        return UserServices.getAllUsers(userId)
            .then((response) => {
                dispatch({
                    type: types.DELETE_USER_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: types.DELETE_USER_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const updateUser = (userId, Data) => {
    return (dispatch) => {
        return UserServices.updateUser(userId, Data)
            .then((response) => {
                dispatch({
                    type: types.UPDATE_USER_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: types.UPDATE_USER_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

export const updateUserPassword = (userId, Data) => {
    return (dispatch) => {
        return UserServices.updateUserPassword(userId, Data)
            .then((response) => {
                dispatch({
                    type: types.UPDATE_USER_PASSWORD_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: types.UPDATE_USER_PASSWORD_FAILURE,
                    payload: error.message,
                });
                throw error;
            });
    };
};

