import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProducts, deleteProduct } from '../../actions/ProductsActions';
import { Link } from "react-router-dom";
import {
    Box,
    Typography,
    IconButton,
    TextField,
    Snackbar,
    Alert,
    Tooltip,
} from "@mui/material";
import { DataGrid, frFR } from "@mui/x-data-grid";
import AccessDenied from "../Errors/AccessDenied";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery } from "@mui/material";
import { encodeId } from '../../Crypte';
import {
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { Delete, BorderColor, ControlPoint, CloudUpload, Visibility } from '@mui/icons-material';
import FileUpload from './UploadFiles';

const ProductsList = ({ currentUser }) => {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.products);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedItem, setSelectedItem] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

    useEffect(() => {
        dispatch(getAllProducts());
    }, [dispatch]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const filteredProducts = products.filter((product) => {
            const name = product.name || '';
            return (
                name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        })
            .sort((a, b) => {
                if (a.createdAt === '0000-00-00' && b.createdAt !== '0000-00-00') {
                    return 1;
                } else if (b.createdAt === '0000-00-00' && a.createdAt !== '0000-00-00') {
                    return -1;
                } else {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                }
            });

        setFilteredProducts(filteredProducts);
    }, [products, searchTerm]);


    const Pagination = ({ page, onPageChange, className }) => {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <MuiPagination
                color="secondary"
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                    onPageChange(event, newPage - 1);
                }}
            />
        );
    }

    const CustomPagination = (props) => {
        return <GridPagination ActionsComponent={Pagination} {...props} />;
    }

    const CustomButton = () => {

        return (
            <>
                {currentUser && (currentUser.role === "Administrateurs" || currentUser?.permissions?.includes("Consulter les factures")) &&
                    <IconButton
                        color="primary"
                        component={Link}
                        to={`/products/add`}
                        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-1ihd25c-MuiButtonBase-root-MuiButton-root"
                    >
                        <ControlPoint sx={{ mr: '7px' }} />
                        Ajouter
                    </IconButton>
                }
            </>
        );
    };
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <CustomButton style={{ marginRight: '2rem' }} />
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const columns = [
        {
            field: 'name', headerName: 'Nom',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.name || '',
        },
        {
            field: 'prix', headerName: 'Prix',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'type_mater',
            headerName: 'Status',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.type_mater || '-',
            renderCell: (params) => {
                let statusLabel;

                switch (params.row.type_mater) {
                    case 'm':
                        statusLabel = 'm(mètre)';
                        break;
                    case 'u':
                        statusLabel = 'unité';
                        break;
                    case 'l':
                        statusLabel = 'l(litres)';
                        break;
                    case 'kg':
                        statusLabel = 'kg(kilogramme)';
                        break;
                    default:
                        statusLabel = "__";
                }

                return (
                    <span>{statusLabel}</span>
                );
            },
        },
        {
            field: 'category?.name', headerName: 'Catégorie',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row?.category?.name || '',
        },
        {
            field: 'description', headerName: 'description',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    <IconButton
                        className='me-1 ms-1'
                        size='small'
                        color='error'
                        onClick={() => openDielog(params.row)}>
                        <Delete />
                    </IconButton>
                    <IconButton
                        component={Link}
                        className='mS-1'
                        size='small'
                        color='info'
                        to={`/products/update/${encodeId(params.id)}`}
                    >
                        <BorderColor />
                    </IconButton>
                    <IconButton
                        component={Link}
                        className='mS-1'
                        size='small'
                        color='info'
                        to={`/products/details/${encodeId(params.id)}`}
                    >
                        <Visibility />
                    </IconButton>
                    <Tooltip title='Importer les images du produit' >
                        <IconButton
                            className='mS-1'
                            size='small'
                            color='success'
                            onClick={() => openUploadDielog(params.row)}
                        >
                            <CloudUpload />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
    ];

    const handleDeleteConfirm = () => {
        dispatch(deleteProduct(selectedItem?.id))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Produit supprimé avec succès.');
                setSnackbarOpen(true);
                dispatch(getAllProducts());
            })
            .catch((error) => {
                console.error('Error deleting user:', error);
                setSnackbarSeverity('error');
                setSnackbarMessage('Erreur de suppression ! Veuillez réessayer.');
                setSnackbarOpen(true);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };

    const openDielog = (item) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    }

    const closeDilaog = () => {
        setSelectedItem(null);
        setDeleteDialogOpen(false);
    }

    const openUploadDielog = (item) => {
        setSelectedItem(item);
        setUploadDialogOpen(true);
    }

    const closeUploadDilaog = () => {
        setSelectedItem(null);
        setUploadDialogOpen(false);
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            {currentUser && (currentUser.role === "Administrateurs" || currentUser.permissions.includes("Consulter les projets")) ? (
                <>
                    <Typography
                        sx={{
                            fontSize: '36px',
                            textAlign: 'center',
                            marginTop: '12px',
                            fontWeight: 'bold',
                            color: '#3e4396',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                        }}
                        variant="h2"
                        gutterBottom
                    >
                        Products
                    </Typography>
                    <Box>
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Rechercher un projet par référence, client, ou numéro"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                        <Box
                            m="0 0 0 0"
                            height="75vh"
                        >
                            <DataGrid
                                rows={filteredProducts}
                                columns={columns}
                                pagination
                                slots={{
                                    toolbar: CustomToolbar,
                                    pagination: CustomPagination,
                                }}
                                slotProps={{
                                    columnMenu: { background: 'red' },
                                }}
                                initialState={{
                                    ...filteredProducts.initialState,
                                    pagination: { paginationModel: { pageSize: 25 } },
                                }}
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            />
                        </Box>
                    </Box>
                    <Dialog
                        open={deleteDialogOpen}
                        onClose={closeDilaog}
                    >
                        <DialogTitle
                            sx={{
                                color: '#fff',
                                background: 'red',
                                textAlign: 'center',
                                marginBottom: '1rem',
                            }}
                        >Confirmer la suppression</DialogTitle>
                        <DialogContent>
                            <Typography>
                                Êtes-vous sûr de vouloir supprimer ce produit?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDilaog}>Annuler</Button>
                            <Button onClick={handleDeleteConfirm}
                                sx={{
                                    color: 'white !important',
                                    backgroundColor: 'red !important',
                                }}
                            >
                                Supprimer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={uploadDialogOpen}
                        onClose={closeUploadDilaog}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle
                            textAlign={'center'}
                            fontSize={'25px'}
                            fontWeight={'bold'}
                        >
                            Importer les images du produit
                        </DialogTitle>
                        <DialogContent>
                            {currentUser && ((currentUser?.role === "Administrateurs") || currentUser.permissions.includes("Importer les fichiers")) && (
                                <FileUpload productId={selectedItem?.id} />
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeUploadDilaog}>Annuler</Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert variant='filled' onClose={handleSnackbarClose} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};

export default ProductsList;

