import React from 'react';
import { Typography, Container, Box } from '@mui/material';

const AccessDenied = () => {
    return (
        <Container maxWidth="sm">
            <Box textAlign="center" mt={10}>
                <Typography variant="h1" color="error" gutterBottom>
                    403
                </Typography>
                <Typography variant="h4" color="error" gutterBottom>
                    Accès refusé
                </Typography>
                <Typography variant="body1">
                    Oups! Vous n'avez pas la permission d'accéder à cette page.
                </Typography>
            </Box>
        </Container>
    );
};

export default AccessDenied;