import {
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILURE,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAILURE,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_FAILURE,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAILURE,
    GET_ALL_PRODUCTS_SUCCESS,
    GET_ALL_PRODUCTS_FAILURE,
  } from '../../actions/types';
  
  const initialState = {
    product: null,
    products: [],
    error: null,
  };
  
  const productReducer = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_PRODUCT_SUCCESS:
        return {
          ...state,
          product: action.payload,
          error: null,
        };
      case CREATE_PRODUCT_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      case UPDATE_PRODUCT_SUCCESS:
        return {
          ...state,
          product: action.payload,
          error: null,
        };
      case UPDATE_PRODUCT_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      case GET_PRODUCT_SUCCESS:
        return {
          ...state,
          product: action.payload,
          error: null,
        };
      case GET_PRODUCT_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      case DELETE_PRODUCT_SUCCESS:
        return {
          ...state,
          product: null,
          error: null,
        };
      case DELETE_PRODUCT_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      case GET_ALL_PRODUCTS_SUCCESS:
        return {
          ...state,
          products: action.payload,
          error: null,
        };
      case GET_ALL_PRODUCTS_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default productReducer;