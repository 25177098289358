import FournisseurService from '../services/fournisseurServices';
import {
  CREATE_FOURNISSEUR_SUCCESS,
  CREATE_FOURNISSEUR_FAILURE,
  UPDATE_FOURNISSEUR_SUCCESS,
  UPDATE_FOURNISSEUR_FAILURE,
  GET_FOURNISSEUR_SUCCESS,
  GET_FOURNISSEUR_FAILURE,
  DELETE_FOURNISSEUR_SUCCESS,
  DELETE_FOURNISSEUR_FAILURE,
  GET_ALL_FOURNISSEURS_SUCCESS,
  GET_ALL_FOURNISSEURS_FAILURE,
} from './types';

export const createFournisseur = (fournisseurData) => {
  return (dispatch) => {
    return FournisseurService.createFournisseur(fournisseurData)
      .then((response) => {
        dispatch({
          type: CREATE_FOURNISSEUR_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: CREATE_FOURNISSEUR_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const getAllFournisseurs = () => {
  return (dispatch) => {
    return FournisseurService.getAllFournisseurs()
      .then((response) => {
        dispatch({
          type: GET_ALL_FOURNISSEURS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_FOURNISSEURS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateFournisseur = (fournisseurId, updatedFournisseur) => {
  return (dispatch) => {
    return FournisseurService.updateFournisseur(fournisseurId, updatedFournisseur)
      .then((response) => {
        dispatch({
          type: UPDATE_FOURNISSEUR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_FOURNISSEUR_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getFournisseur = (fournisseurId) => {
  return (dispatch) => {
    return FournisseurService.fetchFournisseur(fournisseurId)
      .then((response) => {
        dispatch({
          type: GET_FOURNISSEUR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_FOURNISSEUR_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteFournisseur = (fournisseurId) => {
  return (dispatch) => {
    return FournisseurService.deleteFournisseur(fournisseurId)
      .then((response) => {
        dispatch({
          type: DELETE_FOURNISSEUR_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: DELETE_FOURNISSEUR_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};
