import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFournisseur, updateFournisseur } from '../../actions/FournisseurActions';
import {
    TextField,
    Typography,
    Button,
    Grid,
    Snackbar,
    Alert,
    Box,
    Container,
} from '@mui/material';
import AccessDenied from '../Errors/AccessDenied';
import { decodeId } from '../../Crypte';
import { useParams } from "react-router-dom";

const EditFournisseur = ({ currentUser }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const fournisseurId = decodeId(id);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const fournisseur = useSelector((state) => state.fournisseurs.fournisseur);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    useEffect(() => {
        dispatch(getFournisseur(fournisseurId));
    }, [dispatch, fournisseurId]);

    useEffect(() => {
        if (fournisseur) {
            setName(fournisseur?.name);
            setEmail(fournisseur?.email);
            setTelephone(fournisseur?.telephone);
        }
    }, [fournisseur]);

    const handleUpdate = () => {
        const updatedData = {
            name,
            email,
            telephone,
        };

        dispatch(updateFournisseur(fournisseurId, updatedData))
            .then(() => {
                openSnackbar("fournisseur updated successfully", "success");
            })
            .catch((error) => {
                openSnackbar("Failed to update fournisseur", "error");
                console.log(error);
            });
    };

    const openSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
            {(currentUser && ((currentUser?.role === "Administrateurs") || (currentUser.permissions.includes("Ajouter des utilisateurs")))) ? (
                <Container>
                    <Box sx={{
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        marginBottom: '1rem',
                    }} >
                        <Typography
                            variant="h2"
                            className='fw-bold'
                            sx={{
                                margin: '0.5rem',
                            }}
                            color='primary'
                            mb={3}
                            textAlign={'center'}
                        >
                            Modifier le fournisseur: {fournisseur?.name}
                        </Typography>
                    </Box>
                    <form >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Nom"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Telephone"
                                    name="telephone"
                                    value={telephone}
                                    onChange={(e) => setTelephone(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit"
                                    variant="contained"
                                    onClick={handleUpdate}
                                >
                                    Modifier
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={closeSnackbar}
                    >
                        <Alert variant='filled' onClose={closeSnackbar} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Container>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
}

export default EditFournisseur;