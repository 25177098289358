import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData, updateUser } from '../../actions/UsersActions';
import {
    TextField,
    Button,
    Grid,
    Snackbar,
    Alert,
} from '@mui/material';

const UpdateUser = ({ user }) => {
    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [cin, setCin] = useState("");
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [adresse, setAdresse] = useState("");
    const [telephone, setTelephone] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const userId = user?.id;

    useEffect(() => {
        if (user) {
            setUsername(user?.username);
            setEmail(user?.email);
            setCin(user?.cin);
            setNom(user?.nom);
            setPrenom(user?.prenom);
            setAdresse(user?.adresse);
            setTelephone(user?.telephone);
        }
    }, [user]);

    const handleUpdate = (event) => {
        event.preventDefault();

        const updatedUser = {
            username,
            cin,
            email,
            nom,
            prenom,
            adresse,
            telephone,
        };

        dispatch(updateUser(userId, updatedUser))
            .then(() => {
                openSnackbar("User updated successfully", "success");
                dispatch(fetchUserData(userId));
            })
            .catch((error) => {
                openSnackbar("Failed to update user", "error");
                console.log(error);
            });
    };

    const openSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
                <form onSubmit={handleUpdate} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                
                                label="Username"
                                name="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                
                                label="CIN"
                                name="cin"
                                value={cin}
                                onChange={(e) => setCin(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                
                                label="Nom"
                                name="nom"
                                value={nom}
                                onChange={(e) => setNom(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                
                                label="Prenom"
                                name="prenom"
                                value={prenom}
                                onChange={(e) => setPrenom(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                
                                label="Email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                
                                label="Adresse"
                                name="adresse"
                                value={adresse}
                                onChange={(e) => setAdresse(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                
                                label="Telephone"
                                name="telephone"
                                value={telephone}
                                onChange={(e) => setTelephone(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained">
                                Modifier
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={closeSnackbar}
                >
                    <Alert onClose={closeSnackbar} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
        </>
    );
}

export default UpdateUser;