import { combineReducers } from 'redux';
import auth from './authReducers';
import customizationReducer from '../customizationReducer';
import usersReducers from './usersReducers';
import fournisseursReducer from './foutnisseurReducers';
import clientsReducer from './clientReducers';
import categoryReducer from './categoryReducers';
import permissionReducer from './permissionReducers';
import productReducer from './productReducers';
import fileReducer from './filesReducers';
import orderReducer from './orderReducers';
import stockReducer from './stockReducers';
import commandeReducer from './commandeReducers';
import factureReducer from './factureReducers';
import recetteReducer from './recettesReducers';
import notificationsReducer from './notificationsReducers';


const reducer = combineReducers({
    notifications: notificationsReducer,
    recettes: recetteReducer,
    factures : factureReducer,
    commandes: commandeReducer,
    stock: stockReducer,
    orders: orderReducer,
    files: fileReducer,
    products: productReducer,
    categories: categoryReducer,
    permissions: permissionReducer,
    clients: clientsReducer,
    fournisseurs: fournisseursReducer,
    customization: customizationReducer,
    users: usersReducers,
    auth,
});

export default reducer;