export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAILURE = 'UPDATE_USER_PASSWORD_FAILURE';

// ------------------------- MESSAGE  --------------------------
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// -------------------------- FOURNISSEUR TYPES ---------------------

export const CREATE_FOURNISSEUR_SUCCESS = 'CREATE_FOURNISSEUR_SUCCESS';
export const CREATE_FOURNISSEUR_FAILURE = 'CREATE_FOURNISSEUR_FAILURE';
export const UPDATE_FOURNISSEUR_SUCCESS = 'UPDATE_FOURNISSEUR_SUCCESS';
export const UPDATE_FOURNISSEUR_FAILURE = 'UPDATE_FOURNISSEUR_FAILURE';
export const GET_FOURNISSEUR_SUCCESS = 'GET_FOURNISSEUR_SUCCESS';
export const GET_FOURNISSEUR_FAILURE = 'GET_FOURNISSEUR_FAILURE';
export const DELETE_FOURNISSEUR_SUCCESS = 'DELETE_FOURNISSEUR_SUCCESS';
export const DELETE_FOURNISSEUR_FAILURE = 'DELETE_FOURNISSEUR_FAILURE';
export const GET_ALL_FOURNISSEURS_SUCCESS = 'GET_ALL_FOURNISSEURS_SUCCESS';
export const GET_ALL_FOURNISSEURS_FAILURE = 'GET_ALL_FOURNISSEURS_FAILURE';

// ------------------------- CLIENT  ---------------------------

export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_FAILURE = "CREATE_CLIENT_FAILURE";
export const GET_ALL_CLIENTS_SUCCESS = 'GET_ALL_CLIENTS_SUCCESS';
export const GET_ALL_CLIENTS_FAILURE = 'GET_ALL_CLIENTS_FAILURE';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAILURE = 'GET_CLIENT_FAILURE';
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAILURE = "UPDATE_CLIENT_FAILURE";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILURE = "DELETE_CLIENT_FAILURE";

// ----------------------- PERMISSION TYPES --------------------------

export const CREATE_PERMISSION_SUCCESS = 'CREATE_PERMISSION_SUCCESS';
export const CREATE_PERMISSION_FAILURE = 'CREATE_PERMISSION_FAILURE';
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS';
export const UPDATE_PERMISSION_FAILURE = 'UPDATE_PERMISSION_FAILURE';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_PERMISSION_FAILURE = 'GET_PERMISSION_FAILURE';
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS';
export const DELETE_PERMISSION_FAILURE = 'DELETE_PERMISSION_FAILURE';
export const GET_ALL_PERMISSIONS_SUCCESS = 'GET_ALL_PERMISSIONS_SUCCESS';
export const GET_ALL_PERMISSIONS_FAILURE = 'GET_ALL_PERMISSIONS_FAILURE';

// ----------------------- CATEGORY TYPES --------------------------

export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';
export const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS';
export const GET_ALL_CATEGORIES_FAILURE = 'GET_ALL_CATEGORIES_FAILURE';

// ----------------------- PRODUCT TYPES --------------------------

export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';
export const GET_ALL_PRODUCTS_FAILURE = 'GET_ALL_PRODUCTS_FAILURE';

// ----------------------- FILE TYPES --------------------------
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_FAILURE = 'UPLOAD_FILES_FAILURE';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const GET_FILES_FAILURE = 'GET_FILES_FAILURE';

// ----------------------- ORDER TYPES --------------------------

export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';
export const GET_ALL_ORDERS_SUCCESS = 'GET_ALL_ORDERS_SUCCESS';
export const GET_ALL_ORDERS_FAILURE = 'GET_ALL_ORDERS_FAILURE';

// ----------------------- STOCK TYPES --------------------------

export const CREATE_STOCK_SUCCESS = 'CREATE_STOCK_SUCCESS';
export const CREATE_STOCK_FAILURE = 'CREATE_STOCK_FAILURE';
export const UPDATE_STOCK_SUCCESS = 'UPDATE_STOCK_SUCCESS';
export const UPDATE_STOCK_FAILURE = 'UPDATE_STOCK_FAILURE';
export const GET_STOCK_SUCCESS = 'GET_STOCK_SUCCESS';
export const GET_STOCK_FAILURE = 'GET_STOCK_FAILURE';
export const DELETE_STOCK_SUCCESS = 'DELETE_STOCK_SUCCESS';
export const DELETE_STOCK_FAILURE = 'DELETE_STOCK_FAILURE';
export const GET_ALL_STOCKS_SUCCESS = 'GET_ALL_STOCKS_SUCCESS';
export const GET_ALL_STOCKS_FAILURE = 'GET_ALL_STOCKS_FAILURE';
export const GET_OUT_OF_STOCK_SUCCESS = 'GET_OUT_OF_STOCK_SUCCESS';
export const GET_OUT_OF_STOCK_FAILURE = 'GET_OUT_OF_STOCK_FAILURE';

// ----------------------- COMMANDE TYPES --------------------------

export const CREATE_COMMANDE_SUCCESS = 'CREATE_COMMANDE_SUCCESS';
export const CREATE_COMMANDE_FAILURE = 'CREATE_COMMANDE_FAILURE';
export const UPDATE_COMMANDE_SUCCESS = 'UPDATE_COMMANDE_SUCCESS';
export const UPDATE_COMMANDE_FAILURE = 'UPDATE_COMMANDE_FAILURE';
export const GET_COMMANDE_SUCCESS = 'GET_COMMANDE_SUCCESS';
export const GET_COMMANDE_FAILURE = 'GET_COMMANDE_FAILURE';
export const DELETE_COMMANDE_SUCCESS = 'DELETE_COMMANDE_SUCCESS';
export const DELETE_COMMANDE_FAILURE = 'DELETE_COMMANDE_FAILURE';
export const GET_ALL_COMMANDES_SUCCESS = 'GET_ALL_COMMANDES_SUCCESS';
export const GET_ALL_COMMANDES_FAILURE = 'GET_ALL_COMMANDES_FAILURE';
export const GET_ALL_EXPIRED_COMMANDES_SUCCESS = 'GET_ALL_EXPIRED_COMMANDES_SUCCESS';
export const GET_ALL_EXPIRED_COMMANDES_FAILURE = 'GET_ALL_EXPIRED_COMMANDES_FAILURE';

// ----------------------- FACTURE TYPES --------------------------
export const CREATE_FACTURE_SUCCESS = 'CREATE_FACTURE_SUCCESS';
export const CREATE_FACTURE_FAILURE = 'CREATE_FACTURE_FAILURE';
export const UPDATE_FACTURE_SUCCESS = 'UPDATE_FACTURE_SUCCESS';
export const UPDATE_FACTURE_FAILURE = 'UPDATE_FACTURE_FAILURE';
export const GET_FACTURE_SUCCESS = 'GET_FACTURE_SUCCESS';
export const GET_FACTURE_FAILURE = 'GET_FACTURE_FAILURE';
export const DELETE_FACTURE_SUCCESS = 'DELETE_FACTURE_SUCCESS';
export const DELETE_FACTURE_FAILURE = 'DELETE_FACTURE_FAILURE';
export const GET_ALL_FACTURES_SUCCESS = 'GET_ALL_FACTURES_SUCCESS';
export const GET_ALL_FACTURES_FAILURE = 'GET_ALL_FACTURES_FAILURE';

// ----------------------- RECETTE TYPES --------------------------
export const CREATE_RECETTE_SUCCESS = 'CREATE_RECETTE_SUCCESS';
export const CREATE_RECETTE_FAILURE = 'CREATE_RECETTE_FAILURE';
export const UPDATE_RECETTE_SUCCESS = 'UPDATE_RECETTE_SUCCESS';
export const UPDATE_RECETTE_FAILURE = 'UPDATE_RECETTE_FAILURE';
export const GET_RECETTE_SUCCESS = 'GET_RECETTE_SUCCESS';
export const GET_RECETTE_FAILURE = 'GET_RECETTE_FAILURE';
export const DELETE_RECETTE_SUCCESS = 'DELETE_RECETTE_SUCCESS';
export const DELETE_RECETTE_FAILURE = 'DELETE_RECETTE_FAILURE';
export const GET_ALL_RECETTES_SUCCESS = 'GET_ALL_RECETTES_SUCCESS';
export const GET_ALL_RECETTES_FAILURE = 'GET_ALL_RECETTES_FAILURE';

// ----------------------- NOTIFICATIONS TYPES --------------------------
export const GET_UNREAD_NOTIFICATIONS_SUCCESS = 'GET_UNREAD_NOTIFICATIONS_SUCCESS';
export const GET_UNREAD_NOTIFICATIONS_FAILURE = 'GET_UNREAD_NOTIFICATIONS_FAILURE';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';
